import { useSWRConfig } from 'swr'
import client from '~/client'

export default function useMutateCompanies() {
  const { mutate } = useSWRConfig()
  const userInfo = client.getUserInfo()

  return () =>
    mutate(
      key =>
        typeof key === 'string' &&
        ['company', 'repCompanyRelations/companies'].some(string =>
          key.startsWith(string)
        ),
      undefined,
      { revalidate: true }
    )
}
