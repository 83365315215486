import useSWR from 'swr'
import client from '~/client'

export default function useDistributors() {
  const userInfo = client.getUserInfo()

  const { data: relations, ...rest } = useSWR(
    `companyRelations/${userInfo.companyId}?pageSize=1000&sort=createdAt&order=asc`,
    client.fetcher // possibly page
  )
  //label, id

  const distributors =
    relations?.map(rel => ({
      ...rel.distributor,
      discount: rel.discount,
    })) || []
  const distributorsOptions =
    relations?.map(({ distributor, discount, distributorMultiplier }) => ({
      id: distributor.companyId,
      label: `${distributor.companyName || distributor.companyId} (${
        distributor.contactEmails?.materialsListExport ||
        'No email for material list export'
      })`,
      discount,
      distributorMultiplier,
    })) || []

  const getDistributor = id =>
    distributors.find(distributor => distributor.companyId === id)
  // just for now TODO remove
  // .filter(({ label }) => isDev() || label?.includes('Test')) || []
  return {
    distributors,
    relations,
    distributorsOptions,
    getDistributor,
    ...rest,
  }
}
