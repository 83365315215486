import { motion } from 'framer-motion'

const sharedProps = {
  pageInfo: {
    sx: { rowGap: 2, columnGap: 4, display: 'flex', flexWrap: 'wrap' },
  },
  card: {
    sx: {
      p: 2.5,
      mb: 3,
      position: 'relative',
      maxWidth: 1280,
    },
  },
  linearProgress: {
    top: {
      sx: { position: 'absolute', top: 0, left: 0, width: '100%' },
    },
  },
  linearProgress: {
    bottom: {
      sx: { position: 'absolute', bottom: 0, left: 0, width: '100%' },
    },
  },
  stack: {
    form: {
      spacing: 3,
      sx: {
        alignItems: 'flex-start',
      },
    },
  },
  button: {
    save: {
      type: 'submit',
      variant: 'contained',
      children: 'Save changes',
    },
    table: {
      variant: 'text',
      size: 'small',
      sx: { my: -0.5, px: 1 },
    },
    x: {
      sx: { position: 'absolute', top: 12, right: 16, zIndex: 9000 },
    },
  },
  table: {
    size: 'small',
    sx: {
      '& td, & th': { px: 0.5, py: 1 },
      '& td:first-child, & th:first-child': { pl: 0 },
      '& td:last-child, & th:last-child': { pr: 0 },
      tableLayout: 'fixed',
    },
  },
  motion: {
    fade: {
      transition: { duration: 0.3 },
      variants: {
        initial: { opacity: 0, transition: { duration: 0.3 } },
        animate: { opacity: 1, transition: { duration: 0.3 } },
        exit: { opacity: 0, transition: { duration: 0.3 } },
      },
      initial: 'initial',
      animate: 'animate',
      exit: 'exit',
    },
    collapse: {
      variants: {
        initial: custom => ({
          opacity: 0,
          height: 0,
          marginBottom: 0,
          ...custom?.initial,
        }),
        // animate: { opacity: 1, height: 'auto' },
        animate: custom => ({ opacity: 1, height: 'auto', ...custom }),
        exit: custom => ({
          opacity: 0,
          height: 0,
          marginBottom: 0,
          ...custom?.exit,
        }),
      },
      initial: 'initial',
      animate: 'animate',
      exit: 'exit',
      as: motion.div,
    },

    collapseMargin: {
      variants: {
        initial: {
          opacity: 0,
          height: 0,
          marginBottom: 0,
        },
        // animate: { opacity: 1, height: 'auto' },
        animate: custom => ({
          opacity: 1,
          height: 'auto',
          marginBottom: 24,
          ...custom,
        }),
        exit: {
          opacity: 0,
          height: 0,
          marginBottom: 0,
        },
      },
      initial: 'initial',
      animate: 'animate',
      exit: 'exit',
      as: motion.div,
    },
    tr: {
      variants: {
        initial: custom => ({
          opacity: 0,
          height: 0,
        }),
        animate: custom => ({
          opacity: 1,
          height: 'auto',
        }),
        exit: custom => ({
          opacity: 0,
          height: 0,
        }),
      },
      initial: 'initial',
      animate: 'animate',
      exit: 'exit',
      as: motion.tr,
    },
    parent: {
      initial: 'initial',
      animate: 'animate',
      exit: 'exit',
      as: motion.div,
    },
    tabContainer: {
      initial: 'enter',
      animate: 'center',
      exit: 'done',
    },
    tab: {
      as: motion.div,
      transition: { duration: 2 },
      variants: {
        enter: direction => {
          return {
            x: direction === 'prev' ? '100%' : '-100%',
            // opacity: 0,
            transition: { duration: 2 },
            // transition,
          }
        },
        center: {
          // zIndex: 1,
          x: 0,
          opacity: 1,
          transition: { duration: 2 },
          // transition,
        },
        done: direction => {
          return {
            // zIndex: 0,
            x: direction === 'prev' ? '-100%' : '100%',
            // opacity: 0,
            transition: { duration: 2 },
            // transition,
            position: 'absolute',
          }
        },
      },
      initial: 'enter',
      animate: 'center',
      exit: 'done',
    },
  },
  swr: {
    immutable: {
      revalidateOnFocus: false,
      // revalidateOnMount: false,
      revalidateOnReconnect: false,
    },
  },
}

export default sharedProps
