export const cognitoPassword = {
  value:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\"!@#%&\/\\,><\':;|_~`=+\-])[a-zA-Z0-9\^$*.\[\]{}\(\)?\"!@#%&\/\\,><\':;|_~`=+\-]{8,98}$/,
  message:
    'Please use at least one lowercase, uppercase, number and a special character',
}

export const alphaChars = {
  pattern: /^[A-Za-z]+$/,
  message: 'Please use alphabet characters only, no spaces',
}

export const htmlColor = {
  pattern:
    /^(#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})|rgb\(\s*(\d{1,3}\s*,\s*){2}\d{1,3}\s*\))$/,
  message:
    'Please enter a valid CSS color string, eg. #fca9ab or rgb(255,0,69)',
}

export const currencyNumber = {
  pattern: /^\d+(\.\d{1,2})?$/,
  message: 'Please enter a valid currency with two decimal places',
}

export const doubleDigits = {
  pattern: /^[0-9]{2}$/,
  message: 'Please enter a double digit decimal',
}
// const OLDpasswordRegex = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_\-+={[}\]|:;"'<,>.?\/]).{8,}$`
