import { MultiSelectElement, TextFieldElement } from 'react-hook-form-mui'
import client from '~/client'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'
import { multiSelectElementProps } from '~/components/Data/Filter/SearchContainer'

export default function UserSearchFields() {
  const roles = client.roles?.map(id => ({
    id,
    label: id.charAt(0).toUpperCase() + id.replace(/_/g, ' ').slice(1),
  }))
  return (
    <>
      <TextFieldElement label="First name" name="firstName" />
      <TextFieldElement label="Last name" name="lastName" />
      <TextFieldElement label="Email" name="email" />
      <MultiSelectElement
        {...multiSelectElementProps}
        name="status"
        label="Status"
        options={[
          { id: 'invited', label: 'Invited' },
          { id: 'confirmed', label: 'Confirmed' },
        ]}
      />
      <DatePickerElement name="inviteAcceptedAt.gte" label="Joined after" />
      <DatePickerElement name="inviteAcceptedAt.lte" label="Joined before" />
      <DatePickerElement name="inviteSentAt.gte" label="Invited after" />
      <DatePickerElement name="inviteSentAt.lte" label="Invited before" />
      <MultiSelectElement
        {...multiSelectElementProps}
        name="role"
        label="Role"
        options={roles}
      />
    </>
  )
}
