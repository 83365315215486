import Chip from '~/components/Data/Chip'
import SearchFormContainer from '~/components/Data/Filter/SearchFormContainer'

export const multiSelectElementProps = {
  // showChips
  sx: { textTransform: 'capitalize' },
  showCheckbox: true,
  multiple: true,
  renderValue: selected =>
    selected.map(option => (
      <Chip
        key={option}
        label={option}
        sx={{ my: -1, mr: 0.5 }}
        // onDelete={() => {
        //   field.onChange(field.value.filter(i => i !== selectedValue))
        // }}
        // deleteIcon={
        //   <Close
        //     onMouseDown={ev => {
        //       ev.stopPropagation()
        //     }}
        //   />
        // }
      />
    )),
}

function pruneObject(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      pruneObject(obj[key])
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    } else if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
      delete obj[key]
    }
  }
  return obj
}

const formatSearchObject = input => {
  if ([null, '', undefined, []].some(v => v === input)) return null

  if (Array.isArray(input)) return input.join(',')

  if (typeof input === 'string') return input.trim()

  if (typeof input.toISOString === 'function') return input.toISOString()

  if (typeof input !== 'object') return input

  const output = {} // Create a new object to hold the modified keys

  for (const key in input) {
    if (key === 'contents') {
      const [path, equals] = Object.entries(input[key])[0]
      if (equals)
        output['contents'] = {
          path: path.replace(/-/g, '.'),
          equals,
        }
      continue
    }
    const newKey = key.replace(/-/g, '.')
    const newValue = formatSearchObject(input[key])
    if (newValue !== null) output[newKey] = newValue
  }
  // console.log({ input, output: pruneObject(output) })
  return pruneObject(output)
}

export default function SearchContainer({ setSearch, ...rest }) {
  return (
    <SearchFormContainer
      {...rest}
      setSearch={data => setSearch(formatSearchObject(data))}
    />
  )
}
