import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'

import { Stack, Typography } from '@mui/material'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import FileUploadElement from '~/core/forms/FileUploadElement'
// import { alphaChars } from '~/core/utils/regex'
import useCompanyAssets from '~/routes/Admin/Company/Assets/useCompanyAssets'

const acceptExtensions = [
  // image
  '.jpg',
  '.jpeg',
  '.png',
  '.svg',
  //video
  '.mp4',
  //docs
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.xltx',
  '.ppt',
  '.pptx',
  '.pdf',
].join(',')

export default function UploadAssets() {
  const { uploadAsset, isSubmitting } = useCompanyAssets()

  const formContext = useForm({
    shouldUnregister: true,
    defaultValues: {
      files: null,
      meta: {
        title: '',
      },
    },
  })

  const onSuccess = async data => {
    try {
      await uploadAsset(data)
      formContext.setValue('files', null)
      formContext.reset()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <FormContainer formContext={formContext} onSuccess={onSuccess}>
      <Stack spacing={3} alignItems="flex-start">
        <Typography variant="h3">Upload asset</Typography>

        <FileUploadElement
          name="files"
          label="Assets"
          accept={acceptExtensions}
        />

        <TextFieldElement name="meta.title" label="Title" required />

        <SubmitButton
          disabled={!formContext.watch('files')?.length}
          isSubmitting={isSubmitting}
          children="Upload"
        />
      </Stack>
      <LoadingIndicators {...{ isSubmitting }} />
    </FormContainer>
  )
}
