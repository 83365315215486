import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'

import { Stack, Typography } from '@mui/material'
import React from 'react'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import { formatDate } from '~/core/utils/formatDate'

export default function EditAssetInfo({ asset, updateAsset, isUpdating, sx }) {
  const formContext = useForm({
    shouldUnregister: true,
    defaultValues: asset?.meta,
  })

  React.useEffect(() => {
    if (!asset?.meta) return
    formContext.reset(asset.meta)
  }, [asset])

  const onSuccess = meta =>
    updateAsset({ ...asset, meta: { ...asset.meta, ...meta } })

  return (
    <FormContainer formContext={formContext} onSuccess={onSuccess}>
      <Stack spacing={2} alignItems="flex-start" sx={sx}>
        <Typography variant="h4">Asset info</Typography>
        <Typography>
          Last modified {formatDate(asset?.meta?.LastModified)}
        </Typography>
        <TextFieldElement
          required
          // fullWidth
          name="title"
          label="Title"
          type="text"
          sx={{ width: '30%', minWidth: 200 }}
        />
        <SubmitButton isSubmitting={isUpdating} />
      </Stack>
      <LoadingIndicators isSubmitting={isUpdating} />
    </FormContainer>
  )
}
