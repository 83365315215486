import { Box, Card, Container, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import {
  FormContainer,
  PasswordElement,
  PasswordRepeatElement,
  useForm,
} from 'react-hook-form-mui'
import { useNavigate } from 'react-router-dom'
import client from '~/client'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import { cognitoPassword } from '~/core/forms/validation'
import PasswordFeedback from '~/routes/Auth/PasswordFeedback'

const defaultValues = {
  password: '',
  confirmPassword: '',
}

export const UpdatePasswordFields = () => (
  <>
    <PasswordElement
      fullWidth
      id="password"
      name="password"
      label="Password"
      required
      rules={{ pattern: cognitoPassword }}
      // sx={{ mb: 3 }}
      autoComplete="new-password"
    />

    <PasswordFeedback />

    <PasswordRepeatElement
      passwordFieldName="password"
      required
      fullWidth
      id="confirmPassword"
      name="confirmPassword"
      label="Confirm password"
      // sx={{ mb: 3 }}
      autoComplete="new-password"
    />
  </>
)

export default function UpdatePassword({ session, username }) {
  const navigate = useNavigate()
  const [isSubmitting, setSubmitting] = React.useState(false)

  const handleClick = async data => {
    try {
      if (data.password !== data.confirmPassword) {
        enqueueSnackbar('Passwords must match', { variant: 'error' })
        return
      }

      setSubmitting(true)
      const res = await client.changePassword({
        session,
        username,
        password: data.password,
      })

      console.log('UpdatePassword res', res)

      if (client.isSignedIn()) {
        setTimeout(() => navigate('/admin'))
      } else {
        console.error(res)
        throw (
          res.message?.message || 'UpdatePassword.jsx: error updating password'
        )
      }
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  const formContext = useForm({ defaultValues })

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <img src="/logo-unico-dark.svg" alt="The Unico System" />
        <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
          You must update your password
        </Typography>
      </Box>
      <Card sx={{ p: 3, py: 4, position: 'relative' }}>
        <FormContainer onSuccess={handleClick} formContext={formContext}>
          <UpdatePasswordFields />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SubmitButton
              isSubmitting={isSubmitting}
              sx={{ flexShrink: 0, alignSelf: 'start' }}
            >
              Update password
            </SubmitButton>
          </Box>
        </FormContainer>
        <LoadingIndicators isSubmitting={isSubmitting} />
      </Card>
    </Container>
  )
}
