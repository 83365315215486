import { Box, Card, Typography } from '@mui/material'
import { FormContainer } from 'react-hook-form-mui'
import { useLocation } from 'react-router-dom'
import sharedProps from '~/components/sharedProps'
import flexStackSx from '~/core/sx/flexStackSx'
import { UpdatePasswordFields } from '~/routes/Auth/UpdatePassword'
import StepButtons from '~/routes/Onboarding/StepButtons'

export default function OnboardingUpdatePassword({ children, ...rest }) {
  const { state } = useLocation()
  return (
    <FormContainer {...rest}>
      <Card {...sharedProps.card}>
        <Box sx={flexStackSx}>
          <Typography variant="h4">
            Hi {state?.user?.firstName || 'good sir'}, please update your
            password
          </Typography>
          <UpdatePasswordFields />
        </Box>
      </Card>
      <Card {...sharedProps.card}>
        <Typography variant="h4">
          Your sign-up should take less than 2 minutes to complete
        </Typography>
      </Card>
      {/* <StepButtons /> */}
      {children}
    </FormContainer>
  )
}
