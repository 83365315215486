import { Box, Card, Container, Stack, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import BuildIcon from '@mui/icons-material/Build'
import sharedProps from '~/components/sharedProps'

export default function Maintenance() {
  const { state } = useLocation()
  const message = state?.message || `We're offline for maintenance, back soon!`
  return (
    <Box
      spacing={8}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="sm">
        <Card {...sharedProps.card}>
          <Stack spacing={3} direction="row">
            <BuildIcon />
            <Typography>{message}</Typography>
          </Stack>
        </Card>
      </Container>
    </Box>
  )
}
