export const title = {
  name: 'title',
  title: 'Title',
  type: 'string',
  // validation: Rule => Rule.required(),
}
export const src = {
  name: 'src',
  type: 'url',
  validation: Rule =>
    Rule.uri({
      allowRelative: true,
    }),
}
export const file = { name: 'file', type: 'file' }

export const image = {
  name: 'image',
  title: 'Image',
  type: 'image',
  options: { hotspot: true },
}
export const source = {
  name: 'source',
  type: 'object',
  // icon: icons.Source,
  fields: [
    src,
    file,
    {
      name: 'type',
      type: 'string',
      options: {
        list: [
          'video/mp4; codec="avc1"',
          'video/mp4; codecs="hvc1"',
          'video/webm',
          'vimeo',
          // 'youtube',
        ],
      },
    },
  ],
}

const video = {
  name: 'video',
  type: 'object',
  // icon: icons.Video,
  fields: [
    title,
    { name: 'sources', type: 'array', of: [source] },
    { ...image, description: 'Optional poster image' },
    {
      name: 'aspectRatio',
      type: 'string',
      description: (
        <>
          Default is <code>16/9</code>
        </>
      ),
    },
  ],
}

export default video
