export default {
  name: 'banner',
  type: 'document',
  title: 'FastQuote banner',
  fields: [
    {
      name: 'title',
      type: 'string',
    },
    {
      name: 'items',
      type: 'array',
      of: [
        {
          name: 'item',
          type: 'object',
          fields: [
            {
              name: 'title',
              type: 'string',
            },
            {
              name: 'description',
              type: 'string',
            },
            {
              name: 'content',
              type: 'portableText',
            },
            {
              name: 'slug',
              type: 'slug',
            },
            {
              name: 'items',
              type: 'array',
              of: [
                {
                  name: 'heading', // heading
                  type: 'object',
                  fields: [
                    { name: 'title', type: 'string' },
                    { name: 'description', type: 'text' },
                  ],
                },
                {
                  name: 'template', // template
                  type: 'object',
                  fields: [
                    { name: 'title', type: 'string' },
                    { name: 'description', type: 'text' },
                    { name: 'slug', type: 'slug' },
                    { name: 'content', type: 'portableText' },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'content',
      type: 'portableText',
    },
  ],
}
