import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import client from '~/client'

const usePublicProposal = propsProposalId => {
  const { proposalId = propsProposalId } = useParams()
  const {
    data: proposal,
    mutate,
    ...rest
  } = useSWR(
    proposalId ? `publicproposal/?proposalId=${proposalId}` : null,
    client.fetcher
  )

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const updateStatus = async ({ status, statusReasons }) => {
    try {
      setIsSubmitting(status)
      await mutate(
        client.post('proposal/homeowner-review', {
          proposalId: proposal.proposalId,
          secretKey: proposal.secretKey,
          status,
          statusReasons,
        }),
        {
          optimisticData: data => ({
            ...data,
            status,
            // statusReasons,
          }),
          populateCache: false,
        }
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  return { ...rest, proposal, mutate, updateStatus, isSubmitting }
}

export default usePublicProposal

// const useSwrPllus = (...params) => {
//   const [isMutating, setIsMutating] = React.useState(false)
//   const { _mutate, ...rest } = useSwr(...params)

//   const mutate = async (fn, options) => {
//     if (typeof fn.then === 'function') {
//       try {
//         setIsMutating(true)
//         await fn
//         return fn
//       } finally {
//         setIsMutating(false)
//       }
//     } else return mutate(fn, options)
//   }

//   return { mutate, ...rest }
// }
