import {
  AssetsIcon,
  BenefitsOrValueIcon,
  GatesIcon,
  NextStepsIcon,
  ObjectionHandlingIcon,
  QuestionsIcon,
  StakeholdersIcon,
  SummaryIcon,
} from '~/routes/Admin/SalesResources/Subcard/SubcardIcons'

const subcardSections = [
  { name: 'summary', title: 'Summary', Icon: SummaryIcon },
  { name: 'next', title: 'Next steps', Icon: NextStepsIcon },
  { name: 'questions', title: 'Questions', Icon: QuestionsIcon },
  { name: 'assets', title: 'Assets', Icon: AssetsIcon },
  {
    name: 'objection',
    title: 'Objection handling',
    Icon: ObjectionHandlingIcon,
  },
  { name: 'gates', title: 'Gates', Icon: GatesIcon },
  { name: 'benefits', title: 'Benefits or value', Icon: BenefitsOrValueIcon },
  { name: 'stakeholders', title: 'Stakeholders', Icon: StakeholdersIcon },
]

const formatSubcards = subcards =>
  subcardSections.map(section => {
    const subcard = subcards?.find(({ title }) => title === section.title)
    return {
      ...section,
      ...subcard,
      disabled: !subcard,
    }
  })
// .push(...subcards)

export default formatSubcards
