import { ButtonGroup, Select, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useIsPresent } from 'framer-motion'
import React from 'react'
import {
  CheckboxButtonGroup,
  TextFieldElement,
  ToggleButtonGroupElement,
} from 'react-hook-form-mui'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'

import SelectElement from '~/core/forms/SelectElement'

const componentMapping = [
  { form: TextFieldElement, mui: TextField },
  { form: ToggleButtonGroupElement, mui: ButtonGroup },
  { form: SelectElement, mui: Select },
  { form: DatePickerElement, mui: DatePicker },
  // { form: CheckboxButtonGroup, mui: Checkb}
]

const FieldPresenceSwitch = ({ children }) => {
  const present = useIsPresent()
  if (present) return children

  return React.Children.map(children, (child, i) => {
    const Replacement = componentMapping.find(
      ({ form }) => child.type === form
    )?.mui

    if (Replacement)
      return <Replacement {...child.props} key={child.props.name} />

    return React.cloneElement(child, {
      key: child.props.name,
    })
  })
}
export default FieldPresenceSwitch

// const PresenceSwitch = ({ name, children }) =>
//   React.Children.map(children, child => {
//     if (!useIsPresent()) {
//       const Replacement = componentMapping.find(
//         ({ form }) => child.type === form
//       )?.mui

//       if (Replacement) return <Replacement {...child.props} />
//     }

//     return React.cloneElement(child, {
//       name: `${name}.${child.props.name}`,
//       key: `${name}.${child.props.name}`,
//     })
//   })
