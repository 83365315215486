import { Dialog, ImageList, ImageListItem, Typography } from '@mui/material'
import * as React from 'react'
import AssetPreview from '~/routes/Admin/Company/Assets/AssetPreview'
import AssetViewport from '~/routes/Admin/Company/Assets/AssetViewport'

const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN

const isAssetPreviewable = asset => {
  const contentType = asset?.meta?.ContentType || ''
  const isPreviewAble =
    contentType.startsWith('image/') || contentType.startsWith('video/')
  return isPreviewAble
}

export default function AssetList({
  assets,
  cols = 4,
  gap = 8,
  onClick,
  sx,
  ...rest
}) {
  const [asset, setAsset] = React.useState(null)

  const handleClose = () => setAsset(null)

  const handleClick = onClick || setAsset

  return (
    <>
      <ImageList
        {...{ cols, gap }}
        {...rest}
        sx={{ ...sx, overflow: 'visible' }}
      >
        {assets?.map(asset => (
          <ImageListItem
            key={asset?.url}
            onClick={() => handleClick(asset)}
            sx={{
              transition: 'transform .1s',

              '&:hover': {
                transform: 'scale(1.02)',
                zIndex: 1,
                position: 'relative',
              },
            }}
          >
            <AssetPreview
              asset={asset}
              disableDownload={typeof onClick === 'function'}
              sx={{
                aspectRatio: '16/10',
                cursor: typeof onClick === 'function' ? 'pointer' : 'default',
                borderRadius: 0.5,
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      {/* {assets?.map(asset => (
        <AssetPreview asset={asset} />
      ))} */}
      {assets?.length === 0 && (
        <Typography sx={{ textAlign: 'center' }}>
          No assets to display
        </Typography>
      )}
      <Dialog
        scroll="paper"
        disableAutoFocus
        onClose={handleClose}
        open={isAssetPreviewable(asset)}
        PaperProps={{ sx: { p: 2 } }}
      >
        <AssetViewport asset={asset} />
      </Dialog>
    </>
  )
}
