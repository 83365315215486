import { alpha, styled } from '@mui/material'
import { Box } from '@mui/material'
import React from 'react'
import { useDropzone } from 'react-dropzone'

export const DropZoneBackdrop = styled('div')(
  ({ isDragAccept, isDragReject, theme }) => ({
    position: 'absolute',
    pointerEvents: 'none',
    // opacity: isDragActive ? 0.5 : 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'background 0.2s, border-color 0.3s',
    border: `2px solid transparent`,
    ...(isDragAccept && {
      background: alpha(theme.palette.primary.main, 0.2),
      border: `2px solid ${theme.palette.primary.main}`,
    }),
    ...(isDragReject && {
      background: alpha(theme.palette.error.main, 0.2),
      border: `2px solid ${theme.palette.error.main}`,
    }),
  })
)

const StyledDropzone = ({ children, backdrop, onChange, sx, ...rest }) => {
  const onDrop = files => onChange({ target: { files } })

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    ...rest,
  })

  const { onClick, ...rootProps } = getRootProps()

  return (
    <div {...rootProps}>
      <input {...getInputProps()} />
      {/* {backdrop && ( */}
      <DropZoneBackdrop
        {...{ isDragActive, isDragAccept, isDragReject }}
        sx={sx}
      />
      {/* )} */}
      {children}
      {/* {React.Children.map(
        children,
        child =>
          child &&
          React.cloneElement(child, {
            isDragActive,
            isDragAccept,
            isDragReject,
          })
      )} */}
    </div>
  )
}

export default StyledDropzone
