import { Button, Stack, Typography } from '@mui/material'

import { DeleteOutline } from '@mui/icons-material'

const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN

export default function DeleteAsset({ onClick, ...rest }) {
  return (
    <Stack
      // {...sharedProps.stack.form}
      alignItems="flex-start"
      spacing={2}
      {...rest}
    >
      <Typography variant="h4">Delete asset</Typography>
      <Button
        onClick={onClick}
        color="error"
        endIcon={<DeleteOutline />}
        variant="contained"
      >
        Delete
      </Button>
    </Stack>
  )
}
