import { schemaTypes } from 'sanity/schemas'

const findIn = (key, fields) => fields.find(field => field.name === key)

export const findOptions = keys => {
  let field = { fields: schemaTypes }
  for (const key of keys) field = findIn(key, field.fields)
  return field.options.list || console.error(keys, ' not found')
}

export const findAllowedFields = category => {
  let field = { fields: schemaTypes }
  for (const key of ['products', 'spec']) field = findIn(key, field.fields)

  const allowedFields = field?.fields.flatMap(({ visibleCategories, name }) =>
    !visibleCategories || visibleCategories.includes(category) ? name : []
  )

  return allowedFields
}

const categories = findOptions(['products', 'spec', 'category'])

export const categoryNameToTitle = category =>
  categories.find(cat => cat.value === category)?.title || 'Category not found'

export const findFieldnames = keys => {
  let field = { fields: schemaTypes }
  for (const key of keys) field = findIn(key, field.fields)
  // return field.options.list || console.error(keys, ' not found')
  return field?.fields?.map(f => f.name) || console.error(keys, ' not found')
}

export const filterAllowedFields = (o, a) =>
  Object.fromEntries(Object.entries(o).filter(([k, v]) => a.includes(k)))

const mapOptionsToMui = option =>
  typeof option === 'string'
    ? {
        value: option,
        label: option,
        id: option,
      }
    : {
        ...option,
        id: option.value,
        label: option.title,
      }

export const findOptionsMap = keys =>
  findOptions(keys)?.map(mapOptionsToMui) || [
    {
      value: keys.join() + ' not found',
      label: console.log(keys.join() + ' not found'),
      id: keys.join() + ' not found',
    },
  ]
