export const stateOptions = [
  { id: 'AL', label: 'Alabama', country: 'USA' },
  { id: 'AK', label: 'Alaska', country: 'USA' },
  { id: 'AZ', label: 'Arizona', country: 'USA' },
  { id: 'AR', label: 'Arkansas', country: 'USA' },
  { id: 'CA', label: 'California', state: 'USA' },
  { id: 'CANADA', label: 'Canada', country: 'Canada' },
  { id: 'CO', label: 'Colorado', country: 'USA' },
  { id: 'CT', label: 'Connecticut', country: 'USA' },
  { id: 'DE', label: 'Delaware', country: 'USA' },
  { id: 'FL', label: 'Florida', country: 'USA' },
  { id: 'GA', label: 'Georgia', country: 'USA' },
  { id: 'HI', label: 'Hawaii', country: 'USA' },
  { id: 'ID', label: 'Idaho', country: 'USA' },
  { id: 'IL', label: 'Illinois', country: 'USA' },
  { id: 'IN', label: 'Indiana', country: 'USA' },
  { id: 'IA', label: 'Iowa', country: 'USA' },
  { id: 'KS', label: 'Kansas', country: 'USA' },
  { id: 'KY', label: 'Kentucky', country: 'USA' },
  { id: 'LA', label: 'Louisiana', country: 'USA' },
  { id: 'ME', label: 'Maine', country: 'USA' },
  { id: 'MD', label: 'Maryland', country: 'USA' },
  { id: 'MA', label: 'Massachusetts', country: 'USA' },
  { id: 'MI', label: 'Michigan', country: 'USA' },
  { id: 'MN', label: 'Minnesota', country: 'USA' },
  { id: 'MS', label: 'Mississippi', country: 'USA' },
  { id: 'MO', label: 'Missouri', country: 'USA' },
  { id: 'MT', label: 'Montana', country: 'USA' },
  { id: 'NE', label: 'Nebraska', country: 'USA' },
  { id: 'NV', label: 'Nevada', country: 'USA' },
  { id: 'NH', label: 'New Hampshire', country: 'USA' },
  { id: 'NJ', label: 'New Jersey', country: 'USA' },
  { id: 'NM', label: 'New Mexico', country: 'USA' },
  { id: 'NY', label: 'New York', country: 'USA' },
  { id: 'NC', label: 'North Carolina', country: 'USA' },
  { id: 'ND', label: 'North Dakota', country: 'USA' },
  { id: 'OH', label: 'Ohio', country: 'USA' },
  { id: 'OK', label: 'Oklahoma', country: 'USA' },
  { id: 'OR', label: 'Oregon', country: 'USA' },
  { id: 'PA', label: 'Pennsylvania', country: 'USA' },
  { id: 'RI', label: 'Rhode Island', country: 'USA' },
  { id: 'SC', label: 'South Carolina', country: 'USA' },
  { id: 'SD', label: 'South Dakota', country: 'USA' },
  { id: 'TN', label: 'Tennessee', country: 'USA' },
  { id: 'TX', label: 'Texas', country: 'USA' },
  { id: 'UT', label: 'Utah', country: 'USA' },
  { id: 'VT', label: 'Vermont', country: 'USA' },
  { id: 'VA', label: 'Virginia', country: 'USA' },
  { id: 'WA', label: 'Washington', country: 'USA' },
  { id: 'WV', label: 'West Virginia', country: 'USA' },
  { id: 'WI', label: 'Wisconsin', country: 'USA' },
  { id: 'WY', label: 'Wyoming', country: 'USA' },
  { id: 'DC', label: 'District of Columbia', country: 'USA' },
  { id: 'AS', label: 'American Samoa', country: 'Outlying territory' },
  { id: 'GU', label: 'Guam', country: 'Outlying territory' },
  {
    id: 'MP',
    label: 'Northern Mariana Islands',
    country: 'Outlying territory',
  },
  { id: 'PR', label: 'Puerto Rico', country: 'Outlying territory' },
  {
    id: 'UM',
    label: 'United States Minor Outlying Islands',
    country: 'Outlying territory',
  },
  { id: 'VI', label: 'Virgin Islands US', country: 'Outlying territory' },
  { id: 'OTHER', label: 'Other', country: 'Other' },
]
