import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { Highlight } from '~/routes/ViewProposal/Subcomponents'

export default function MaterialsList({ lineItems, project }) {
  // if (!project?.includeMaterialsList) return null

  return (
    <Box sx={{ maxWidth: 540, mx: 'auto' }}>
      <Typography variant="h2" gutterBottom>
        Materials list
      </Typography>
      <Highlight sx={{ width: '100%' }} />
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
          {lineItems
            ?.filter(({ category }) => category !== 'labor')
            .map(
              ({ description, title = description, quantity, modelNumber }) => (
                <TableRow key={modelNumber}>
                  <TableCell>{title}</TableCell>
                  <TableCell align="right">{quantity}</TableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
    </Box>
  )
}
