import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  InputLabel,
  styled,
} from '@mui/material'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import ImageIcon from '@mui/icons-material/Image'
import AssetPreview from '~/routes/Admin/Company/Assets/AssetPreview'
import AttachFileIcon from '@mui/icons-material/AttachFile'
const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN

const StyledDiv = styled('div')({
  width: '100%',
  // aspectRatio: '16/9',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: 4,
  minHeight: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginBottom: 8,
  padding: 8,
  minWidth: 120,
  '& img': {
    maxWidth: 320,
    maxHeight: 160,
  },
})

const FileUploadElement = ({
  name,
  multiple,
  label = 'Image upload',
  ...rest
}) => {
  const [previewAsset, setPreviewAsset] = useState(null)
  const { control, watch } = useFormContext()

  const handleFileSelection = event => {
    console.log('handleImageSelection', event)
    const file = event.target.files[0]

    const reader = new FileReader()

    reader.onloadend = () => {
      const asset = {
        meta: {
          title: file.name,
          ContentType: file.type,
        },
        url: reader.result,
      }
      setPreviewAsset(asset)
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const value = watch(name)

  React.useEffect(() => {
    if (!value) setPreviewAsset(null)
  }, [value])

  // const onSubmit = data => {
  //   const formData = new FormData()
  //   formData.append('image', data.image[0])

  //   // Send formData to server using API
  //   // ...
  // }

  // console.log('watch', name, watch(name))

  const asset = previewAsset || value

  return (
    <Controller
      name={name}
      control={control}
      // rules={{ required: true }}
      render={({ field: { onChange, onBlur, value, ref, ...field } }) => (
        <label htmlFor={name}>
          <StyledDiv>
            {/* <FormControlLabel label="hello"></FormControlLabel> */}
            <FormLabel
              component="div"
              sx={{
                transform: 'translate(14px, -9px) scale(0.75)',
                zIndex: 2,
                // backgroundColor: '#383838',
                backgroundColor: '#fff',
                transformOrigin: 'top left',
                position: 'absolute',
                top: 0,
                left: 0,
                px: 1,
                ml: -1,
              }}
            >
              {label}
            </FormLabel>

            <input
              name={name}
              type="file"
              // accept="image/*,.pdf"
              onChange={e => {
                handleFileSelection(e)
                // onChange(e)
                // console.log('onChange', e)
                onChange(e.target.files)
              }}
              onBlur={onBlur}
              ref={ref}
              // value={value?.fileName || ''}
              hidden
              id={name}
              {...rest}
              {...field}
              n={console.log('VALUE is', value)}
            />

            {previewAsset ? (
              <AssetPreview asset={asset} />
            ) : (
              <AttachFileIcon
                fontSize="large"
                sx={{ color: 'rgba(0, 0, 0, 0.23)' }}
              />
            )}
          </StyledDiv>
          <Button
            component="div"
            endIcon={<FileUploadIcon />}
            // sx={{ '&, &:hover': { background: 'white' } }}
          >
            Choose {name}
          </Button>
        </label>
      )}
    />
  )
}

export default FileUploadElement
