import AddIcon from '@mui/icons-material/Add'
import { Button, Card, Stack } from '@mui/material'
import Drawer from '~/components/Layouts/Drawer'
import PageHeader from '~/components/Layouts/PageHeader'
import Link from '~/config/Link'
import useSubmatch from '~/core/router/useSubmatch'
import CompaniesTable from '~/components/Companies/CompaniesTable'
import EditCompanyForm from '~/components/Companies/EditCompanyForm'
import AddCompanyForm from '~/components/Companies/AddCompanyForm'

export default function SuperadminCompanies({}) {
  return (
    <>
      <PageHeader />
      <Stack
        direction="row"
        spacing={3}
        sx={{ justifyContent: 'flex-end', mb: 3 }}
      >
        <Link to="add">
          <Button variant="outlined" endIcon={<AddIcon />}>
            Add new company
          </Button>
        </Link>
      </Stack>
      <Card sx={{ position: 'relative', p: 2 }}>
        <CompaniesTable />
      </Card>
      <Drawer open={useSubmatch('add')}>
        <AddCompanyForm />
      </Drawer>
      <Drawer open={useSubmatch('edit')}>
        <EditCompanyForm />
      </Drawer>
    </>
  )
}
