import { Button, Card, Stack } from '@mui/material'
import Drawer from '~/components/Layouts/Drawer'
import PageHeader from '~/components/Layouts/PageHeader'
import Link from '~/config/Link'
import useSubmatch from '~/core/router/useSubmatch'
import AddCompanyForm from '~/components/Companies/AddCompanyForm'
import EditCompanyForm from '~/components/Companies/EditCompanyForm'
// import AddCompanyForm from './AddCompanyForm'
import AddIcon from '@mui/icons-material/Add'
import RepGroupCompaniesTable from '~/routes/Admin/RepGroup/Companies/CompaniesTable'
import AutoTable, { AddressCell, UsersCell } from '~/components/Data/AutoTable'
import useCompanies from '~/components/Companies/useCompanies'
import SearchContainer from '~/components/Data/Filter/SearchContainer'
import CompanyDetailsFields from '~/routes/Admin/Company/Profile/CompanyDetailsFields'
import React from 'react'
import CompanySearchFields from '~/components/Data/Filter/CompanySearchFields'
import { TextFieldElement } from 'react-hook-form-mui'

export default function RepGroupCompanies({}) {
  const [search, setSearch] = React.useState()

  const { data, ...response } = useCompanies({
    query: 'repCompanyRelations/companies',
    search,
  })

  return (
    <>
      <PageHeader />
      <Stack
        direction="row"
        spacing={3}
        sx={{ justifyContent: 'flex-end', mb: 3 }}
      >
        <Link to="add">
          <Button variant="outlined" endIcon={<AddIcon />}>
            Add new company
          </Button>
        </Link>
      </Stack>
      <Card sx={{ position: 'relative', p: 2 }}>
        <SearchContainer setSearch={setSearch}>
          <TextFieldElement
            label="Company name"
            name="contractingFirm.companyName"
          />
        </SearchContainer>
        <AutoTable
          {...response}
          data={data?.map(relation => relation.contractingFirm)}
          titleField="companyName"
          uniqueField="companyId"
          columns={[
            'companyName',
            { headerName: 'Address', Component: AddressCell },
            { Component: UsersCell, field: 'editUsers' },
          ]}
        />
      </Card>
      <Drawer open={useSubmatch('add')}>
        <AddCompanyForm />
      </Drawer>
      <Drawer open={useSubmatch('edit')}>
        <EditCompanyForm />
      </Drawer>
    </>
  )
}
