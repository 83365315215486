import { Check, Close } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form-mui'

export const passwordCriteria = [
  { message: 'Password minimum length: 8 characters', pattern: /^(?=.{8,})/ },
  { message: 'Contains at least 1 number', pattern: /(?=.*\d)/ },
  {
    message: 'Contains at least 1 special character',
    pattern: /(?=.*[\^\$\*\.\[\]\{\}\(\)\?"!@#%&/\\,><':;|_~`+\-])/,
  },
  { message: 'Contains at least 1 uppercase letter', pattern: /(?=.*[A-Z])/ },
  { message: 'Contains at least 1 lowercase letter', pattern: /(?=.*[a-z])/ },
]

export const testCriteria = (input, criteria) =>
  criteria.map(({ message, pattern }) => ({
    message,
    pattern,
    valid: pattern.test(input),
  }))

export const testPassword = (input, criteria = passwordCriteria) =>
  testCriteria(input, criteria)

const iconProps = {
  fontSize: 'inherit',
  sx: { mr: 0.5 },
}

export default function PasswordFeedback({ sx, ...rest }) {
  const formContext = useFormContext()
  const { watch } = formContext

  const password = watch('password')
  const feedback = testPassword(password)
  // const isValid = passwordFeedback.every(({ valid }) => !!valid)

  return (
    <Stack
      spacing={1}
      {...rest}
      as="ul"
      sx={{
        listStyleType: 'none',
        p: 0,
        m: 0,
        ...sx,
        // mb: 3,
        '& li': {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      {feedback.map(({ message, pattern, valid }) => (
        <Typography color={valid ? 'success.dark' : 'inherit'} as="li">
          {valid ? <Check {...iconProps} /> : <Close {...iconProps} />}{' '}
          <span>{message}</span>
        </Typography>
      ))}
    </Stack>
  )
}
