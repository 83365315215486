import styled from '~/config/styled'

const Svg = styled('svg')({})

const UnicoLogo = props => (
  <Svg
    width={156}
    height={27}
    viewBox="0 0 156 27"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_162_7954"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={156}
      height={27}
    >
      <path
        d="M86.8751 0.0209182C84.986 0.0209182 82.9938 1.5526 82.9938 3.44298V21.6736C83.0705 24.7331 82.5773 24.5681 80.102 24.5673H58.8954H59.0603H37.1259C34.6514 24.5681 34.1574 24.7331 34.2341 21.6736V3.44221C34.2341 1.5526 32.2412 0.0201435 30.3528 0.0201435L0 0V2.66126H28.1261C30.3869 2.63285 31.5271 3.7738 31.5467 6.0841V23.5772C31.5467 25.4675 33.0774 27 34.965 27H82.2606C84.1498 27 85.6812 25.4668 85.6812 23.5772V6.0841C85.6993 3.7738 86.8387 2.63285 89.0995 2.66126L155.527 2.63957V0L86.8736 0.0209182H86.8751Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_162_7954)">
      <path d="M155.528 0H0V27H155.528V0Z" fill="currentColor" />
    </g>
    <path
      d="M0 5.24365V7.8104H2.754V22.1634H6.69723V7.8104H9.452V5.24365H0Z"
      fill="currentColor"
    />
    <path
      d="M10.3069 22.1629V5.24316H14.0597V10.2573H14.1077C14.4871 9.06887 15.3891 8.40414 16.7657 8.40414C18.6672 8.40414 19.378 9.66233 19.378 11.6348V22.1629H15.6268V12.9666C15.6268 12.2298 15.6028 11.4931 14.8432 11.4931C14.0837 11.4931 14.0589 12.2298 14.0589 12.9666V22.1629H10.3062H10.3069Z"
      fill="currentColor"
    />
    <path
      d="M24.1758 15.7939V18.5512C24.1758 19.3592 24.4607 19.8574 24.9601 19.8574C25.5532 19.8574 25.7437 19.3585 25.7437 18.5512V16.7925H29.4964V18.7875C29.4964 21.355 27.5964 22.3296 24.9609 22.3296C22.5623 22.3296 20.4238 21.2357 20.4238 18.717V12.23C20.4238 9.7818 22.0397 8.4043 24.9609 8.4043C27.3588 8.4043 29.4964 9.3061 29.4964 12.23V15.7939H24.1766H24.1758ZM25.7437 13.5362V11.683C25.7437 11.1601 25.4828 10.875 24.9601 10.875C24.4375 10.875 24.1758 11.1601 24.1758 11.683V13.5362H25.7437Z"
      fill="currentColor"
    />
    <path
      d="M46.0246 17.3393C45.9294 20.9272 44.6952 22.4953 41.0137 22.4953C36.1452 22.4953 36.0508 19.0972 36.0508 16.9589V5.24316H39.9932V17.4346C39.9932 18.7889 39.9932 19.7868 41.0145 19.7868C42.0357 19.7868 42.0837 18.7889 42.0837 17.4346V5.24316H46.0262V17.3393H46.0246Z"
      fill="currentColor"
    />
    <path
      d="M50.8211 10.2575H50.8684C51.2253 9.06981 52.1273 8.4043 53.5999 8.4043C55.2862 8.4043 56.1418 9.56874 56.1418 11.5172V22.1631H52.389V12.7305C52.389 12.1347 52.3418 11.494 51.6287 11.494C50.9156 11.494 50.8211 12.1347 50.8211 12.7305V22.1631H47.0684V8.57009H50.8211V10.2575Z"
      fill="currentColor"
    />
    <path
      d="M57.209 5.24316H60.9618V7.64334H57.209V5.24316ZM57.209 8.57149H60.9618V22.1645H57.209V8.57071V8.57149Z"
      fill="currentColor"
    />
    <path
      d="M67.3493 11.8266C67.3493 11.3276 67.1581 10.8759 66.565 10.8759C65.972 10.8759 65.7815 11.3276 65.7815 11.8266V18.9085C65.7815 19.4075 65.9712 19.8592 66.565 19.8592C67.1589 19.8592 67.3493 19.4075 67.3493 18.9085V16.7935H71.1021V18.79C71.1021 21.356 69.2021 22.3306 66.5426 22.3306C63.739 22.3306 62.0303 21.1669 62.0303 18.5529V12.207C62.0303 9.97414 63.6926 8.40527 66.5426 8.40527C68.9644 8.40527 71.1021 9.30786 71.1021 12.2325V13.3722H67.3493V11.8273V11.8266Z"
      fill="currentColor"
    />
    <path
      d="M81.2176 18.7178C81.2176 21.2357 79.0799 22.3296 76.6821 22.3296C74.2843 22.3296 72.145 21.2357 72.145 18.7178V12.23C72.145 9.78258 73.7609 8.4043 76.6821 8.4043C79.6033 8.4043 81.2176 9.78258 81.2176 12.23V18.7178ZM75.8978 18.9076C75.8978 19.4065 76.089 19.8582 76.6821 19.8582C77.2752 19.8582 77.4656 19.4065 77.4656 18.9076V11.8256C77.4656 11.3266 77.2759 10.875 76.6821 10.875C76.0883 10.875 75.8978 11.3266 75.8978 11.8256V18.9076Z"
      fill="currentColor"
    />
    <path
      d="M93.8039 11.3031V8.92694C93.8039 8.09486 93.543 7.61917 92.7587 7.61917C91.9032 7.61917 91.7135 8.09486 91.7135 8.92694C91.7135 11.778 97.7456 12.6109 97.7456 17.8381C97.7456 21.0231 96.0361 22.4959 92.7355 22.4959C90.2176 22.4959 87.771 21.6413 87.771 18.7182V15.5813H91.7135V18.5044C91.7135 19.5023 92.0224 19.7881 92.7587 19.7881C93.3998 19.7881 93.8039 19.5023 93.8039 18.5044C93.8039 14.6787 87.7718 14.6066 87.7718 9.14C87.7718 6.07509 89.791 4.91064 92.8307 4.91064C95.4902 4.91064 97.7456 5.81478 97.7456 8.64261V11.3039H93.8032L93.8039 11.3031Z"
      fill="currentColor"
    />
    <path
      d="M102.353 8.5708L103.612 17.9569H103.66L104.917 8.5708H108.076L105.037 22.9006C104.728 24.4454 103.636 25.2062 101.997 25.2062H98.9796V22.7348H100.928C101.426 22.7348 101.592 22.6147 101.592 22.3536C101.592 22.1638 101.545 21.9964 101.497 21.7586L98.5522 8.5708H102.351H102.353Z"
      fill="currentColor"
    />
    <path
      d="M114.227 13.3707V11.8972C114.227 11.3277 114.036 10.8753 113.443 10.8753C112.85 10.8753 112.659 11.3269 112.659 11.8972C112.659 14.2268 117.909 13.7984 117.909 18.2183C117.909 20.9036 116.293 22.3299 113.158 22.3299C110.759 22.3299 108.907 21.4033 108.907 18.9551V16.7928H112.66V18.8358C112.66 19.4068 112.85 19.8585 113.444 19.8585C114.037 19.8585 114.228 19.4068 114.228 18.8358C114.228 15.6051 108.908 16.603 108.908 12.183C108.908 9.47296 110.832 8.40381 113.302 8.40381C116.152 8.40381 117.981 9.52022 117.981 11.8019V13.3707H114.228H114.227Z"
      fill="currentColor"
    />
    <path
      d="M118.692 11.0422V8.56999H119.951V6.12256H123.702V8.56999H125.342V11.0422H123.702V18.4558C123.702 19.2646 123.726 19.6923 124.606 19.6923H125.342V22.1622H122.279C120.403 22.1622 119.951 21.1178 119.951 19.3344V11.0422H118.692Z"
      fill="currentColor"
    />
    <path
      d="M129.806 15.7939V18.5512C129.806 19.3592 130.091 19.8574 130.59 19.8574C131.183 19.8574 131.373 19.3585 131.373 18.5512V16.7925H135.126V18.7875C135.126 21.355 133.227 22.3296 130.591 22.3296C128.192 22.3296 126.055 21.2357 126.055 18.717V12.23C126.055 9.7818 127.669 8.4043 130.591 8.4043C132.989 8.4043 135.126 9.3061 135.126 12.23V15.7939H129.806H129.806ZM131.373 13.5362V11.683C131.373 11.1601 131.113 10.875 130.59 10.875C130.067 10.875 129.806 11.1601 129.806 11.683V13.5362H131.373Z"
      fill="currentColor"
    />
    <path
      d="M139.828 10.3762H139.875C140.256 9.11649 141.11 8.40527 142.487 8.40527C143.864 8.40527 144.672 9.07001 144.933 10.3762H144.981C145.195 9.16453 146.24 8.40527 147.569 8.40527C149.445 8.40527 150.039 9.61698 150.039 11.2788V22.1633H146.431V12.7291C146.431 12.1341 146.382 11.4926 145.671 11.4926C144.959 11.4926 144.934 12.1341 144.934 12.7291V22.1633H141.326V12.7291C141.326 12.1341 141.278 11.4926 140.565 11.4926C139.853 11.4926 139.829 12.1341 139.829 12.7291V22.1633H136.219V8.5703H139.829V10.377L139.828 10.3762Z"
      fill="currentColor"
    />
    <path
      d="M151.174 10.3921C151.174 9.07736 152.22 7.99658 153.511 7.99658C154.802 7.99658 155.848 9.07116 155.848 10.3921C155.848 11.7131 154.809 12.7737 153.511 12.7737C152.213 12.7737 151.174 11.6983 151.174 10.3921ZM153.511 12.5118C154.6 12.5118 155.528 11.5039 155.528 10.3921C155.528 9.28034 154.607 8.2569 153.511 8.2569C152.415 8.2569 151.494 9.26562 151.494 10.3921C151.494 11.5186 152.43 12.5118 153.511 12.5118ZM152.401 9.04094H153.576C153.99 9.04094 154.396 9.23076 154.396 9.65842C154.396 10.0357 154.156 10.2968 153.823 10.3999L154.229 10.9515C154.425 11.2126 154.658 11.4667 154.795 11.5395V11.5976H154.418C154.177 11.5976 153.996 11.1901 153.461 10.4572H153.141V11.0964C153.141 11.4303 153.126 11.414 153.489 11.452V11.5976H152.401V11.452C152.763 11.414 152.749 11.4303 152.749 11.0964V9.52749C152.749 9.17885 152.763 9.20054 152.401 9.1649V9.04017V9.04094ZM153.14 10.3123H153.474C153.822 10.3123 154.004 10.1085 154.004 9.69561C154.004 9.37564 153.831 9.1866 153.445 9.1866H153.14V10.3123Z"
      fill="currentColor"
    />
  </Svg>
)

export default UnicoLogo
