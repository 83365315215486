import { Box } from '@mui/material'
import AppStyles from '~/components/Layouts/AppStyles'
import { CardInfo } from '~/routes/Admin/SalesResources/Card'
import { CloseButton } from '~/routes/Admin/SalesResources/CloseButton'
import Column, { ColumnContent } from '~/routes/Admin/SalesResources/Column'
import { useBoardState } from '~/routes/Admin/SalesResources/Provider/BoardProvider'
import useBoard from '~/routes/Admin/SalesResources/hooks/useBoard'
// fake data generator
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import StyledCard, {
  StyledColumn,
} from '~/routes/Admin/SalesResources/StyledCard'
// const colors = ['#FF4D70', '#FF7C4D', '#D14DFF', '#72D761']

export default function ViewBoard({}) {
  const { boardRef, boardId, toCard, toBoard, toRoot } = useBoardState()
  const { data: board, isLoading, ...rest } = useBoard(boardId)

  console.log('sanity board data', board, rest)

  return (
    <>
      <AppStyles />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          background: '#F9F9FC',
          mb: 0,
          position: 'relative',
        }}
      >
        <LoadingIndicators {...{ isLoading }} />
        <StyledColumn
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            mb: 3,
            borderTopColor: board?.color,
          }}
        >
          <ColumnContent column={board} />
          <CloseButton onClick={toRoot}>Close process</CloseButton>
        </StyledColumn>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 0,
            mb: 0,
            overflowX: 'scroll',
            width: '100%',
            // overflowY: 'hidden',
            // overflow: 'scroll',
            pb: 3,
          }}
        >
          {board?.columns?.map((column, i) => (
            <Column
              {...{ column }}
              sx={{ borderTopColor: 'primary.main', flexShrink: 0 }}
            >
              {column.cards?.map((card, i) => (
                <StyledCard onClick={() => toCard(card._id)} component="a">
                  <CardInfo card={card} />
                </StyledCard>
              ))}
            </Column>
          ))}
        </Box>
      </Box>
    </>
  )
}
