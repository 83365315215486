import { Close } from '@mui/icons-material'
import { Button } from '@mui/material'

export const CloseButton = ({ children = 'Close', ...props }) => (
  <Button
    variant="text"
    startIcon={<Close />}
    sx={{
      // position: 'absolute',
      // right: 16,
      // top: 16,
      // alignSelf: 'flex-end',
      whiteSpace: 'nowrap',
      mr: 0,
      ml: 'auto',
      display: 'flex',
      p: 1,
      pb: 0.5,
    }}
    {...props}
  >
    {children}
  </Button>
)
