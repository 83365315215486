import { Box, Modal } from '@mui/material'
import React from 'react'
import MediaCard from '~/routes/Admin/SalesResources/Subcard/MediaCard'

const MediaGrid = ({ assets, ...rest }) => {
  const [asset, setAsset] = React.useState(null)
  // const viableAssets = assets?.filter(asset => )
  // console.log('MediaGrid', { assets })

  const handleClick = asset => {
    if (
      asset.mimeType?.startsWith('image') ||
      asset.mimeType?.startsWith('video')
    )
      setAsset(asset)
  }
  return (
    <>
      <Modal
        open={!!asset}
        onClose={() => setAsset(null)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: '80%',
            maxHeight: '80%',
            aspectRatio: asset?.metadata?.dimensions?.aspectRatio,
            background: `url(${asset?.url})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            pointerEvents: 'none',
          }}
        >
          {/* <CloseButton sx={{ background: 'rgba(255,255,255,0.7)' }} /> */}
        </Box>
      </Modal>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns:
            'repeat(auto-fill, minmax(min(180px, 100%), 1fr))',
          gap: '16px 16px',
        }}
      >
        {assets?.map(asset => (
          <MediaCard grid asset={asset} onClick={() => handleClick(asset)} />
        ))}
      </Box>
    </>
  )
}

export default MediaGrid
