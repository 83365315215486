import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import client from '~/client'

export default function SignOut({ redirectPath = '/sign-in' }) {
  const navigate = useNavigate()

  React.useEffect(() => {
    client.signOut()

    if (!client.isSignedIn()) {
      enqueueSnackbar('You have been signed out')
      navigate(redirectPath)
    }
  }, [])

  return null
}
