import useSanityQuery from '~/client/useSanityQuery'

export default function useCard(cardId) {
  const query = `
  *[_type == 'card' && _id == '${cardId}'][0]{
    ...,
    subcards[] -> {
      ...,
      assets[]{
        ...
        asset ->,
      },
      messages[] ->,
    }
  }`

  const result = useSanityQuery(
    cardId ? query : null
    // { params: { id } }
  )

  console.log('useCard result', result)

  return result
}
