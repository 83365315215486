import { currentGenGroq } from '~/config/currentGeneration'
import objectToGroq from '~/core/sanity/objectToGroq'

const buildSanityProductQuery = ({
  params,
  text,
  textFields,
  filter = currentGenGroq,
}) => {
  const t =
    text && textFields?.length
      ? `&& (${textFields.map(f => `${f} match '${text}*' `).join(' || ')})`
      : ''

  // const r = columns?.length ? `{_id, ${columns.map(({ field }) => field)}}` : ''
  const r = `{ _id, modelNumber, title }`

  const q = objectToGroq(params)

  const f = filter ? ` && ${filter}` : ''

  return `*[_type == 'products' ${f}${q}${t}]${r}`
}

export default buildSanityProductQuery
