import { PersonRemove } from '@mui/icons-material'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { FormContainer, useForm } from 'react-hook-form-mui'
import { useLocation, useNavigate } from 'react-router-dom'
import client from '~/client'
import usePageQuery from '~/client/usePageQuery'
import Chip from '~/components/Data/Chip'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import AutocompleteElementOptions from '~/core/forms/AutocompleteElementOptions'
import useMutateUsers from '~/components/Users/useMutateUsers'
import useUsers from '~/components/Users/useUsers'

const useProposalCounts = userId =>
  usePageQuery(userId ? `proposal/by-user?userId=${userId}` : null)

const ProposalCounts = ({ proposalCounts }) => (
  <Box
    sx={{
      display: 'grid',
      gap: 1,
      gridTemplateColumns: 'min-content min-content',
      alignItems: 'center',
    }}
  >
    {Object.entries(proposalCounts)
      .filter(([, count]) => count)
      .map(([status, count]) => (
        <>
          <div>
            <Chip key={status} label={`${status}`} sx={{ mr: 2 }} />{' '}
          </div>
          <b>{count}</b>
        </>
      ))}
  </Box>
)

export default function DeleteUser() {
  const navigate = useNavigate()

  const location = useLocation()
  const user = location.state?.data
  console.log('user to delete', user)

  const { data: proposalCounts = {}, isLoading: isLoadingProposals } =
    useProposalCounts(user?.userId)

  const { data: users, isLoading: isLoadingUsers } = useUsers({
    companyId: user?.companyId,
    pageSize: -1,
  })

  const isLoading = isLoadingProposals || isLoadingUsers

  const usersOptions = users
    ?.filter(u => u.userId !== user?.userId)
    .filter(u => u.status === 'CONFIRMED')

  const mutateUsers = useMutateUsers()

  const [isSubmitting, setSubmitting] = React.useState()

  const handleClick = async data => {
    try {
      setSubmitting(true)
      await client.patch('protected/disable-user', {
        ...data,
        userId: user.userId,
      })

      enqueueSnackbar(`User ${user?.email} deleted`)
      mutateUsers()
      // onMutate?.()
      navigate(-1)
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  const formContext = useForm({
    defaultValues: {},
    shouldUnregister: true,
  })

  const totalCount = !isLoadingProposals
    ? Object.values(proposalCounts).reduce((total, v) => total + v, 0)
    : undefined

  const canSafelyDelete = isLoading
    ? undefined
    : totalCount === 0
    ? true
    : usersOptions?.length === 0
    ? 1
    : false
  const s = totalCount > 1 ? 's' : ''

  const userText = (
    <>
      <b>
        {user?.firstName || ''} {user?.lastName || ''}
      </b>{' '}
      ({user?.email})
    </>
  )

  return (
    <FormContainer onSuccess={handleClick} formContext={formContext}>
      <Stack spacing={3}>
        <Typography variant="h3">Delete user</Typography>
        {canSafelyDelete === undefined && <Skeleton />}
        {canSafelyDelete === true && (
          <Typography>
            {userText} owns no proposals and can be safely deleted.
          </Typography>
        )}

        {canSafelyDelete === 1 && (
          <>
            <Typography>
              {userText} is the last user in the company and the owner of{' '}
              {totalCount} proposal{s}. These proposals will be vanquished
              forevermore.
            </Typography>
            <ProposalCounts proposalCounts={proposalCounts} />
          </>
        )}

        {canSafelyDelete === false && (
          <>
            <Typography>
              {userText} is the owner of {totalCount} proposal{s}.
            </Typography>
            <ProposalCounts proposalCounts={proposalCounts} />
            <Typography>
              To which user would you like to reassign these proposals{s}?
            </Typography>
            <AutocompleteElementOptions
              fullWidth
              name="assigneeUserId"
              label="Reassign propsals to"
              required
              idKey="userId"
              labelKey="email"
              options={usersOptions}
            />
          </>
        )}

        <SubmitButton
          sx={{ flexShrink: 0, alignSelf: 'start' }}
          isSubmitting={isSubmitting}
          endIcon={<PersonRemove />}
          color="error"
        >
          {canSafelyDelete !== false ? 'Delete user' : 'Delete and reassign'}
        </SubmitButton>
      </Stack>
      <LoadingIndicators {...{ isLoading, isSubmitting }} />
    </FormContainer>
  )
}
