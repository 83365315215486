export const orange = '#F9951C'
export const green = '#8CC828'
export const yellow = '#F6DC23'
export const gold = '#E19E35'
export const magenta = '#92278F'
export const teal = '#288C8C'
export const azure = '#1B7FF9'
export const blue = '#3258C3'
export const red = '#EB3915'
export const blood = '#9E1709'
export const navy = '#18203B'
// export const paper = '#F9FBFB'
export const paper = '#ffffff'
export const paperAlternate = '#F6F9FC'
export const gray = '#4C4C4C'
export const charcoal = '#262626'

export const brand = '#051133'
export const primary = '#051133'
export const success = '#4CAF50'
export const warning = '#92278F'
export const danger = '#EB3915'
