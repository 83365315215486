import React from 'react'
import useSanityQuery from '~/client/useSanityQuery'

const formatPageQuery = ({
  search,
  types = ['sanity.imageAsset', 'sanity.fileAsset'],
  extensions,
  mediaTags,
  pageSize,
  page,
}) => {
  const _type = `(_type == '${types.join(`' || _type == '`)}')`

  const pagination =
    typeof pageSize === 'number' && typeof page === 'number'
      ? `[${pageSize * page}...${pageSize * (page + 1)}]`
      : ''

  const s =
    search?.length > 2
      ? `&& [title, description, originalFilename] match '${search}' + '*'`
      : ''

  const x = extensions?.length
    ? `&& extension in ['${extensions.join(`','`)}']`
    : ''

  const t = mediaTags?.length
    ? `&& count(opt.media.tags[_ref in ['${mediaTags.join(`','`)}']]) > 0`
    : ''

  return `*[${_type}${s}${x}${t}]${pagination}`
}

export default function useAssets({
  search,
  extensions,
  mediaTags, // TODO
  types,
  pageSize = 12,
  initPage = 0,
} = {}) {
  const [page, setPage] = React.useState(initPage)

  const result = useSanityQuery(
    formatPageQuery({ search, types, extensions, mediaTags, pageSize, page })
  )
  const nextResult = useSanityQuery(
    formatPageQuery({
      search,
      types,
      extensions,
      mediaTags,
      pageSize,
      page: page + 1,
    })
  )

  const isNextPage = !!nextResult.data?.length
  const isPrevPage = page !== 0

  const onNextClick = () => isNextPage && setPage(p => p + 1)
  const onPrevClick = () => setPage(p => Math.max(0, p))

  return {
    ...result,
    pagination: {
      page,
      setPage,
      onNextClick,
      onPrevClick,
      isNextPage,
      isPrevPage,
    },
  }
}
