import useSWR from 'swr'
import sanityClient from '~/client/sanity'
import { sanityAuthClient } from '~/client/sanity'

const defaultOptions = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
  revalidateOnReconnect: false,
}

const useSanityQuery = (query, options = {}) => {
  const client = options.withCredentials ? sanityAuthClient : sanityClient

  const results = useSWR(query, query => client.fetch(query, options.params), {
    // defaultOptions,
    ...options,
  })
  return { client, ...results }
}

export default useSanityQuery
