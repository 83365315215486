import { useMatches } from 'react-router-dom'

const regex = new RegExp(/^\/|\/$/g)

const useSubmatch = path => {
  const matches = useMatches()

  const subroute = matches
    .find(match => typeof match?.params?.['*'] === 'string')
    ?.params['*'].replace(regex, '')

  return subroute?.startsWith(path.replace(regex, '')) ? subroute : false
}

export default useSubmatch

export const useLevelMatchKey = routingLevel => {
  const matches = useMatches()

  const match =
    typeof routingLevel === 'number'
      ? matches[routingLevel]
      : matches.slice(-1)[0]

  return match?.pathname
}
