import { useSWRConfig } from 'swr'
import client from '~/client'

export default function useMutateUsers() {
  const { mutate } = useSWRConfig()
  const userInfo = client.getUserInfo()

  return () =>
    mutate(
      key =>
        typeof key === 'string' &&
        [`companyusers/?companyId=${userInfo.companyId}`, 'protected'].some(
          string => key.startsWith(string)
        ),
      undefined,
      { revalidate: true }
    )
}
