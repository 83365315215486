import { enqueueSnackbar } from 'notistack'
import React from 'react'
import useSWR from 'swr'
import client from '~/client'

export default function useUserProfile() {
  const { data, mutate, ...rest } = useSWR(
    'protected/?current=1',
    client.fetcher
  )

  console.log('EditProfile data', data)

  const [isSubmitting, setSubmitting] = React.useState(false)

  const updateUserProfile = async data => {
    try {
      setSubmitting(true)
      await client.patch('protected', data)
      enqueueSnackbar('Your details have been saved')
      mutate()
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  return {
    data,
    isSubmitting,
    updateUserProfile,
    ...rest,
  }
}
