import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, Typography } from '@mui/material'
import { PortableText } from '@portabletext/react'
import StyledCard from '~/routes/Admin/SalesResources/StyledCard'
import MediaGrid from '~/routes/Admin/SalesResources/Subcard/MediaGrid'

const Subcard = ({
  subcard: { title, content, assets, messages, Icon },
  ...rest
}) => {
  return (
    <StyledCard {...rest}>
      <Typography
        gutterBottom
        sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
      >
        <Icon sx={{ mr: 1 }} />
        {title}
      </Typography>
      <PortableText value={content} />
      <Box
        as="ol"
        spacing={2}
        sx={{
          pl: 0,
          listStylePosition: 'inside',
          display: 'grid',

          gridTemplateColumns: '1fr 1fr',
          gap: '8px 8px',
          // gridAutoFlow: 'row',
        }}
      >
        {messages?.map(({ _id, title }, i) => (
          <Box
            key={_id}
            as="li"
            sx={{
              background: '#fff',
              p: 1,
              borderRadius: 0.5,
              boxShadow: '0px 0px 14px 0px rgba(53, 64, 82, 0.05)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {i + 1}. {title}
            <VisibilityIcon sx={{ color: 'grey.400' }} />
          </Box>
        ))}
      </Box>
      <MediaGrid assets={assets} />
    </StyledCard>
  )
}

export default Subcard
