import { useMatches, useParams } from 'react-router-dom'
import PageHeader from '~/components/Layouts/PageHeader'

import AppStyles from '~/components/Layouts/AppStyles'
import AssetList from '~/routes/Admin/SalesResources/Assets/AssetList'
import useCard from '~/routes/Admin/SalesResources/hooks/useCard'

export default function SalesResourcesAssets() {
  const matches = useMatches('/')

  const params = useParams()

  const { data: card } = useCard(params.cardId)

  console.log({ matches, params, card })
  return (
    <>
      <PageHeader />
      <AppStyles />
      <AssetList />
    </>
  )
}
