import { TextFieldElement } from 'react-hook-form-mui'

import { InputAdornment, Typography } from '@mui/material'
import FieldArrayStack from '~/core/forms/FieldArrayStack'
import { alphaChars } from '~/core/forms/validation'
// import { alphaChars } from '~/core/utils/regex'

export default function ProposalSetupFields() {
  return (
    <>
      <TextFieldElement
        name="markupRate"
        label="Markup rate"
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        sx={{ width: '30%', minWidth: 200 }}
      />
      {/* </> */}
      {/* )} */}

      <TextFieldElement
        name="expiryTime"
        label="Expiry time"
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            max: 365,
          },
          endAdornment: <InputAdornment position="start">days</InputAdornment>,
        }}
        sx={{ width: '30%', minWidth: 200 }}
      />

      <TextFieldElement
        name="exclusionsTemplate"
        label="Exclusions template"
        multiline
        minRows={3}
        sx={{ width: '100%', minWidth: 200 }}
        helperText="Please list your standard exclusion list that apply to all proposals"
      />
      <Typography variant="h4">Contractor labor rates</Typography>

      <FieldArrayStack allowEdit name="labourRates" spacing={2}>
        <TextFieldElement
          name="name"
          required
          label="Rate name"
          // fullWidth
          // xs={7}
          // sx={{ pr: 2 }}
          rules={{ pattern: alphaChars }}
          sx={{ width: '30%', minWidth: 100 }}
        />
        <TextFieldElement
          name="price"
          type="number"
          // min
          // fullWidth
          required
          label="Day rate"
          // xs={4}
          sx={{ width: '30%', minWidth: 60 }}
          InputProps={{
            min: 0,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </FieldArrayStack>
    </>
  )
}
