import { Box, Button, Container, Modal, styled, keyframes } from '@mui/material'
import { motion } from 'framer-motion'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import FanIcon from '~/components/Data/FanIcon'
import FanLoader from '~/components/Data/FanLoaader'

const LayerFixed = styled(motion.div)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // zIndex: theme.zIndex.modal,
  zIndex: 1300,
}))

const gradientKeyframes = keyframes`
0%{background-position:0% 100%}
50%{background-position:100% 50%}
100%{background-position:0% 100%}`

const motionTextProps = {
  as: motion.div,
  transition: { duration: 0.3, ease: 'easeIn' },
  variants: {
    initial: { opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: '100vw' },
  },
}

const backgroundVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.3 } },
  exit: { opacity: 0, transition: { duration: 1 } },
}

const whiteVariants = {
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { duration: 0.3, delay: 0.3 } },
}

export function LoadingSplash() {
  return (
    <motion.div initial="initial" animate="animate" exit="exit">
      <LayerFixed sx={{ background: 'white' }} variants={whiteVariants} />

      <LayerFixed variants={backgroundVariants} sx={{ color: '#555' }}>
        <FanIcon sx={{ fontSize: 64 }} />
      </LayerFixed>
    </motion.div>
  )
}

export default function Splash({ firstName = 'Homeowner', onClick }) {
  return (
    <motion.div initial="initial" animate="animate" exit="exit">
      <LayerFixed
        sx={{
          background:
            'linear-gradient(113deg, var(--primary-color-light, #C9321F) 0%, var(--primary-color-dark, #1C5799) 60%)',
          backgroundSize: '150% 150%',
          animation: `${gradientKeyframes} 7s ease infinite`,
        }}
        variants={backgroundVariants}
      />

      <LayerFixed
        onClick={onClick}
        sx={{
          alignItems: 'flex-start',
          color: 'var(--secondary-color, white)',
          pl: 'min(10vw, 300px)',
          pr: '5vw',
        }}
      >
        <Box {...motionTextProps} sx={{ fontSize: { xs: 48, sm: 64 }, mb: 4 }}>
          Hi {firstName}
        </Box>
        <Box {...motionTextProps} sx={{ fontSize: { xs: 34, sm: 58 }, mb: 4 }}>
          Welcome to your proposal
        </Box>
        <Box {...motionTextProps}>
          <Button
            variant="contained"
            color="contrast"
            endIcon={<ArrowForwardIcon />}
            size="large"
          >
            Let's get started
          </Button>
        </Box>
      </LayerFixed>
    </motion.div>
  )
}
