import { SvgIcon } from '@mui/material'

const spinAnimation = {
  animation: 'spin 1.2s linear infinite',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
}

const FanIcon = ({ sx, spinning = true, ...rest }) => (
  <SvgIcon
    // xmlns="http://www.w3.org/2000/svg"
    // viewBox="0 0 122.88 122.07"
    {...rest}
    sx={{ ...sx, ...(spinning && spinAnimation) }}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M 13.1049 15.9085 c -0.0211 0.2495 -0.0499 0.4989 -0.0902 0.7484 c -0.2744 1.7271 -1.1111 2.7518 -1.5506 4.2544 C 10.9383 22.7306 12.7268 23.5135 14.2524 23.4118 A 7.2212 7.2212 90 0 0 16.5034 22.8361 a 8.6355 8.6355 90 0 0 1.7885 -1.0286 c 3.8591 -2.8401 3.0704 -6.6973 -1.1725 -8.8965 a 2.8785 2.8785 90 0 0 -0.7945 -0.2687 a 4.2218 4.2218 90 0 1 -1.1514 2.1243 l 0 0 A 4.2391 4.2391 90 0 1 13.1049 15.9085 Z M 12.1665 8.4858 a 3.2815 3.2815 90 1 1 -3.2815 3.2815 a 3.2815 3.2815 90 0 1 3.2815 -3.2815 Z M 16.323 10.9057 c 0.2418 0.0096 0.4855 0.0269 0.7273 0.0557 c 1.7386 0.1919 2.7979 0.9902 4.3177 1.3625 c 1.8422 0.451 2.5465 -1.3759 2.3815 -2.8958 a 7.1713 7.1713 90 0 0 -0.6812 -2.2203 a 8.7027 8.7027 90 0 0 -1.1053 -1.7425 C 18.954 1.7271 15.1371 2.6866 13.126 7.0293 a 2.7135 2.7135 90 0 0 -0.1919 0.5642 A 4.2218 4.2218 90 0 1 15.1601 8.766 l 0 0 a 4.2352 4.2352 90 0 1 1.1514 2.1358 Z m -5.1621 -3.2623 c 0 -0.307 0.0249 -0.616 0.0595 -0.923 c 0.1919 -1.7405 0.9825 -2.8017 1.3433 -4.3216 C 13.0223 0.5546 11.1916 -0.1439 9.6737 0.0249 A 7.1771 7.1771 90 0 0 7.4534 0.71 a 8.5549 8.5549 90 0 0 -2.1761 1.209 C 1.99 4.8359 2.9533 8.6509 7.2922 10.6524 a 2.8075 2.8075 90 0 0 0.7215 0.2188 A 4.2448 4.2448 90 0 1 11.1609 7.6434 Z M 7.9946 12.5541 a 9.9999 9.9999 90 0 1 -1.0977 -0.0461 c -1.7425 -0.1689 -2.8152 -0.9441 -4.3408 -1.2915 C 0.7062 10.7944 0.0326 12.6347 0.2226 14.1507 A 7.1867 7.1867 90 0 0 0.9403 16.3595 a 8.647 8.647 90 0 0 1.1341 1.7271 c 3.0704 3.6787 6.87 2.6617 8.8101 -1.7098 a 3.057 3.057 90 0 0 0.1689 -0.5105 A 4.2506 4.2506 90 0 1 7.9946 12.5541 Z"
    />
  </SvgIcon>
)
export default FanIcon
