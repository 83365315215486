import { Box, Button, Card, Grid, Typography } from '@mui/material'
import PageHeader from '~/components/Layouts/PageHeader'

import { PostAdd } from '@mui/icons-material'
import AutoTable, {
  ContentPreviewCell,
  DateCell,
  DeleteCell,
  EditCell,
} from '~/components/Data/AutoTable'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import Drawer from '~/components/Layouts/Drawer'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import useSubmatch from '~/core/router/useSubmatch'

import EditTextTemplateForm from '~/routes/Admin/ProposalSetup/TextTemplates/EditTextTemplateForm'
import useTextTemplates from '~/routes/Admin/ProposalSetup/TextTemplates/useTextTemplates'

export default function TextTemplates() {
  const response = useTextTemplates()
  const { isSubmitting, isLoading, saveTemplate, deleteTemplate } = response

  return (
    <>
      <PageHeader />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card {...sharedProps.card}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h3" sx={{ mb: 3 }}>
                Message templates
              </Typography>
              <Link to="new">
                <Button sx={{ mb: 2 }} variant="outlined" endIcon={<PostAdd />}>
                  Add new
                </Button>
              </Link>
            </Box>
            <AutoTable
              {...response}
              columns={[
                // { field: 'name', Component: ContentPreviewCell },
                { field: 'name' },
                {
                  field: 'contents',
                  headerName: 'Preview',
                  Component: ContentPreviewCell,
                },
                { field: 'updatedAt', Component: DateCell },
                { field: 'edit', Component: EditCell },
                {
                  field: 'delete',
                  Component: DeleteCell,
                  onClick: deleteTemplate,
                },
              ]}
            />
          </Card>

          <LoadingIndicators {...{ isSubmitting, isLoading }} />
        </Grid>
      </Grid>

      <Drawer open={useSubmatch('edit')}>
        <EditTextTemplateForm onSuccess={saveTemplate} />
      </Drawer>

      <Drawer open={useSubmatch('new')}>
        <EditTextTemplateForm onSuccess={saveTemplate} />
      </Drawer>
    </>
  )
}
