import {
  Card,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import PageHeader from '~/components/Layouts/PageHeader'
import Chip, { getPlural } from '~/components/Data/Chip'

import { DeleteOutline } from '@mui/icons-material'
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread'
import { enqueueSnackbar } from 'notistack'
import client from '~/client'
import usePageQuery from '~/client/usePageQuery'
import DottedMenu from '~/components/DottedMenu'
import Pagination from '~/components/Data/Pagination'
import { formatDate } from '~/core/utils/formatDate'
import useNotifications from '~/routes/Admin/Notifications/useNotifications'

const unwrap =
  fn =>
  ({ data, meta }) => ({ data: fn(data), meta })

export default function Notifications() {
  const { data, updateRead, deleteNotification, pagination } =
    useNotifications()

  const unread = data?.filter(row => !row.dateRead) || []

  const [message, setMessage] = React.useState({})

  const handleClick = row => {
    setMessage(row)

    if (row.dateRead) return
    const dateRead = new Date().toISOString()
    updateRead({ ...row, dateRead })
  }

  return (
    <>
      <PageHeader />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 2, mb: 3 }}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Summary
            </Typography>
            <Typography variant="h4">Status</Typography>
            <Chip
              label={getPlural('unread notification', unread.length)}
              count={unread.length}
              color={unread.length === 0 ? 'success' : 'warning'}
            />
          </Card>
          <Card sx={{ p: 2, mb: 3 }}>
            <Typography variant="h3">Notifications</Typography>
            <table aria-label="simple table" style={{ width: '100%' }}>
              <tbody style={{ position: 'relative' }}>
                {/* <AnimatePresence> */}
                {data?.map((row, i) => (
                  <TableRow
                    variant="compact"
                    // {...sharedProps.motion.tr}
                    // as={motion.tr}
                    key={row.dateSent}
                    onClick={() => handleClick(row)}
                  >
                    <TableCell scope="row" sx={{ py: 1, pl: 0 }}>
                      {row.subject}
                      <br />
                      {formatDate(row.dateSent)}
                    </TableCell>
                    <TableCell align="right" sx={{ py: 1 }}>
                      <Chip
                        size="small"
                        label={row.dateRead === null ? 'unread' : 'read'}
                        sx={{
                          textTransform: 'capitalize',
                          bgcolor:
                            row.dateRead === null
                              ? 'warning.main'
                              : 'success.main',
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>
                      <DottedMenu>
                        <MenuItem
                          onClick={() => updateRead({ ...row, dateRead: null })}
                        >
                          <ListItemIcon>
                            <MarkAsUnreadIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Mark as unread</ListItemText>
                        </MenuItem>
                        <MenuItem
                          sx={{ color: 'error.main' }}
                          onClick={() => deleteNotification(row)}
                        >
                          <ListItemIcon sx={{ color: 'error.main' }}>
                            <DeleteOutline fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Delete</ListItemText>
                        </MenuItem>
                      </DottedMenu>
                    </TableCell>
                  </TableRow>
                ))}
                {/* </AnimatePresence> */}
              </tbody>
            </table>
            {/* <AutoTable data={data} /> */}
            <Pagination {...pagination} />
          </Card>
        </Grid>
        {message?.body && (
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 2, mb: 3 }}>
              <Typography variant="h3" sx={{ mb: 1 }}>
                {message.subject}
              </Typography>
              <Typography variant="h4" sx={{ mb: 3 }}>
                {formatDate(message.dateSent)}
              </Typography>
              <Typography>{message.body}</Typography>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  )
}
