import { AutocompleteElement } from 'react-hook-form-mui'

export function makeAutocompleteElementProps(
  data,
  { idKey = 'id', labelKey = 'label', autocompleteProps } = {}
) {
  const options = data?.map(item => item[idKey]) || []

  return {
    options,
    autocompleteProps: {
      getOptionLabel: value =>
        data?.find(item => item[idKey] === value)?.[labelKey] || '',
      isOptionEqualToValue: (o, v) => o === v,
      ...autocompleteProps,
    },
  }
}

export default function AutocompleteElementOptions({
  options,
  idKey = 'id',
  labelKey = 'label',
  autocompleteProps,
  // getOptionLabel,
  ...rest
}) {
  return (
    <AutocompleteElement
      {...makeAutocompleteElementProps(options, {
        idKey,
        labelKey,
        autocompleteProps,
      })}
      {...rest}
    />
  )
}
