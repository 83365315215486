import useSWR from 'swr'
import client from '~/client'

export default function useCompanyOptions() {
  const { data } = useSWR(
    `company?pageSize=1000&search=${encodeURIComponent('{"status":"active"}')}`,
    client.fetcher
  )
  console.log('useCompanyOptions', data)
  return (
    data?.map?.(option => ({
      id: option.companyId,
      label: option.companyName,
      companyType: option.companyType,
    })) || []
  )
}
