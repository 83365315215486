export default {
  proposalId: '96b7e039-6368-4efe-929b-8f45df4cb811',
  contents: {
    company: {
      address: {
        city: 'London',
        line1: 'dsdsd',
        state: 'AL',
        zipCode: '1234',
      },
      website: 'test.com',
      companyId: 'ebcafb76-3270-4624-b011-7074e16b1658',
      expiryTime: null,
      markupRate: null,
      companyName: 'Test Rep Company',
      companyType: 'repCompany',
      phoneNumber: null,
      contactEmails: {
        generalEnquiries: 'test@repcompany.com',
        materialsListExport: 'test@repcompany.com',
      },
      exclusionsTemplate: null,
    },
    details: {
      consent: ['yes'],
      customer: {
        name: 'Lewis',
        email: 'lewis@motifgroup.co',
        address: {
          city: 'plop',
          line1: 'plop',
          line2: 'plop',
          state: 'IN',
          zipCode: 'plop',
        },
        phoneNumber: '1234567',
      },
      frameBuild: 'newBuild',
      projectType: 'residential',
      installationType: 'newInstallation',
    },
    project: {
      duration: 12,
      template: {
        contents: 'Plop',
      },
      discounts: [],
      equipment: [],
      startDate: '2024-07-22T23:00:00.000Z',
      paymentBreakdown: [
        {
          date: '2024-07-30T23:00:00.000Z',
          percentage: 100,
          description: 'Payment',
        },
      ],
      customLabourRates: [],
    },
    systems: [
      {
        duct: {
          plenumLength: [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            22,
            null,
            22,
            22,
          ],
          plenumRValue: 6,
          tubing2Total: 264,
          tubingRValue: 4.2,
          outletAdapter: 'round7',
          tubing2_5Total: 276,
          tubing2Aluminum: 0,
          plenumAccessories: {},
          tubing2Attenuated: 264,
          tubing2_5Aluminum: 0,
          plenumConfiguration: 'loop',
          returnAirDuctLength: 22,
          returnAirDuctRValue: 4.2,
          tubing2_5Attenuated: 276,
        },
        size: {
          consent: ['yes'],
          tonnage: '2-2_5t',
          customer: {
            name: 'Lewis',
            email: 'lewis@motifgroup.co',
            address: {
              city: 'plop',
              line1: 'plop',
              line2: 'plop',
              state: 'IN',
              zipCode: 'plop',
            },
            phoneNumber: '1234567',
          },
          duration: 12,
          hvacType: 'heating',
          outlets2: 22,
          template: {
            contents: 'Plop',
          },
          direction: 'left',
          discounts: [],
          equipment: [],
          furnaceKw: 7.5,
          motorType: 'variable',
          startDate: '2024-07-22T23:00:00.000Z',
          frameBuild: 'newBuild',
          outlets2_5: 23,
          heatingType: 'electric',
          orientation: 'verticalUpflowModular',
          projectType: 'residential',
          outletsRound: {
            pine: {},
            birch: {},
            maple: {},
            cherry: {},
            poplar: {},
            redOak: {},
            walnut: {},
            mahogany: {},
            knottyPine: {},
          },
          outletsTotal: 45,
          plenumLength: [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            22,
            null,
            22,
            22,
          ],
          plenumRValue: 6,
          tubing2Total: 264,
          tubingRValue: 4.2,
          outletAdapter: 'round7',
          returnAirType: 'unico',
          tubing2_5Total: 276,
          outletsNotRound: {
            slotted: {},
            roundAngled15: {},
            roundAngled25: {},
            slottedAngled: {},
          },
          tubing2Aluminum: 0,
          installationType: 'newInstallation',
          paymentBreakdown: [
            {
              date: '2024-07-30T23:00:00.000Z',
              percentage: 100,
              description: 'Payment',
            },
          ],
          customLabourRates: [],
          plenumAccessories: {},
          tubing2Attenuated: 264,
          tubing2_5Aluminum: 0,
          plenumConfiguration: 'loop',
          returnAirDuctLength: 22,
          returnAirDuctRValue: 4.2,
          returnAirPartConfig: 'single',
          tubing2_5Attenuated: 276,
        },
        system: {
          hvacType: 'heating',
          furnaceKw: 7.5,
          heatingType: 'electric',
        },
        equipment: {
          outlets2: 22,
          outlets2_5: 23,
          outletsRound: {
            pine: {},
            birch: {},
            maple: {},
            cherry: {},
            poplar: {},
            redOak: {},
            walnut: {},
            mahogany: {},
            knottyPine: {},
          },
          outletsTotal: 45,
          outletsNotRound: {
            slotted: {},
            roundAngled15: {},
            roundAngled25: {},
            slottedAngled: {},
          },
        },
        configuration: {
          direction: 'left',
          motorType: 'variable',
          orientation: 'verticalUpflowModular',
          returnAirType: 'unico',
          returnAirPartConfig: 'single',
        },
      },
    ],
    customer: {
      name: 'Lewis',
      email: 'lewis@motifgroup.co',
      address: {
        city: 'plop',
        line1: 'plop',
        line2: 'plop',
        state: 'IN',
        zipCode: 'plop',
      },
      phoneNumber: '1234567',
    },
    companyId: 'ebcafb76-3270-4624-b011-7074e16b1658',
    lineItems: [
      {
        _id: '6f3a0707-deb6-4a48-bdcc-37ee4e047de4',
        key: 'airHandler',
        _rev: 'trqx8S1HIiR8RLm64rIJ2F',
        spec: {
          tonnage: ['2-2_5t'],
          voltage: [120, 208, 230],
          category: 'airHandler',
          hvacType: ['cooling', 'heating', 'heatingCooling'],
          direction: ['left'],
          motorType: ['variable'],
          coolingType: ['chilledWater', 'refrigerant'],
          heatingType: ['heatPump', 'electric', 'hydro', 'airWaterHeatPump'],
          orientation: [
            'horizontal',
            'verticalUpflowModular',
            'verticalDownflow',
          ],
        },
        _type: 'products',
        price: 2328,
        category: 'airHandler',
        quantity: 1,
        boxWeight: 62,
        _createdAt: '2023-02-05T12:24:28Z',
        _updatedAt: '2024-01-19T16:06:34Z',
        boxQuantity: 1,
        description: 'Module, Blower, SCB Variable Speed ECM, 120/208/230V',
        modelNumber: 'M2430BL1-EC2',
        boxDimensions: {
          depth: 20,
          width: 16,
          height: 29,
        },
      },
      {
        _id: '80fa3e8e-e825-453a-b91a-7d6e897b1552',
        key: 'electricFurnace',
        _rev: 'EzfH5HhL4bWBoLI26DJZnV',
        spec: {
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'electricFurnace',
          hvacType: ['heating', 'heatingCooling'],
          furnaceKw: [7.5],
          heatingType: ['electric', 'airWaterHeatPump'],
        },
        _type: 'products',
        price: 1074,
        category: 'electricFurnace',
        quantity: 1,
        boxWeight: 29,
        _createdAt: '2023-02-05T12:48:15Z',
        _updatedAt: '2024-01-11T15:58:18Z',
        boxQuantity: 1,
        description:
          'Furnace, Electric, 7.5 kW, 1P/230V (matches all models)**',
        modelNumber: 'WON0752-C',
        boxDimensions: {
          depth: 29,
          width: 20,
          height: 16,
        },
      },
      {
        _id: '80cbde27-01e8-4b0d-af15-7133e504b3e3',
        key: 'returnAirPartAdaptor',
        _rev: 'HsJDzpWZsV9WhQWGwnPuIS',
        spec: {
          tonnage: ['2-2_5t'],
          category: 'returnAirPart',
          coolingType: ['chilledWater', 'refrigerant'],
          heatingType: ['heatPump', 'electric'],
          orientation: ['horizontal', 'verticalDownflow'],
          returnAirPartType: ['adaptor'],
          returnAirPartConfig: ['single'],
        },
        _type: 'products',
        price: 206,
        category: 'returnAirPartAdaptor',
        quantity: 1,
        boxWeight: 6,
        _createdAt: '2023-02-05T13:02:37Z',
        _updatedAt: '2023-12-08T17:21:02Z',
        boxQuantity: 1,
        description:
          'Return Air Adapter (fits to M2430CL1-A,B, C or E coil modules),14" duct',
        modelNumber: 'UPC-59-2430',
        boxDimensions: {
          depth: 13,
          width: 15,
          height: 23,
        },
      },
      {
        _id: 'f989eb0a-f359-4553-a999-92ca84611a05',
        key: 'returnAirPartBox',
        _rev: 'xWjKiVRUS31aFCePUXirNZ',
        spec: {
          rValue: [4.2, 6, 8],
          tonnage: ['2-2_5t'],
          category: 'returnAirPart',
          coolingType: ['chilledWater', 'refrigerant'],
          heatingType: ['heatPump', 'electric', 'hydro'],
          orientation: [
            'horizontal',
            'verticalUpflowModular',
            'verticalDownflow',
          ],
          returnAirPartType: ['box'],
          returnAirPartConfig: ['single'],
        },
        _type: 'products',
        price: 291,
        category: 'returnAirPartBox',
        quantity: 1,
        boxWeight: 8,
        _createdAt: '2023-02-05T13:01:23Z',
        _updatedAt: '2023-12-08T17:16:29Z',
        boxQuantity: 1,
        description: 'Return Air Box with Grille and Filter, 14" x 25"',
        modelNumber: 'UPC-01-2430',
        boxDimensions: {
          depth: 17,
          width: 8,
          height: 23,
        },
      },
      {
        _id: '39c657ae-3d44-4aa8-b390-03d809427557',
        key: 'supplyPlenumAccessory',
        _rev: 'WbIG6HtCYx5t4g92LFuu7S',
        spec: {
          package: ['single'],
          tonnage: ['2-2_5t'],
          category: 'outlet',
          outletAdapter: ['round7'],
          outletCategory: ['supplyPlenumAccessory'],
        },
        _type: 'products',
        price: 82,
        category: 'supplyPlenumAccessory',
        quantity: 1,
        boxWeight: 3,
        _createdAt: '2023-02-06T12:17:09Z',
        _updatedAt: '2023-12-11T10:59:00Z',
        boxQuantity: 1,
        description: 'Adapter, Supply, Round, 7"',
        modelNumber: 'UPC-61-2430',
        boxDimensions: {
          depth: 9,
          width: 11,
          height: 16.75,
        },
      },
      {
        _id: 'c8654b05-c9b8-4b6b-a3d1-901bdd85436c',
        key: 'tubing-soundAttenuatorTubing-2-4.2',
        _rev: 'IzUhYaI3dnnQMhTNGpdRf9',
        spec: {
          rValue: [4.2],
          package: ['box'],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'ducting',
          ductSize: [2],
          ductType: ['soundAttenuatorTubing'],
          frameSize: [2],
          lengthPerBox: [72],
        },
        _type: 'products',
        price: 582,
        category: 'supplyTubing',
        quantity: 3,
        boxWeight: 32,
        _createdAt: '2023-02-05T19:29:19Z',
        _updatedAt: '2023-12-14T13:38:10Z',
        boxQuantity: 6,
        description:
          'Sound Attenuator Tubing, TFS, Couplings Attached 2" ID x 12\'L, R-4.2 (72 ft. per box)',
        modelNumber: 'UPC-26TCR4-6',
        boxDimensions: {
          depth: 23,
          width: 24,
          height: 24,
        },
      },
      {
        _id: '69c4df88-4d75-4e6d-b359-a75f050023e0',
        key: 'tubing-soundAttenuatorTubing-2-4.2',
        _rev: 'QSuZW0QffIX9aJgqqbdMRt',
        spec: {
          rValue: [4.2],
          package: ['single'],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'ducting',
          ductSize: [2],
          ductType: ['soundAttenuatorTubing'],
          frameSize: [2],
          lengthPerBox: [12],
        },
        _type: 'products',
        price: 131,
        category: 'supplyTubing',
        quantity: 4,
        boxWeight: 6,
        _createdAt: '2023-02-05T19:28:55Z',
        _updatedAt: '2023-12-14T13:37:08Z',
        boxQuantity: 1,
        description:
          'Sound Attenuator Tubing, TFS, Couplings Attached 2" ID x 12\'L, R-4.2, single length (12 ft. per box)',
        modelNumber: 'UPC-26TCR4-1',
        boxDimensions: {
          depth: 4,
          width: 31,
          height: 32,
        },
      },
      {
        _id: 'b325da08-6354-40b9-9356-bdc07669e0ee',
        key: 'tubing-soundAttenuatorTubing-2.5-4.2',
        _rev: 'QSuZW0QffIX9aJgqqgnPEu',
        spec: {
          rValue: [4.2],
          package: ['box'],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'ducting',
          ductSize: [2.5],
          ductType: ['soundAttenuatorTubing'],
          frameSize: [2.5],
          lengthPerBox: [60],
        },
        _type: 'products',
        price: 562,
        category: 'supplyTubing',
        quantity: 5,
        boxWeight: 32,
        _createdAt: '2023-02-05T19:37:48Z',
        _updatedAt: '2023-12-19T17:19:42Z',
        boxQuantity: 5,
        description:
          'Sound Attenuator Tubing, 2.5" ID x 12\'L, R-4.2 (60 ft total)',
        modelNumber: 'UPC-226-R4-5',
        boxDimensions: {
          depth: 23,
          width: 24,
          height: 24,
        },
      },
      {
        _id: 'd0aa7b99-0a90-477a-9c06-be4640c4b448',
        _rev: 'IzUhYaI3dnnQMhTNGsVTm3',
        spec: {
          gauge: [28],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'plenum',
          diameter: [9],
          material: ['metal'],
          plenumType: ['duct'],
          lengthPerBox: [5],
        },
        _type: 'products',
        price: 70,
        category: 'plenumDuct',
        quantity: 5,
        boxWeight: 10,
        _createdAt: '2023-11-27T15:47:05Z',
        _updatedAt: '2023-12-21T10:06:42Z',
        boxQuantity: 1,
        description: "Duct,Galv,9Dx5'x28GA (1/piece)",
        modelNumber: 'USM-02',
        lengthPerBox: 5,
        boxDimensions: {
          depth: 63,
          width: 11,
          height: 11,
        },
      },
      {
        _id: '4767f471-6a47-45a7-9111-7ede15ca01f2',
        _rev: 'qYdn4QjknlssWRGxOysJHO',
        spec: {
          rValue: [6],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'plenum',
          diameter: [9],
          plenumType: ['sleeve'],
          lengthPerBox: [5],
        },
        _type: 'products',
        price: 46,
        category: 'plenumDuct',
        quantity: 5,
        boxWeight: 5,
        _createdAt: '2023-11-27T16:00:15Z',
        _updatedAt: '2023-12-21T12:38:09Z',
        boxQuantity: 1,
        description: 'Insul,Sleeve,Silver,9Dx5\'x2" R6 (1/piece)',
        modelNumber: 'USM-11',
        lengthPerBox: 5,
        boxDimensions: {
          depth: 14,
          width: 15,
          height: 23,
        },
      },
      {
        _id: 'b2600f34-6940-4994-840b-313d81b3a39e',
        _rev: 'qYdn4QjknlssWRGxOysk7u',
        spec: {
          gauge: [28],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'plenum',
          diameter: [10],
          material: ['metal'],
          plenumType: ['duct'],
          lengthPerBox: [5],
        },
        _type: 'products',
        price: 42,
        category: 'plenumDuct',
        quantity: 5,
        boxWeight: 10,
        _createdAt: '2023-11-27T15:47:48Z',
        _updatedAt: '2023-12-21T12:41:15Z',
        boxQuantity: 1,
        description: "Duct,Galv,10Dx5'x28GA (1/piece)",
        modelNumber: 'USM-13',
        lengthPerBox: 5,
        boxDimensions: {
          depth: 63,
          width: 12,
          height: 12,
        },
      },
      {
        _id: 'ff84be69-6c64-4967-a5de-806ae8501f76',
        _rev: 'qYdn4QjknlssWRGxOyswSQ',
        spec: {
          rValue: [6],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'plenum',
          diameter: [10],
          plenumType: ['sleeve'],
          lengthPerBox: [5],
        },
        _type: 'products',
        price: 45,
        category: 'plenumDuct',
        quantity: 5,
        boxWeight: 5,
        _createdAt: '2023-11-27T17:19:27Z',
        _updatedAt: '2023-12-21T12:44:16Z',
        boxQuantity: 1,
        description: 'Insul,Sleeve,Silver, 10Dx5\'x2" R6 (1/piece)',
        modelNumber: 'USM-16',
        lengthPerBox: 5,
        boxDimensions: {
          depth: 14,
          width: 15,
          height: 23,
        },
      },
      {
        _id: '0500eb40-9982-4709-a4b0-0532e027383c',
        _rev: 'qYdn4QjknlssWRGxOx4gMc',
        spec: {
          gauge: [28],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'plenum',
          diameter: [7],
          material: ['metal'],
          plenumType: ['duct'],
          lengthPerBox: [5],
        },
        _type: 'products',
        price: 30,
        category: 'plenumDuct',
        quantity: 5,
        boxWeight: 10,
        _createdAt: '2023-11-27T11:50:02Z',
        _updatedAt: '2023-12-20T13:23:14Z',
        boxQuantity: 1,
        description: "Duct,Galv,7Dx5'x28GA (1 piece)",
        modelNumber: 'USM-01',
        lengthPerBox: 5,
        boxDimensions: {
          depth: 63,
          width: 11,
          height: 11,
        },
      },
      {
        _id: '184655ee-b2b4-4adc-9d2c-4ec038df77dd',
        _rev: 'qYdn4QjknlssWRGxOygFCk',
        spec: {
          rValue: [6],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'plenum',
          diameter: [7],
          plenumType: ['sleeve'],
          lengthPerBox: [5],
        },
        _type: 'products',
        price: 50,
        category: 'plenumDuct',
        quantity: 5,
        boxWeight: 5,
        _createdAt: '2023-11-27T15:55:21Z',
        _updatedAt: '2023-12-21T11:05:42Z',
        boxQuantity: 1,
        description: 'Insul,Sleeve,Silver,7Dx5\'x2" R6 (1/piece)',
        modelNumber: 'USM-10',
        lengthPerBox: 5,
        boxDimensions: {
          depth: 14,
          width: 15,
          height: 23,
        },
      },
      {
        _id: 'bebc35fc-61e0-4197-aaee-4512e60a7f5b',
        key: 'basicOutlet-UPC-56TB-6',
        _rev: 'IzUhYaI3dnnQMhTNGpKEd1',
        spec: {
          package: ['box'],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'outlet',
          outletType: ['round'],
          outletFinish: ['white'],
          outletCategory: ['roundFacePlate'],
        },
        _type: 'products',
        price: 65,
        category: 'basicOutlet',
        quantity: 7,
        boxWeight: 3,
        _createdAt: '2023-02-05T19:18:05Z',
        _updatedAt: '2023-12-13T15:59:09Z',
        boxQuantity: 6,
        description:
          'Supply Outlet, TFS, w/Toggles and Screws, Face Plate Only (6 pcs)',
        modelNumber: 'UPC-56TB-6',
        boxDimensions: {
          depth: 5,
          width: 8,
          height: 8,
        },
      },
      {
        _id: '53577a57-0ad4-453a-898d-e13175086573',
        key: 'basicOutlet-UPC-56TB-1',
        _rev: 'qYdn4QjknlssWRGxOocO14',
        spec: {
          package: ['single'],
          tonnage: ['1-1_5t', '2-2_5t', '2_5-3t', '3-3_5t', '4-4_5t'],
          category: 'outlet',
          outletType: ['round'],
          outletFinish: ['white'],
          outletCategory: ['roundFacePlate'],
        },
        _type: 'products',
        price: 33,
        category: 'basicOutlet',
        quantity: 3,
        boxWeight: 1,
        _createdAt: '2023-02-05T19:17:37Z',
        _updatedAt: '2023-12-13T15:42:21Z',
        boxQuantity: 1,
        description:
          'Supply Outlet, TFS, w/Toggles and Screws, Face Plate Only',
        modelNumber: 'UPC-56TB-1',
        boxDimensions: {
          depth: 5,
          width: 5,
          height: 5,
        },
      },
      {
        _id: '39fd1a5c-aa6e-45ce-97dc-71c407fab9e9',
        _rev: 'IzUhYaI3dnnQMhTNGph7XP',
        spec: {
          rValue: [4.2],
          tonnage: ['2-2_5t'],
          category: 'returnAirPart',
          diameter: [14],
          coolingType: ['chilledWater', 'refrigerant'],
          heatingType: ['heatPump', 'electric', 'hydro'],
          orientation: [
            'horizontal',
            'verticalUpflowModular',
            'verticalDownflow',
          ],
          lengthPerBox: [10],
          returnAirPartType: ['duct'],
          returnAirPartConfig: ['single'],
        },
        _type: 'products',
        price: 346,
        category: 'returnAirPartDuct',
        quantity: 3,
        boxWeight: 9,
        _createdAt: '2023-02-05T13:01:52Z',
        _updatedAt: '2023-12-14T16:29:14Z',
        boxQuantity: 1,
        description: 'Return Air Duct , R-4.2, 14" D x 10 ft.',
        modelNumber: 'UPC-04-2430',
        boxDimensions: {
          depth: 26,
          width: 18,
          height: 18,
        },
      },
    ],
    contractor: {
      email: 'lewis@motifgroup.co',
      userId: '3e5efdb1-9a4e-4fe0-81d7-46ee02357829',
      lastName: 'Hunter',
      firstName: 'Lewis',
      phoneNumber: '098098',
    },
    proposalId: '96b7e039-6368-4efe-929b-8f45df4cb811',
    totalPrice: 12068,
    distributor: {},
    customerEmail: 'lewis@motifgroup.co',
  },
  createdAt: '2024-07-12T13:08:38.865Z',
  updatedAt: '2024-07-12T13:10:04.063Z',
  companyId: 'ebcafb76-3270-4624-b011-7074e16b1658',
  customerEmail: 'lewis@motifgroup.co',
  status: 'sent',
  userId: '3e5efdb1-9a4e-4fe0-81d7-46ee02357829',
  sentDate: '2024-07-12T13:10:04.062Z',
  secretKey: 'a88b02f6-463a-4583-bf59-0a077d2c500c',
  statusReasons: null,
}
