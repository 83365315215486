import useSanityQuery from '~/client/useSanityQuery'

export default function useMediaTags() {
  const query = `
  *[_type == 'media.tag']{
    _id,
    name,
    "title": name.current,
    "id": _id,
  }`

  const result = useSanityQuery(query)

  console.log('useMediaTags data', result)

  return result
}
