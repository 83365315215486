import { Box, Button, Card, Container, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import { useNavigate } from 'react-router-dom'
import client from '~/client'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmittingIcon from '~/components/Data/SubmittingIcon'
import sharedProps from '~/components/sharedProps'

const variants = {
  initial: { y: '-100%' },
  animate: { y: 0 },
  exit: { y: '-100%' },
}

const defaultValues = {
  username: '',
  confirmationCode: '',
}

export default function ConfirmSignup() {
  const navigate = useNavigate()
  // const location = useLocation()
  const [updatePassword, setUpdatePassword] = React.useState()
  const [submitting, setSubmitting] = React.useState(false)

  // const [searchParams] = useSearchParams()
  // console.log(searchParams.get())

  const formContext = useForm({
    defaultValues,
  })

  React.useEffect(() => {
    if (client.isSignedIn()) navigate('/admin')
  }, [])

  const submitButtonRef = React.useRef()

  React.useEffect(() => {
    if (!window.location.search) return

    try {
      const [username, confirmationCode] = window.location.search
        .replace('?username=', '')
        .split('&confirmationCode=')

      if (username && confirmationCode) {
        formContext.reset({ username, confirmationCode })
        submitButtonRef.current.click()
        // window.location.search = ''
      }
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    }
  }, [])

  const handleClick = async data => {
    try {
      setSubmitting(true)
      const res = await client.fetch('auth/confirm_signup', data)
      enqueueSnackbar('Your email has been confirmed, please sign in')
      navigate('/sign-in')
    } catch (e) {
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      as={motion.div}
      variants={variants}
    >
      <Box sx={{ textAlign: 'center' }}>
        <img src="/logo-unico-dark.svg" alt="The Unico System" />
        <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
          Confirm signup
        </Typography>
      </Box>
      <Card sx={{ p: 3, py: 4, position: 'relative' }}>
        <FormContainer formContext={formContext} onSuccess={handleClick}>
          <TextFieldElement
            fullWidth
            name="username"
            // id="username"
            label="Email"
            required
            sx={{ mb: 3 }}
            autoComplete="username"
            type="email"
          />
          <TextFieldElement
            fullWidth
            // id="code"
            name="confirmationCode"
            label="Confirmation code"
            required
            sx={{ mb: 3 }}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              sx={{ flexShrink: 0, alignSelf: 'start' }}
              {...sharedProps.button.save}
              endIcon={<SubmittingIcon active={submitting} />}
              ref={submitButtonRef}
            >
              Confirm signup
            </Button>
            {/* <Link href="/forgot-password">Forgot password?</Link> */}
          </Box>
        </FormContainer>
        <LoadingIndicators isSubmitting={submitting} />
      </Card>
    </Container>
  )
}
