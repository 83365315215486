import { enqueueSnackbar } from 'notistack'
import client from '~/client'
import usePageQuery from '~/client/usePageQuery'

export default function useProposals({ search } = {}) {
  const userInfo = client.getUserInfo()

  const { data, mutate, ...rest } = usePageQuery(
    `proposal/?username=${encodeURIComponent(userInfo.email)}`,
    { search }
  )

  console.log('Proposals', data, userInfo)

  const handleDelete = async ({ customerEmail, proposalId }) => {
    if (
      !confirm(`Are you sure you want to delete proposal for ${customerEmail}?`)
    )
      return

    try {
      const optimisticData = data.filter(
        proposal => proposal.proposalId !== proposalId
      )

      mutate(
        // this expects mutated data to be returned
        async () => {
          await client.delete(`proposal/?proposalId=${proposalId}`)
          enqueueSnackbar(`Proposal for ${customerEmail} deleted`, {
            variant: 'info',
          })
          return optimisticData
        },
        // this also expects the mutated data, but not async
        { optimisticData }
      )
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    }
  }

  const updateStatus = async ({ proposalId, status, statusReasons }) => {
    try {
      const res = await client.put('proposal', {
        proposalId,
        status,
        statusReasons,
      })
      console.log('updateStatus', res)
      mutate()
      return res
    } catch (e) {
      console.error(e)
    }
  }

  return { data, mutate, ...rest, handleDelete, updateStatus }
}
