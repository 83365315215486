import { FormContainer, useForm } from 'react-hook-form-mui'

import { Stack, Typography } from '@mui/material'
import React from 'react'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import AddressFields from '~/routes/Admin/Company/Profile/AddressFields'
import CompanyDetailsFields from '~/routes/Admin/Company/Profile/CompanyDetailsFields'
import ContactEmails from '~/routes/Admin/Company/Profile/ContactEmails'
import useCompanyDetails from '~/routes/Admin/Company/Profile/useCompanyDetails'
import CompanyBrandingFields from '~/routes/Admin/Company/Profile/CompanyBrandingFields'
import PreviewDialogs from '~/routes/Admin/Company/Profile/PreviewDialogs'
import isDev from '~/config/isDev'
// import { alphaChars } from '~/core/utils/regex'

export default function CompanyDetailsForm() {
  const { data, error, isLoading, mutate, isSubmitting, onSuccess } =
    useCompanyDetails()

  const formContext = useForm({
    shouldUnregister: true,
    defaultValues: {
      // primaryColor: '#ffffff',
      // secondaryColor: '#ffffff',
      ...data,
    },
  })

  React.useEffect(() => {
    if (data) formContext.reset(data)
  }, [data])

  return (
    <FormContainer formContext={formContext} onSuccess={onSuccess}>
      <Stack spacing={3} alignItems="flex-start">
        <Typography variant="h4">Company details</Typography>
        <CompanyDetailsFields />

        <Typography variant="h4">Address</Typography>

        <AddressFields />

        <Typography variant="h4">Contact emails</Typography>
        <ContactEmails />

        <CompanyBrandingFields />

        {isDev() && <PreviewDialogs />}

        <SubmitButton isSubmitting={isSubmitting} />
      </Stack>
      <LoadingIndicators {...{ isSubmitting, isLoading }} />
    </FormContainer>
  )
}
