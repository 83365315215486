import { Stack } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { TextFieldElement } from 'react-hook-form-mui'
import sharedProps from '~/components/sharedProps'
import FieldPresenceSwitch from '~/core/forms/FieldPresenceSwitch'

export default function AnimateFields({
  component: Component = Stack,
  children,
  ...rest
}) {
  return (
    <Stack {...sharedProps.motion.collapseMargin} {...rest}>
      <FieldPresenceSwitch>{children}</FieldPresenceSwitch>
    </Stack>
  )
}
