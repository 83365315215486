export const boardCollection = {
  name: 'salesResource',
  title: 'Sales resource',
  type: 'document',
  fields: [
    { title: 'Title', name: 'title', type: 'string' },
    {
      title: 'Board collections',
      name: 'boardCollections',
      type: 'array',
      of: [
        {
          type: 'object',
          fields: [
            { title: 'Title', name: 'title', type: 'string' },
            { title: 'Description', name: 'description', type: 'text' },
            {
              title: 'Colour',
              name: 'color',
              type: 'string',
              options: { list: ['red', 'orange', 'pink'] },
            },
            {
              name: 'boards',
              type: 'array',
              of: [{ type: 'reference', to: [{ type: 'board' }] }],
            },
          ],
        },
      ],
    },
  ],
}

export const board = {
  name: 'board',
  // title: 'Playbooks',
  type: 'document',
  fields: [
    { title: 'Title', name: 'title', type: 'string' },
    { title: 'Description', name: 'description', type: 'text' },
    // {
    //   title: 'Colour',
    //   name: 'color',
    //   type: 'string',
    //   options: { list: ['red', 'orange', 'pink'] },
    // },
    {
      title: 'Columns',
      name: 'columns',
      type: 'array',
      of: [{ type: 'column' }],
    },
  ],
}

export const column = {
  name: 'column',
  // title: 'Stages',
  type: 'object',
  fields: [
    { title: 'Title', name: 'title', type: 'string' },
    { title: 'Description', name: 'description', type: 'text' },
    // { title: 'Drop out rate (%)', name: 'dropoutRate', type: 'number' },
    // { title: 'Duration (days)', name: 'duration', type: 'number' },
    {
      title: 'Cards',
      name: 'cards',
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'card' }] }],
    },
  ],
}

export const card = {
  name: 'card',
  // title: 'Cards',
  type: 'document',
  fields: [
    { title: 'Title', name: 'title', type: 'string' },
    { title: 'Description', name: 'description', type: 'string' },
    {
      title: 'Subcards',
      name: 'subcards',
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'subcard' }] }],
    },
  ],
}

export const subcard = {
  name: 'subcard',
  type: 'document',
  fields: [
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      options: {
        list: [
          'Summary',
          'Next steps',
          'Questions',
          'Assets',
          'Objection handling',
          'Gates',
          'Benefit or value',
          'Stakeholders',
        ],
      },
    },
    { title: 'Content', name: 'content', type: 'portableText' },
    {
      title: 'Assets',
      name: 'assets',
      type: 'array',
      of: [{ type: 'image' }, { type: 'file' }, { type: 'video' }],
      // of: [{ type: 'reference', to: [{ type: 'image' }, { type: 'file' }] }],
    },
    {
      title: 'Messages',
      name: 'messages',
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'message' }] }],
    },
  ],
}

export const message = {
  name: 'message',
  type: 'document',
  fields: [
    { title: 'Title', name: 'title', type: 'string' },
    { title: 'Description', name: 'description', type: 'portableText' },
  ],
}

// export default { board, lane, card, question: content, questionCategory }
