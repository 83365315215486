import { motion } from 'framer-motion'

const transition = { duration: 0.3, ease: 'linear' }

export const variants = {
  enter: direction => {
    return {
      x: direction === 'prev' ? '100%' : '-100%',
      // opacity: 0,
      transition,
    }
  },
  center: {
    // zIndex: 1,
    x: 0,
    opacity: 1,
    transition,
  },
  done: direction => {
    return {
      // zIndex: 0,
      x: direction === 'prev' ? '-100%' : '100%',
      // opacity: 0,
      transition,
      position: 'absolute',
    }
  },
}

export const motionStepProps = {
  as: motion.div,
  variants,
  initial: 'enter',
  animate: 'center',
  exit: 'done',
}
