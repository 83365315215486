import { Typography, useTheme } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form-mui'
import ColorPickerElement from '~/core/forms/ColorPickerElement'

export default function CompanyBrandingFields() {
  const formContext = useFormContext()
  const { watch, setValue, register } = formContext
  const primaryColor = watch('primaryColor')
  const theme = useTheme()
  React.useEffect(() => {
    if (primaryColor === null) setValue('secondaryColor', null)
    if (!primaryColor) return

    let secondaryColor
    try {
      secondaryColor = theme.palette.getContrastText(primaryColor)
    } catch (e) {}

    if (secondaryColor) setValue('secondaryColor', secondaryColor)
    else setValue('secondaryColor', null)
  }, [primaryColor])

  return (
    <>
      <Typography variant="h4">Branding</Typography>
      <ColorPickerElement name="primaryColor" label="Primary color" />
      {/* <ColorPickerElement name="secondaryColor" label="Secondary color" /> */}
      <input type="hidden" {...register('secondaryColor')} />
    </>
  )
}
