import { Box, Typography } from '@mui/material'
import React from 'react'
import { useMatches } from 'react-router-dom'

import Breadcrumbs from './Breadcrumbs'

function reverseTraverseAndGetTitles(matches) {
  for (let i = matches.length - 1; i >= 0; i--)
    if (matches[i].handle?.title) return matches[i].handle.title
}

export default function PageHeader({ children }) {
  const matches = useMatches()
  const title = reverseTraverseAndGetTitles(matches)

  if (!title) return null

  return (
    <>
      <Box
        sx={{
          borderBottom: '1px solid #E0E0E0',
          pt: 8,
          pb: 3,
          mb: 3,
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        <Breadcrumbs />
      </Box>
      {children && <Typography>{children}</Typography>}
    </>
  )
}
