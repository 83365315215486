import { Stepper, styled } from '@mui/material'

const StyledStepper = styled(Stepper)(({ theme }) =>
  theme.unstable_sx({
    py: { xs: 1, sm: 2, md: 4 },
    maxWidth: 800,
    mx: 'auto',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'flex-end',
      position: 'fixed',
      top: 7,
      right: 12,
      width: '40%',
      minWidth: 100,
      zIndex: theme.zIndex.appBar + 1,
      '& .MuiStepConnector-line': {
        display: 'none',
      },
      '& .MuiStep-root': {
        px: 0.5,
      },
      '& .MuiStepIcon-root.Mui-active, & .MuiStepIcon-root.Mui-completed': {
        fill: 'rgba(255,255,255,0.2)',
        '& .MuiStepIcon-text': {
          // fill: theme.palette.primary.main,
          fill: theme.palette.primary.contrastText,
        },
      },
    },
  })
)

export default StyledStepper
