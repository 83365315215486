import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import {
  CheckboxButtonGroup,
  FormContainer,
  RadioButtonGroup,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import sharedProps from '~/components/sharedProps'
import flexStackSx from '~/core/sx/flexStackSx'
import useUserProfile from '~/routes/Admin/Profile/useUserProfile'
import { UpdatePasswordFields } from '~/routes/Auth/UpdatePassword'
import StepButtons from '~/routes/Onboarding/StepButtons'

export default function OnboardingCompanyQuestions({
  fieldProps,
  defaultValues,
  children,
  ...rest
}) {
  // const { data, error, isLoading, updateUserProfile } = useUserProfile()

  const formContext = useForm({
    // defaultValues: data,
    defaultValues,
    shouldUnregister: true,
  })

  React.useEffect(() => void formContext.reset(defaultValues), [defaultValues])

  return (
    <>
      <FormContainer {...rest} formContext={formContext}>
        <Card sx={{ ...sharedProps.card.sx, ...flexStackSx }}>
          <Typography variant="h4">
            How many people work at your company
          </Typography>
          <RadioButtonGroup
            name="companyHeadCount"
            options={[
              { id: '0-5', label: '0-5' },
              { id: '6-20', label: '6-20' },
              { id: '21-50', label: '21-50' },
              { id: '50+', label: '50+' },
            ]}
          />
        </Card>

        <Card sx={{ ...sharedProps.card.sx, ...flexStackSx }}>
          <Typography variant="h4">
            How many years has your company worked with Unico systems?
          </Typography>
          <TextFieldElement name="companyRelationshipDuration" type="number" />
        </Card>

        <Card sx={{ ...sharedProps.card.sx, ...flexStackSx }}>
          <Typography variant="h4">
            How many Unico system installs has your company done in the last 12
            months?
          </Typography>
          <RadioButtonGroup
            name="companyAnnualInstalls"
            options={[
              { id: '0-5', label: '0-5' },
              { id: '6-20', label: '6-20' },
              { id: '21-50', label: '21-50' },
              { id: '50+', label: '50+' },
            ]}
          />
        </Card>

        {/* <StepButtons /> */}
        {children}
      </FormContainer>
    </>
  )
}
