import { Card, Typography } from '@mui/material'
import {
  FormContainer,
  RadioButtonGroup,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import sharedProps from '~/components/sharedProps'
import flexStackSx from '~/core/sx/flexStackSx'

export default function OnboardingUserQuestions({
  fieldProps,
  defaultValues,
  children,
  ...rest
}) {
  // const { data, error, isLoading, updateUserProfile } = useUserProfile()

  const formContext = useForm({
    // defaultValues: data,
    defaultValues,
    shouldUnregister: true,
  })

  // React.useEffect(() => void formContext.reset(data), [data])

  return (
    <>
      <FormContainer {...rest} formContext={formContext}>
        <Card sx={{ ...sharedProps.card.sx, ...flexStackSx }}>
          <Typography variant="h4">How often do you use apps?</Typography>
          <RadioButtonGroup
            name="userFrequency"
            options={[
              { id: 'never', label: 'Never' },
              { id: 'sometimes', label: 'Sometimes' },
              { id: 'regularly', label: 'Regularly' },
            ]}
          />
        </Card>

        <Card sx={{ ...sharedProps.card.sx, ...flexStackSx }}>
          <Typography variant="h4">
            How many years have you personally worked with Unico systems?
          </Typography>
          <TextFieldElement
            name="userTenureDuration"
            label="Years"
            type="number"
          />
        </Card>

        <Card sx={{ ...sharedProps.card.sx, ...flexStackSx }}>
          <Typography variant="h4">
            How many Unico system installs have you personally done in the last
            12 months?
          </Typography>
          <RadioButtonGroup
            name="userAnnualInstalls"
            options={[
              { id: '0', label: '0' },
              { id: '1-2', label: '1-2' },
              { id: '3-10', label: '3-10' },
              { id: '11+', label: '11+' },
            ]}
          />
        </Card>

        {/* <StepButtons /> */}
        {children}
      </FormContainer>
    </>
  )
}
