import {
  Chip,
  Dialog,
  Divider,
  Stack,
  TextField,
  Button,
  Typography,
} from '@mui/material'
import * as React from 'react'
import SubmitButton from '~/components/Data/SubmitButton'

import { Close } from '@mui/icons-material'
import BlockIcon from '@mui/icons-material/Block'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import sharedProps from '~/components/sharedProps'
import usePublicProposal from '~/routes/ViewProposal/usePublicProposal'
import { reasonOptionsHomeowner } from '~/routes/Admin/Proposals/statusUtils'

export default function ProposalStatusDialog({ open, onClose, ...rest }) {
  const { proposal, updateStatus, isSubmitting } = usePublicProposal()

  const [statusReasons, setStatusReasons] = React.useState([])
  const [message, setMessage] = React.useState('')

  const toggleStatusReason = reason =>
    setStatusReasons(reasons =>
      reasons.includes(reason)
        ? reasons.filter(r => r !== reason)
        : [...reasons, reason]
    )

  const handleDeclineClick = async () => {
    const partialPayload = {
      status: 'homeowner_rejected',
      statusReasons: statusReasons.map(reason => ({
        reason,
        ...(reason === 'other' && { message }),
      })),
    }
    updateStatus(partialPayload).then(() => onClose())
  }

  return (
    <Dialog
      {...rest}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: 1, maxWidth: 376 } }}
    >
      <Stack spacing={2} sx={{ m: 2 }}>
        <Typography variant="h4">Decline proposal</Typography>
        <Typography>
          Please let us know why you are declining the proposal.
        </Typography>
        <Divider />
        <Typography variant="h4">Rejected reasons</Typography>

        <div>
          {reasonOptionsHomeowner.map(({ id, label }) => (
            <Chip
              key={id}
              sx={{ m: 0.5 }}
              onClick={() => toggleStatusReason(id)}
              label={label}
              variant={statusReasons.includes(id) ? 'filled' : 'outlined'}
              color={statusReasons.includes(id) ? 'primary' : 'default'}
            />
          ))}
        </div>
        <Divider />
        {statusReasons.includes('other') && (
          <Stack spacing={2} {...sharedProps.motion.collapse}>
            <Typography variant="h4">Other detail</Typography>
            <TextField
              rows={3}
              multiline
              onChange={e => setMessage(e.target.value)}
              placeholder="Optionally, please provide a reason"
            />
            <Divider />
          </Stack>
        )}
        <Stack
          spacing={2}
          direction="row"
          sx={{ justifyContent: 'space-between' }}
        >
          <SubmitButton
            variant="contained"
            color="warning"
            onClick={handleDeclineClick}
            endIcon={<BlockIcon />}
            {...{ isSubmitting }}
          >
            Decline proposal
          </SubmitButton>
          <Button
            color="inherit"
            variant="text"
            endIcon={<Close />}
            onClick={onClose}
          >
            Close
          </Button>
        </Stack>
        <LoadingIndicators {...{ isSubmitting }} />
      </Stack>
    </Dialog>
  )
}
