import addressToArray from '~/routes/ViewProposal/addressToArray'

export default function PreviewEmail({
  customerName = 'Customer name',
  companyName = 'Our Company',
  phoneNumber = '555-0100',
  address = {
    line1: '123 Main St',
    city: 'London',
    state: 'KY',
    zipCode: '12345',
  },
  email = 'contact@company.com',
}) {
  return (
    <div className="">
      <div className="aHl" />
      <div id=":om" tabIndex="-1" />
      <div className="ii gt">
        <div className="a3s aiL msg-7287198474571350846" id=":ob">
          <u />
          <div
            style={{
              backgroundColor: '#f4f4f4',
              margin: '0!important',
              padding: '0!important',
            }}
          >
            <div
              style={{
                color: '#fefefe',
                display: 'none',
                fontFamily: 'Lato,Helvetica,Arial,sans-serif',
                fontSize: '1px',
                lineHeight: '1px',
                maxHeight: '0',
                maxWidth: '0',
                opacity: '0',
                overflow: 'hidden',
              }}
            >
              Get ready to confirm into your new Unico account.
            </div>
            <table border="0" cellPadding="0" cellSpacing="0" width="100%">
              <tbody>
                <tr>
                  <td align="center" bgcolor="#1A609E">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      style={{
                        maxWidth: '600px',
                      }}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            align="center"
                            style={{
                              padding: '40px 10px 40px 10px',
                            }}
                            valign="top"
                          >
                            <a>
                              <img
                                alt="Unico"
                                border="0"
                                className="CToWUd"
                                height="60"
                                style={{
                                  color: '#fff',
                                  display: 'block',
                                  fontFamily: 'Lato,Helvetica,Arial,sans-serif',
                                  fontSize: '18px',
                                  maxWidth: '345px',
                                  minWidth: '40px',
                                  width: '345px',
                                }}
                                src="https://motif-cdn.s3.eu-west-2.amazonaws.com/unico/unico-email.png"
                                width="345"
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    align="center"
                    bgcolor="#1A609E"
                    style={{
                      padding: '0 10px 0 10px',
                    }}
                  >
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      style={{
                        maxWidth: '600px',
                      }}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            align="center"
                            bgcolor="#ffffff"
                            style={{
                              borderRadius: '4px 4px 0 0',
                              color: '#111',
                              fontFamily: 'Lato,Helvetica,Arial,sans-serif',
                              fontSize: '32px',
                              fontWeight: '400',
                              letterSpacing: '1px',
                              lineHeight: '32px',
                              padding: '40px 20px 20px 20px',
                            }}
                            valign="top"
                          >
                            Your new Unico System proposal awaits
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    align="center"
                    bgcolor="#f4f4f4"
                    style={{
                      padding: '0 10px 0 10px',
                    }}
                  >
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      style={{
                        maxWidth: '600px',
                      }}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            bgcolor="#ffffff"
                            style={{
                              color: '#666',
                              fontFamily: 'Lato,Helvetica,Arial,sans-serif',
                              fontSize: '18px',
                              fontWeight: '400',
                              lineHeight: '25px',
                              padding: '20px 30px 40px 30px',
                            }}
                          >
                            Hello {customerName},
                            <br />
                            <br />
                            Thank you for considering working with {companyName}
                            .
                            <br />
                            <br />
                            From our conversation, I have put together a
                            comprehensive proposal - please click the link below
                            to review.
                            <br />
                            <br />
                            If you have any questions, please reach out any time
                            to me at: <a>{email}</a>, or <a>{phoneNumber}</a>
                            .
                            <br />
                            <br />
                            I will plan to follow up with you in a few days to
                            answer questions and for next steps.
                            <br />
                            <br />
                            I look forward to working with you.
                            <br />
                            <br />
                            <br />
                            Lewis Hunter
                            <br />
                            <br />
                            Test Rep Company
                            <br />
                            {phoneNumber}
                            <br />
                            <br />
                            {addressToArray(address).join(' ')}
                            <br />
                            <br />
                            {phoneNumber} <a>{email}</a>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" bgcolor="#ffffff">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    bgcolor="#ffffff"
                                    style={{
                                      padding: '0 30px 60px 30px',
                                    }}
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="center"
                                            bgcolor="#1A609E"
                                            style={{
                                              borderRadius: '3px',
                                            }}
                                          >
                                            <a
                                              style={{
                                                border: '1px solid #1a609e',
                                                borderRadius: '2px',
                                                color: '#fff',
                                                display: 'inline-block',
                                                fontFamily:
                                                  'Helvetica,Arial,sans-serif',
                                                fontSize: '20px',
                                                padding: '15px 25px',
                                                textDecoration: 'none',
                                              }}
                                            >
                                              Review your proposal
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            align="left"
                            bgcolor="#ffffff"
                            style={{
                              color: '#666',
                              fontFamily: 'Lato,Helvetica,Arial,sans-serif',
                              fontSize: '18px',
                              fontWeight: '400',
                              lineHeight: '25px',
                              padding: '0 30px 0 30px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                              }}
                            >
                              If the button doesn't work, please copy and paste
                              the following link in your browser:
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td
                            align="left"
                            bgcolor="#ffffff"
                            style={{
                              color: '#666',
                              fontFamily: 'Lato,Helvetica,Arial,sans-serif',
                              fontSize: '18px',
                              fontWeight: '400',
                              lineHeight: '25px',
                              padding: '20px 30px 20px 30px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                              }}
                            >
                              <a
                                style={{
                                  color: '#ffa73b',
                                }}
                              >
                                https://salesapp.
                                <wbr />
                                unicosystem.com
                              </a>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td
                            align="left"
                            bgcolor="#ffffff"
                            style={{
                              borderRadius: '0 0 4px 4px',
                              color: '#666',
                              fontFamily: 'Lato,Helvetica,Arial,sans-serif',
                              fontSize: '18px',
                              fontWeight: '400',
                              lineHeight: '25px',
                              padding: '0 30px 40px 30px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    align="center"
                    bgcolor="#f4f4f4"
                    style={{
                      padding: '30px 10px 0 10px',
                    }}
                  >
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      style={{
                        maxWidth: '600px',
                      }}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            align="center"
                            bgcolor="#FFF0D1"
                            style={{
                              borderRadius: '4px 4px 4px 4px',
                              color: '#666',
                              fontFamily: 'Lato,Helvetica,Arial,sans-serif',
                              fontSize: '18px',
                              fontWeight: '400',
                              lineHeight: '30px',
                              padding: '30px 30px 30px 30px',
                            }}
                          >
                            <h2
                              style={{
                                color: '#111',
                                fontSize: '20px',
                                fontWeight: '400',
                                lineHeight: '30px',
                                margin: '0',
                              }}
                            >
                              Need some help?
                            </h2>
                            <p
                              style={{
                                fontSize: '18px',
                                lineHeight: '25px',
                              }}
                            >
                              Please forward this email to{' '}
                              <a>
                                support@unicosalesapp.zendesk.
                                <wbr />
                                com
                              </a>{' '}
                              and let us know how we can support you.
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="yj6qo" />
            <div className="adL" />
          </div>
          <div className="adL" />
        </div>
      </div>
    </div>
  )
}
