import { AnimatePresence, motion } from 'framer-motion'
import { FormHelperText as MuiFormHelperText } from '@mui/material'

const variants = {
  variants: {
    initial: { height: 0, x: -20, paddingBottom: 0 },
    animate: { height: 'auto', x: 0, paddingBottom: 0 },
    exit: { height: 0, x: 20, paddingBottom: 0 },
  },
  initial: 'initial',
  animate: 'animate',
  transition: {
    x: {
      type: 'spring',
      bounce: 0.87,
      duration: 0.6,
      // damping: 0.2,
    },
  },
}
export default function FormHelperText(props) {
  // if (!props.className?.includes('Mui-error'))
  //   return <MuiFormHelperText {...props} />

  return (
    <AnimatePresence>
      <motion.div
        {...variants}
        initial={props.className?.includes('Mui-error') ? 'initial' : false}
      >
        <MuiFormHelperText {...props} as="div" />
      </motion.div>
    </AnimatePresence>
  )
}
