import { Box, Step, StepButton, StepLabel, Stepper } from '@mui/material'

export default function StepperHeader({ steps = [], ...rest }) {
  return (
    <Stepper alternativeLabel {...rest}>
      {steps.map(({ label, name, Icon }, i) => (
        <Step key={name} sx={{ minWidth: { sm: 70 } }} as="a" href={`#${name}`}>
          <StepButton>
            <StepLabel
              StepIconComponent={Icon}
              sx={{ '& svg': { color: 'var(--feature-text, #fff)' } }}
            >
              <Box
                sx={{
                  display: { xs: 'none', sm: 'inline' },
                  color: 'var(--feature-text, #fff)',
                }}
              >
                {label}
              </Box>
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  )
}
