import { Badge } from '@mui/material'
import useSWR from 'swr'
import client from '~/client'
import useNotifications from '~/routes/Admin/Notifications/useNotifications'

export default function NotificationsBadge() {
  const { data, error, isLoading, mutate } = useNotifications()

  const unread = data?.filter(row => row.dateRead === null) || []

  return <Badge badgeContent={unread.length} sx={{ ml: 2 }} color="primary" />
}
