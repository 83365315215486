import AddIcon from '@mui/icons-material/Add'
import { Stack, Typography } from '@mui/material'
import { FormContainer, useForm } from 'react-hook-form-mui'
import { useNavigate } from 'react-router-dom'
import client from '~/client'
import SubmitButton from '~/components/Data/SubmitButton'
import AddressFields from '~/routes/Admin/Company/Profile/AddressFields'
import CompanyDetailsFields from '~/routes/Admin/Company/Profile/CompanyDetailsFields'
import ContactEmails from '~/routes/Admin/Company/Profile/ContactEmails'
import ProposalSetupFields from '~/routes/Admin/ProposalSetup/Settings/ProposalSetupFields'
import CompanyAdminFields from '~/components/Companies/CompanyAdminFields'
import useCompanies from '~/components/Companies/useCompanies'
import LoadingIndicators from '~/components/Data/LoadingIndicators'

export default function AddCompanyForm() {
  const navigate = useNavigate()

  // const location = useLocation()
  // const data = location.state?.data

  const userInfo = client.getUserInfo()

  const { data, addCompany, isSubmitting } = useCompanies({
    // search: { status: 'active' },
  })

  // const currentUser = client.getUserInfo()

  const formContext = useForm({
    defaultValues: {
      companyType: 'contractingFirm',
    },
    shouldUnregister: true,
  })

  const companyType = formContext.watch('companyType')

  console.log({ companyType })

  return (
    <>
      <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
        Add new company
      </Typography>
      <FormContainer onSuccess={addCompany} formContext={formContext}>
        <Stack spacing={3}>
          <Typography variant="h4">Company details</Typography>
          <CompanyDetailsFields />

          {client.hasRoles(['unico_admin']) && <CompanyAdminFields />}

          <Typography variant="h4">Address</Typography>

          <AddressFields />

          <Typography variant="h4">Contact emails</Typography>
          <ContactEmails />

          {client.hasRoles(['unico_admin']) &&
            companyType !== 'distributor' && (
              <>
                <Typography variant="h4">Proposal setup</Typography>
                <ProposalSetupFields />
              </>
            )}

          <SubmitButton
            // disabled={!username || !password}
            {...{ isSubmitting }}
            sx={{ flexShrink: 0, alignSelf: 'start' }}
            endIcon={<AddIcon />}
          >
            Add new company
          </SubmitButton>
        </Stack>
        <LoadingIndicators {...{ isSubmitting }} />
      </FormContainer>
    </>
  )
}
