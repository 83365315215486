const showOnlyForCategories =
  a =>
  ({ document }) =>
    !document?.spec?.category ||
    !a.some(value => document?.spec?.category.startsWith(value))

const tonnages = [
  { value: '1-1_5t', title: '1 - 1.5t', number: 1.5 },
  { value: '2-2_5t', title: '2 - 2.5t', number: 2.5 },
  { value: '2_5-3t', title: '2.5 - 3t', number: 3 },
  { value: '3-3_5t', title: '3 - 3.5t', number: 3.5 },
  { value: '4-4_5t', title: '4 - 5t', number: 5 },
]

export default {
  name: 'products',
  type: 'document',
  preview: {
    select: { title: 'modelNumber', subtitle: 'title' },
  },
  fields: [
    { name: 'modelNumber', type: 'string', title: 'Model number' },
    { name: 'title', type: 'string', title: 'Title' },
    { name: 'description', type: 'text', title: 'Description' },
    { name: 'images', type: 'array', of: [{ type: 'image' }], title: 'Images' },
    { name: 'files', type: 'array', of: [{ type: 'file' }], title: 'Files' },
    {
      name: 'price',
      type: 'object',

      fields: [
        'global',
        // 'reps',
        // 'firms',
        // 'contractors'
      ].map(name => ({
        name,
        type: 'object',
        options: {
          collapsible: true,
          collapsed: name !== 'global',
          columns: 3,
        },
        fields: ['USD', 'GBP', 'EUR'].map(name => ({
          name,
          type: 'number',
        })),
      })),
    },
    { name: 'boxQuantity', type: 'number', title: 'Box quantity' },
    { name: 'boxWeight', type: 'number', title: 'Box weight' },
    {
      name: 'boxDimensions',
      type: 'object',
      title: 'Box dimensions',
      fields: [
        { name: 'height', title: 'Height', type: 'number' },
        { name: 'width', title: 'Width', type: 'number' },
        { name: 'depth', title: 'Depth', type: 'number' },
      ],
      options: {
        collapsible: true,
        columns: 3,
      },
    },
    {
      name: 'spec',
      type: 'object',
      options: {
        columns: 2,
      },
      fields: [
        {
          name: 'category',
          title: 'Category',
          type: 'string',
          options: {
            list: [
              { value: 'airHandler', title: 'Blower' },
              {
                value: 'coil',
                title: 'Coil assembly',
                description: 'Hot Water, Chilled Water & Refrigerant Coils',
              },
              { value: 'coilAccessory', title: 'Coil' },
              { value: 'returnAirPart', title: 'Return air part' },
              { value: 'electricFurnace', title: 'Electric Furnace' },
              { value: 'outlet', title: 'Outlet' },
              { value: 'ducting', title: 'Ducting' },
              { value: 'plenum', title: 'Plenum' },
              { value: 'indoorAirQuality', title: 'Indoor air quality' },
              { value: 'drainPan', title: 'Drain Pan' },
              { value: 'frameKit', title: 'Frame in kit' },
              { value: 'accessories', title: 'Accessory' },
              { value: 'outdoorUnit', title: 'Outdoor unit' },
              { value: 'storageTank', title: 'Storage tank' },
              { value: 'backupHeater', title: 'Backup heater' },
              { value: 'misc', title: 'Miscellaneous' },
              { value: 'part', title: 'Replacement part' },
            ],
          },
        },
        {
          name: 'tonnage',
          title: 'Tonnage',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: tonnages,
          },
          hidden: ({ document }) => !document?.spec?.category,
        },
        {
          name: 'orientation',
          title: 'Orientation',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'horizontal', title: 'Horizontal' },
              {
                value: 'verticalUpflowModular',
                title: 'Vertical upflow modular',
              },
              {
                value: 'verticalUpflowSingle',
                title: 'Vertical upflow single',
              },
              { value: 'verticalDownflow', title: 'Vertical downflow' },
            ],
          },
          visibleCategories: ['airHandler', 'returnAirPart', 'coil'],
        },
        {
          name: 'hvacType',
          title: 'HVAC Type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'cooling', title: 'Cooling' },
              { value: 'heating', title: 'Heating' },
              { value: 'heatingCooling', title: 'Heating & Cooling' },
            ],
          },
          visibleCategories: [
            'airHandler',
            'coil',
            'returnAirPart',
            'electricFurnace',
            'outdoorUnit',
            'storageTank',
            'backupHeater',
          ],
        },
        {
          name: 'heatingType',
          title: 'Heating type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'heatPump', title: 'Heat pump' },
              { value: 'electric', title: 'Electric' },
              { value: 'hydro', title: 'Hydro air' },
              {
                value: 'airWaterHeatPump',
                title: 'Air to water heat pump',
              },
            ],
          },
          visibleCategories: [
            'airHandler',
            'returnAirPart',
            'electricFurnace',
            'coil',
            'outdoorUnit',
            'storageTank',
            'backupHeater',
          ],
        },
        {
          name: 'coolingType',
          title: 'Cooling type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'chilledWater', title: 'Chilled water' },
              { value: 'refrigerant', title: 'Refrigerant' },
            ],
          },
          visibleCategories: [
            'airHandler',
            'returnAirPart',
            'coil',
            'outdoorUnit',
            'storageTank',
            'backupHeater',
          ],
        },
        {
          name: 'motorType',
          title: 'Motor type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'singleSpeed', title: 'Single speed' },
              { value: 'variable', title: 'Variable ECM' },
            ],
          },
          visibleCategories: ['airHandler'],
        },

        {
          name: 'direction',
          title: 'Direction',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'left', title: 'Left' },
              { value: 'right', title: 'Right' },
            ],
          },
          visibleCategories: ['airHandler', 'returnAirPart', 'coil'],
        },

        {
          name: 'furnaceKw',
          title: 'Furnace Kw',
          type: 'array',
          of: [{ type: 'number' }],
          options: {
            list: [2, 5, 7.5, 10, 15, 20].map(n => ({
              value: n,
              // title: <>{n}&nbsp;Kw</>,
              title: `${n}Kw`,
            })),
            layout: 'grid',
          },
          visibleCategories: ['electricFurnace'],
        },
        {
          name: 'voltage',
          title: 'Voltage',
          type: 'array',
          of: [{ type: 'number' }],
          options: {
            list: [120, 208, 230].map(n => ({
              value: n,
              title: n.toString() + 'V',
            })),
            layout: 'grid',
          },
          visibleCategories: [
            'airHandler',
            'outdoorUnit',
            'storageTank',
            'backupHeater',
          ],
        },
        {
          name: 'coilType',
          title: 'Coil type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'chilledWater', title: 'Chilled water' },
              { value: 'hotWater', title: 'Hot water' },
            ],
          },
          visibleCategories: ['coil'],
        },

        // return air parts
        {
          name: 'returnAirPartConfig',
          title: 'Return air part configuration',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'single', title: 'Single' },
              { value: 'multiple', title: 'Multiple' },
            ],
          },
          // hidden: ({ document }) => !document?.category === 'returnAirPart',
          visibleCategories: ['returnAirPart'],
        },
        {
          name: 'returnAirPartType',
          title: 'Return air part type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'adaptor', title: 'Adaptor' },
              { value: 'module', title: 'Module' },
              { value: 'box', title: 'Box' },
              { value: 'duct', title: 'Duct' },
            ],
          },
          visibleCategories: ['returnAirPart'],
        },
        {
          name: 'rValue',
          title: 'R Value',
          type: 'array',
          of: [{ type: 'number' }],
          options: {
            // list: [3.3, 4.2, 6, 8].map(n => ({
            //   value: n,
            //   title: n.toString() + n === 3.3 ? ' (standard)' : '',
            // })),
            list: [
              { value: 3.3, title: '3.3' },
              { value: 4.2, title: '4.2' },
              { value: 6, title: '6' },
              { value: 8, title: '8' },
            ],
            layout: 'grid',
          },
          visibleCategories: ['returnAirPart', 'ducting', 'plenum'],
        },
        // outlets
        {
          name: 'outletCategory',
          title: 'Outlet category',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              {
                value: 'supplyPlenumAccessory',
                title: 'Supply Plenum Accessory',
              },
              { value: 'roundFacePlate', title: 'Outlet' },
              // { value: 'slottedOutlet', title: 'Slotted Outlet' },
            ],
          },
          visibleCategories: ['outlet'],
        },
        {
          name: 'package',
          title: 'Package',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'single', title: 'Single' },
              { value: 'box', title: 'Box' },
              { value: 'kit', title: 'Kit' },
            ],
            layout: 'grid',
          },
          visibleCategories: ['outlet', 'ducting'],
        },
        {
          name: 'outletType',
          title: 'Outlet type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'round', title: 'Round (straight)' },
              { value: 'roundAngled15', title: 'Round (angled) 15 angle' },
              { value: 'roundAngled25', title: 'Round (angled) 25 angle' },
              { value: 'slotted', title: 'Slotted (straight)' },
              { value: 'slottedAngled', title: 'Slotted (angled)' },
            ],
          },
          visibleCategories: ['outlet'],
        },
        {
          name: 'outletFinish',
          title: 'Outlet finish',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { title: 'Aluminum', value: 'aluminum' },
              { title: 'Birch', value: 'birch' },
              { title: 'Black', value: 'black' },
              { title: 'Brass', value: 'brass' },
              { title: 'Cherry', value: 'cherry' },
              { title: 'Chrome', value: 'chrome' },
              { title: 'Knotty Pine', value: 'knottyPine' },
              { title: 'Mahogany', value: 'mahogany' },
              { title: 'Maple', value: 'maple' },
              { title: 'Pine', value: 'pine' },
              { title: 'Poplar', value: 'poplar' },
              { title: 'Red Oak', value: 'redOak' },
              { title: 'Walnut', value: 'walnut' },
              { title: 'White (standard)', value: 'white' },
              { title: 'White Oak', value: 'whiteOak' },
            ],
          },
          visibleCategories: ['outlet'],
        },
        {
          name: 'outletAdapter',
          title: 'Outlet adapter',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'round7', title: 'Round 7"' },
              { value: 'round9', title: 'Round 9"' },
              { value: 'round10', title: 'Round 10"' },
              { value: 'square8_5', title: 'Square 8.5"' },
              { value: 'square10_5', title: 'Square 10.5"' },
              { value: 'square11_5', title: 'Square 11.5"' },
            ],
          },
          visibleCategories: ['outlet'],
        },
        {
          name: 'outletFlush',
          title: 'Flush type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'flush', title: 'Flush' },
              { value: 'notFlush', title: 'Not flush' },
            ],
            layout: 'grid',
          },
          visibleCategories: ['outlet'],
        },
        {
          name: 'ductType',
          title: 'Duct type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              {
                value: 'soundAttenuatorTubing',
                title: 'Sound attenuator tubing',
              },
              { value: 'aluminiumCoreTubing', title: 'Aluminum core tubing' },
            ],
          },
          visibleCategories: ['ducting'],
        },
        {
          name: 'material',
          title: 'Material',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              {
                value: 'fibreglass',
                title: 'Fibreglass',
              },
              { value: 'metal', title: 'Metal' },
              { value: 'aluminium', title: 'Aluminium' },
              { value: 'soundAttenuating', title: 'Sound attenuating' },
            ],
          },
          visibleCategories: ['plenum'],
        },
        // plenum
        {
          name: 'diameter',
          title: 'Diameter',
          type: 'array',
          of: [{ type: 'number' }],
          options: {
            list: [7, 9, 10, 12, 14, 16, 18, 20].map(n => ({
              value: n,
              title: `${n}`,
            })),
          },
          visibleCategories: ['plenum', 'returnAirPart'],
        },
        {
          name: 'gauge',
          title: 'Gauge',
          type: 'array',
          of: [{ type: 'number' }],
          options: {
            list: [28].map(n => ({ value: n, title: `${n}` })),
          },
          visibleCategories: ['plenum'],
        },
        {
          name: 'plenumType',
          title: 'Plenum type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: ['duct', 'sleeve', 'accessory'].map(n => ({
              value: n,
              title: `${n}`,
            })),
          },
          visibleCategories: ['plenum'],
        },
        // duct
        {
          name: 'ductShape',
          title: 'Duct shape',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'round', title: 'Round' },
              { value: 'square', title: 'Square' },
            ],
            layout: 'grid',
          },
          visibleCategories: ['ducting'],
        },
        {
          name: 'ductSize',
          title: 'Duct/outlet size',
          type: 'array',
          of: [{ type: 'number' }],
          options: {
            list: [
              { value: 2, title: '2"' },
              { value: 2.5, title: '2.5"' },
            ],
            layout: 'grid',
          },
          visibleCategories: ['ducting', 'outlet'],
        },
        {
          name: 'lengthPerBox',
          title: 'Feet per box',
          type: 'array',
          of: [{ type: 'number' }],
          options: {
            list: [5, 10, 12, 25, 60, 72, 75, 100].map(n => ({
              value: n,
              title: n.toString() + 'ft',
            })),
            layout: 'grid',
          },
          visibleCategories: ['ducting', 'plenum', 'returnAirPart'],
        },
        {
          name: 'airQualityType',
          title: 'Air quality type',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'merv', title: 'MERV' },
              { value: 'light', title: 'Light' },
            ],
            layout: 'grid',
          },
          visibleCategories: ['indoorAirQuality'],
        },
        {
          name: 'drainPanModules',
          title: 'Drain pan modules',
          type: 'array',
          of: [{ type: 'number' }],
          options: {
            list: [2, 3].map(n => ({
              value: n,
              title: n.toString() + ' module system',
            })),
            layout: 'grid',
          },
          visibleCategories: ['drainPan'],
        },
        {
          name: 'frameSize',
          title: 'Frame-in size',
          type: 'array',
          of: [{ type: 'number' }],
          options: {
            list: [2, 2.5].map(n => ({
              value: n,
              title: n.toString() + '"',
            })),
            layout: 'grid',
          },
          visibleCategories: ['frameKit', 'ducting', 'returnAirPart'],
        },
        {
          name: 'frameBuild',
          title: 'Frame build',
          type: 'array',
          of: [{ type: 'string' }],
          options: {
            list: [
              { value: 'newBuild', title: 'New build' },
              { value: 'retrofit', title: 'Existing home' },
            ],
            layout: 'grid',
          },
          visibleCategories: ['frameKit'],
        },
        // new fields
        {
          name: 'dimensions',
          type: 'object',
          title: 'Dimensions',
          fields: [
            { name: 'height', title: 'Height', type: 'number' },
            { name: 'width', title: 'Width', type: 'number' },
            { name: 'depth', title: 'Depth', type: 'number' },
          ],
          options: {
            collapsible: true,
            columns: 3,
          },
        },
        {
          name: 'weight',
          type: 'array',
          of: [{ type: 'number' }],
          title: 'Weight',
        },
        {
          name: 'refrigerantType',
          type: 'array',
          of: [{ type: 'string' }],
          title: 'Refrigerant type',
          options: {
            list: ['R410A', 'R22'],
          },
          visibleCategories: ['coilAccessory', 'coil'],
        },
        {
          name: 'btu',
          type: 'array',
          of: [{ type: 'number' }],
          title: 'BTUs',
          visibleCategories: ['coilAccessory', 'coil'],
        },
        {
          name: 'cfm',
          type: 'array',
          of: [{ type: 'number' }],
          title: 'CFM',
          visibleCategories: ['airHandler'],
        },
        {
          name: 'seer',
          type: 'array',
          of: [{ type: 'number' }],
          title: 'SEER',
          visibleCategories: ['airHandler'],
        },
        {
          name: 'supportedHeatPumps',
          title: 'Supported heat pumps',
          type: 'object',
          fields: tonnages.map(({ value, title, number }) => ({
            name: 't' + value.replace(/-/g, ''),
            title,
            type: 'array',
            of: [{ type: 'number' }],
            options: {
              list: [1, 2, 3].map(n => ({
                value: n,
                title: n.toString(),
              })),
              layout: 'grid',
            },
          })),
          visibleCategories: ['storageTank'],
        },
      ].map(field => ({
        ...field,
        ...(field.visibleCategories && {
          hidden: showOnlyForCategories([...field.visibleCategories, 'misc']),
        }),
      })),
      // .map(field => ({
      //   ...field,
      //   title: (
      //     <>
      //       {field.title} [<code>{field.name}</code>]
      //     </>
      //   ),
      //   options: {
      //     ...field.options,
      //     list: field.options?.list?.map(option => ({
      //       ...option,
      //       title: `${option.title} [${option.value}]`,
      //     })),
      //   },
      // })),
    },
    {
      name: 'generation',
      type: 'number',
      title: 'Generation',
      options: { list: [1, 2, 3, 4] },
    },
    {
      name: 'replaces',
      title: 'Replacement for',
      description: 'Legacy products this replaces',
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'products' }] }],
    },
    {
      name: 'parts',
      title: 'Parts',
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'products' }] }],
    },
    {
      name: 'info',
      title: 'Info',
      type: 'array',
      of: [
        {
          type: 'object',
          preview: {
            select: {
              title: 'title',
              subtitle: 'value',
            },
            prepare: ({ title, subtitle }) => ({
              title,
              subtitle: subtitle.join(', '),
            }),
          },
          fields: [
            {
              name: 'name',
              type: 'string',
              validation: Rule => Rule.required(),
              options: {
                list: [
                  { title: 'Decibel rating', value: 'decibelRating' },
                  {
                    title: 'Refrigerant line connection',
                    value: 'refrigerantLineConnection',
                  },
                  {
                    title: 'Chiled water connections',
                    value: 'chiledWaterConnections',
                  },
                  { title: 'Water connections', value: 'waterConnections' },
                  {
                    title: 'Condensate connection',
                    value: 'condensateConnection',
                  },
                  {
                    title: 'Condensate connection',
                    value: 'condensateConnection',
                  },
                  {
                    title: 'Minimum airflow (CFM)',
                    value: 'minimumAirflowCFM',
                  },
                ],
              },
            },
            {
              name: 'value',
              type: 'array',
              validation: Rule => Rule.required(),
              of: [{ type: 'string' }, { type: 'number' }],
            },
          ],
        },
      ],
    },
  ],
}
