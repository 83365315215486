import useSanityQuery from '~/client/useSanityQuery'

export default function useBoardCollection(boardCollectionId) {
  const query = `
  *[_type == 'salesResource' && _id == '${boardCollectionId}'][0]{ 
    ... ,
      boardCollections[] {
        ...,
        boards[]-> { _id, title, description },
      }
    }.boardCollections`
  
  const result = useSanityQuery(
    query
    // { params: { id } }
  )

  return result
}
