import useSanityQuery from '~/client/useSanityQuery'

export default function useBoard(boardId) {
  // TODO remove deep query
  const query = `
  *[_type == 'board' && _id == '${boardId}'][0]{
    ...,
    columns[]{
      ...,
      cards[]->{
        ...,
        subcards[] -> {
          ...,
          assets[]{
            ...
            asset ->,
          },
          messages[] ->,
        }     
      }
    }
  }`
  const result = useSanityQuery(
    query
    // { params: { id } }
  )

  return result
}
