import { green, grey, purple } from '@mui/material/colors'

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 ',
}

const palette = {
  name: 'default',
  mode: 'light',
  primary: {
    main: '#1565C0',
    contrastText: '#FFF',
  },
  secondary: {
    // main: '#1E88E5',
    main: '#16203d',
    contrastText: '#FFF',
  },
  success: {
    main: '#4CAF50',
    contrastText: '#FFF',
  },
  neutral: {
    main: '#999',
    contrastText: '#fff',
  },
  super: {
    main: '#8c03fc',
    contrastText: '#fff',
  },
  contrast: {
    main: '#fff',
    contrastText: '#1565C0',
  },
  background: {
    default: '#F7F9FC',
    paper: '#FFF',
  },
  header: {
    color: '#fff',
    background: '#1E88E5',
    search: {
      color: grey[800],
    },
    // indicator: {
    //   background: customBlue[600],
    // },
  },
  footer: {
    color: grey[500],
    background: '#FFF',
  },
  sidebar: {
    color: '#fff',
    background: '#1B2430',
    header: {
      color: grey[200],
      background: '#1B2430',
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: customBlue[500],
    },
  },
}

export default palette
