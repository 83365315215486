import client from '~/client'

export const shouldOnboardCompany = user =>
  console.log('USSUSSUS', user, client.roles) ||
  (user?.role &&
    [
      'contract_firm_admin',
      'rep_admin',
      'distributor_admin',
      'super_admin',
    ].includes(user.role) &&
    user.company?.onboarded === false)

export const shouldOnboardUser = user => user?.onboarded === false

export default function shouldOnboard(user) {
  return shouldOnboardCompany(user) || shouldOnboardUser(user)
}
