import { Clear } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import Pagination from '~/components/Data/Pagination'
import sharedProps from '~/components/sharedProps'
import useAssets from '~/routes/Admin/SalesResources/Assets/useAssets'
import MediaCard from '~/routes/Admin/SalesResources/Subcard/MediaCard'
import useMediaTags from '~/routes/Admin/SalesResources/hooks/useMediaTags'

import fileTypesOptions from '~/routes/Admin/SalesResources/Subcard/fileTypesOptions'

export default function AssetList() {
  const [search, setSearch] = React.useState('')
  const [mediaTags, setMediaTags] = React.useState([])
  const [fileTypes, setFileTypes] = React.useState([])

  const { data, pagination } = useAssets({
    search,
    extensions: fileTypes.flatMap(({ extensions }) => extensions),
    mediaTags: mediaTags?.map(({ id }) => id),
  })

  const { data: mediaTagsOptions = [] } = useMediaTags()

  console.log('AssetList data', { data, mediaTags, fileTypes })

  const handleClear = () => {
    setSearch('')
    setMediaTags([])
    setFileTypes([])
  }

  return (
    <Box>
      <Card {...sharedProps.card}>
        <Stack
          direction="row"
          sx={{ mb: 2, alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h4">Search and filtering</Typography>
          <Button
            color="inherit"
            variant="text"
            endIcon={<Clear />}
            onClick={handleClear}
            sx={{ px: 1 }}
          >
            Clear&nbsp;all
          </Button>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              value={search}
              onChange={e => setSearch(e.target.value)}
              fullWidth
              label="Search"
              input
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="clear"
                    onClick={() => setSearch('')}
                    edge="end"
                    style={{ visibility: search ? 'visible' : 'hidden' }}
                  >
                    <Clear />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              multiple
              id="mediaTags"
              options={mediaTagsOptions}
              getOptionLabel={option => option.title}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tags"
                  placeholder="Search by tag"
                />
              )}
              value={mediaTags}
              onChange={(e, value) => setMediaTags(value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              multiple
              id="fileTypes"
              options={fileTypesOptions}
              getOptionLabel={option => option.title}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tags"
                  placeholder="Search by file type"
                />
              )}
              value={fileTypes}
              onChange={(e, value) => setFileTypes(value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={2}>
        {data?.map(asset => (
          <Grid key={asset._id} item xs={6} sm={4} lg={3} xl={2}>
            <MediaCard asset={asset} sx={{ height: '100%' }} />
          </Grid>
        ))}
      </Grid>
      <Pagination {...pagination} />
    </Box>
  )
}
