import { Box } from '@mui/material'
import useMeasure from 'react-use-measure'

const GoogleMap = ({
  q,
  key = 'AIzaSyBD-99wiXasbOWe8SAXdWD_Rw3Q1zPBs20',
  ...rest
}) => {
  const [ref, { width, height }] = useMeasure()
  return (
    <Box
      ref={ref}
      sx={{ position: 'relative', width: '100%', aspectRatio: '16/9' }}
    >
      <Box
        as="iframe"
        width={width}
        height={width * 0.5625}
        // height={250}
        frameBorder={0}
        style={{ border: 0, minHeight: 250, borderRadius: 4 }}
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${key}&q=${q}`}
        allowFullScreen=""
        {...rest}
      />
    </Box>
  )
}

export default GoogleMap
