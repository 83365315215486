import useSanityQuery from '~/client/useSanityQuery'

const columns = ['modelNumber', 'title', 'tonnage', 'generation']

export default function useNewerGenProducts({ modelNumber } = {}) {
  const query = modelNumber
    ? `*[_type == 'products' && 
  *[_type == 'products' && modelNumber == '${modelNumber}'][0]._id in replaces[]._ref] 
  | order(generation desc) 
  { _id, ${columns.join()} }`
    : null

  return { columns, ...useSanityQuery(query) }
}
