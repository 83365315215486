import { Card, Typography } from '@mui/material'
import sharedProps from '~/components/sharedProps'
import flexStackSx from '~/core/sx/flexStackSx'

export default function OnboardingFinish({ children }) {
  return (
    <>
      <Card
        sx={{ ...sharedProps.card.sx, ...flexStackSx, alignItems: 'center' }}
      >
        <Typography variant="h4">Congratulations!</Typography>
        <Typography variant="h5">Your account is all set up.</Typography>
        <Typography>
          You can edit and update all your settings in the app
        </Typography>
      </Card>
      {children}
    </>
  )
}
