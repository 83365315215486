import { Typography } from '@mui/material'
import { TextFieldElement } from 'react-hook-form-mui'
// import { alphaChars } from '~/core/utils/regex'

export default function CompanyDetailsFields() {
  return (
    <>
      {/* <Typography variant="h4">Company details</Typography> */}
      <TextFieldElement
        required
        // fullWidth
        name="companyName"
        label="Company Name"
        type="text"
        sx={{ width: '50%', minWidth: 200 }}
      />
      {/* <SelectElement
        required
        name="companyType"
        label="Company type"
        options={[
          { id: 'contractingFirm', label: 'Contracting firm' },
          { id: 'distributor', label: 'Distributor' },
          { id: 'repCompany', label: 'Rep company' },
        ]}
        sx={{ width: '50%', minWidth: 200 }}
      /> */}
      <TextFieldElement
        // required
        // fullWidth
        name="phoneNumber"
        label="Phone number"
        type="tel"
        sx={{ width: '50%', minWidth: 200 }}
      />
      <TextFieldElement
        required
        // fullWidth
        name="website"
        label="Website"
        type="text"
        sx={{ width: '50%', minWidth: 200 }}
      />
    </>
  )
}
