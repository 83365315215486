import { enqueueSnackbar } from 'notistack'
import React from 'react'
import client from '~/client'
import usePageQuery from '~/client/usePageQuery'

export default function useUsers({
  query,
  companyId,
  search,
  ...options
} = {}) {
  const userInfo = client.getUserInfo()

  const { mutate, ...response } = usePageQuery(
    query || `companyusers/?companyId=${companyId || userInfo.companyId}`,
    { search }
  )

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleDelete = async ({ email }) => {
    if (confirm(`Are you sure you want to delete ${email}?`)) {
      try {
        setIsSubmitting(email)
        await client.delete('protected', { username: email })
        mutate()
        enqueueSnackbar(`${email} deleted`)
      } catch (e) {
        console.error(e)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  const resendInvitation = async ({ userId, email = 'user' }) => {
    try {
      setIsSubmitting(userId)
      await client.post('resend-invitation', { userId })
      mutate()
      enqueueSnackbar(`Invitation confirmation sent to ${email}`)
    } catch (e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  return { mutate, ...response, isSubmitting, handleDelete, resendInvitation }
}
