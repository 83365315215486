const objectToGroq = (params, key = '', path = key ? `${key}.` : '') =>
  Object.entries(params)
    .filter(([k, v]) => (Array.isArray(v) ? v.length : v))
    .map(([k, v]) =>
      Array.isArray(v)
        ? ` && (${v
            .map(
              v2 => `${typeof v2 === 'number' ? v2 : `'${v2}'`} in ${path}${k}`
            )
            .join(' || ')})`
        : typeof v === 'object'
        ? objectToGroq(v, `${path}${k}`)
        : ` && ${path}${k} == '${v}'`
    )
    .join('')

export default objectToGroq
