import { Divider, Typography } from '@mui/material'
import { StyledColumn } from '~/routes/Admin/SalesResources/StyledCard'
// const colors = ['#FF4D70', '#FF7C4D', '#D14DFF', '#72D761']

export const ColumnContent = ({ children, column }) => (
  <div>
    <Typography variant="h4" gutterBottom>
      {column?.title}
    </Typography>
    <Typography gutterBottom>{column?.description}</Typography>
    {children}
  </div>
)

function Column({ children, sx, column, index, ...rest }) {
  return (
    <StyledColumn
      sx={{
        overflowY: 'scroll',
        mb: 0,
        width: 280,
        ...sx,
      }}
      {...rest}
    >
      <ColumnContent column={column} />
      <Divider sx={{ my: 3 }} />
      {children}
    </StyledColumn>
  )
}

export default Column
