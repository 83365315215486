import { useEffect } from 'react'

const useScript = ({
  src,
  enabled,
  integrity,
  async = true,
  crossOrigin = 'anonymous',
}) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = src

    script.async = async

    if (integrity) {
      script.integrity = integrity
    }

    script.crossOrigin = crossOrigin

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [src, integrity, async, crossOrigin, enabled])
}

export default useScript
