import { Card, Grid, Typography } from '@mui/material'
// import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { Box } from '@mui/system'
import { Outlet } from 'react-router-dom'
import client from '~/client'
import PageHeader from '~/components/Layouts/PageHeader'
import Chip from '~/components/Data/Chip'
import sharedProps from '~/components/sharedProps'
import CompanyDetailsForm from './CompanyDetailsForm'
import { formatDate } from '~/core/utils/formatDate'
import useCompanyDetails from '~/routes/Admin/Company/Profile/useCompanyDetails'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import PreviewDialogs from '~/routes/Admin/Company/Profile/PreviewDialogs'
import CompanyLogoUpload from '~/routes/Admin/Company/Profile/CompanyLogoUpload'

const findAttr = (item, key) =>
  item.Attributes.find(attr => attr.Name === key)?.Value || ''

export default function CompanyProfile() {
  const userInfo = client.getUserInfo()
  const { data, error, isLoading } = useCompanyDetails()

  console.log('userInfo', { userInfo, data })
  return (
    <>
      <PageHeader />
      <Grid
        container
        spacing={3}
        // justify="center"
      >
        <Grid item xs={12}>
          <Card {...sharedProps.card}>
            <LoadingIndicators isLoading={isLoading} />
            <Typography variant="h3" sx={{ mb: 2 }}>
              Overview
            </Typography>
            <Box {...sharedProps.pageInfo}>
              <div>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Created date
                </Typography>
                <Typography variant="caption">
                  {formatDate(data?.createdAt)}
                </Typography>
              </div>
              <div>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Status
                </Typography>
                <Chip size="small" label="Active" color="success" />
              </div>
              <div>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Relationship PIN code
                </Typography>
                <Typography variant="caption">
                  {data?.relationshipPinCode}
                </Typography>
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card {...sharedProps.card}>
            <CompanyDetailsForm />
          </Card>

          <Card sx={{ p: sharedProps.card.sx.p }}>
            <CompanyLogoUpload />
          </Card>
        </Grid>
      </Grid>

      <Outlet />
    </>
  )
}
