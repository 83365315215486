import AssetPreview from '~/routes/Admin/Company/Assets/AssetPreview'

const videoProps = {
  autoPlay: true,
  controls: true,
}

const AssetViewport = ({ asset, ...props }) => {
  const isVideo = asset?.meta?.ContentType?.startsWith('video/')

  // const isPreviewableInDrive =
  // asset?.meta?.ContentType?.startsWith('application/')

  // if (isPreviewableInDrive) return <AssetPreviewDocument asset={asset} />

  return (
    <AssetPreview
      // disableDownload
      asset={asset}
      {...props}
      {...(isVideo && videoProps)}
    />
  )
}

export default AssetViewport
