import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import client from '~/client'
import PageHeader from '~/components/Layouts/PageHeader'
import sharedProps from '~/components/sharedProps'
import EditProfile from './EditProfile'
import Chip from '~/components/Data/Chip'
import useCompanyDetails from '~/routes/Admin/Company/Profile/useCompanyDetails'
import { formatDate } from '~/core/utils/formatDate'

export default function Profile() {
  const userInfo = client.getUserInfo()

  const { data } = useCompanyDetails()

  return (
    <>
      <PageHeader />
      <Card {...sharedProps.card}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Overview
        </Typography>
        <Box {...sharedProps.pageInfo}>
          <div>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Created date
            </Typography>
            <Typography variant="caption">
              {formatDate(data?.createdAt)}
            </Typography>
          </div>
          <div>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Company
            </Typography>
            <Typography>{data?.companyName}</Typography>
          </div>
          <div>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Email
            </Typography>
            <Typography>{userInfo?.email}</Typography>
          </div>
          <div>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Role
            </Typography>
            <Chip label={userInfo.role} />
          </div>
          <div>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Status
            </Typography>
            <Chip size="small" label="Active" color="success" />
          </div>
        </Box>
      </Card>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <EditProfile
            fieldProps={{ disabled: client.hasOnlyRoles(['demo_user']) }}
          />
        </Grid>
      </Grid>
    </>
  )
}
