import { Close, Preview, Visibility } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import React from 'react'
import client from '~/client'
import sharedProps from '~/components/sharedProps'
import exampleProposal from '~/routes/Admin/Company/Profile/exampleProposal'
import PreviewEmail from '~/routes/Admin/Company/Profile/PreviewEmail'
import ViewProposal from '~/routes/ViewProposal'

// const CloseButton = ({ sx, ...rest }) => (
//   <IconButton
//     {...rest}
//     sx={{ sx, ...sharedProps.button.x.sx, background: 'white!important' }}
//   >
//     <Close />
//   </IconButton>
// )

const PreviewDialog = ({ title, children, ...rest }) => (
  <Dialog maxWidth="sm" {...rest}>
    <DialogTitle
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 1,
        pr: 1,
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Button
        onClick={rest.onClose}
        variant="text"
        color="inherit"
        endIcon={<Close />}
      >
        Close
      </Button>
    </DialogTitle>
    <DialogContent sx={{ p: 0 }}>{children}</DialogContent>
  </Dialog>
)

export default function PreviewDialogs() {
  const [open, setOpen] = React.useState(false)
  const onClose = () => setOpen(false)

  const userInfo = client.getUserInfo()
  return (
    <>
      <Typography
        color="primary.main"
        sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        onClick={() => setOpen('email')}
      >
        <Visibility sx={{ mr: 1 }} />
        Preview homeowner email
      </Typography>
      <Typography
        color="primary.main"
        sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        onClick={() => setOpen('proposal')}
      >
        <Visibility sx={{ mr: 1 }} />
        Preview proposal
      </Typography>
      <PreviewDialog
        title="Preview homeowner email"
        open={open === 'email'}
        onClose={onClose}
        maxWidth="sm"
      >
        <PreviewEmail />
      </PreviewDialog>
      <PreviewDialog
        title="Preview proposal"
        open={open === 'proposal'}
        onClose={onClose}
        maxWidth="xl"
      >
        <ViewProposal proposal={exampleProposal} disableSplash />
      </PreviewDialog>
    </>
  )
}
