import { createElement } from 'react'
import { MenuItem, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
// import { useFormError } from './FormErrorProvider'

export default function SelectElement({
  name,
  required,
  valueKey = 'id',
  labelKey = 'label',
  options = [],
  parseError,
  type,
  objectOnChange,
  validation = {},
  control,
  ...rest
}) {
  // const errorMsgFn = useFormError()
  const customErrorFn = parseError // || errorMsgFn
  const isNativeSelect = !!rest.SelectProps?.native
  const ChildComponent = isNativeSelect ? 'option' : MenuItem

  if (required && !validation.required) {
    validation.required = 'This field is required'
  }

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({
        field: { onBlur, onChange, value, ref },
        fieldState: { error },
      }) => {
        // handle shrink on number input fields
        if (type === 'number' && typeof value !== 'undefined') {
          rest.InputLabelProps = rest.InputLabelProps || {}
          rest.InputLabelProps.shrink = true
        }

        value = value?.[valueKey] ?? value // try fetch key value

        return (
          <TextField
            {...rest}
            name={name}
            value={value ?? ''}
            onBlur={onBlur}
            onChange={event => {
              let item = event.target.value
              if (type === 'number' && item) {
                item = Number(item)
              }
              onChange(item)
              if (typeof rest.onChange === 'function') {
                if (objectOnChange) {
                  item = options.find(i => i[valueKey] === item)
                }
                rest.onChange(item)
              }
            }}
            select
            required={required}
            error={!!error}
            helperText={
              error
                ? typeof customErrorFn === 'function'
                  ? customErrorFn(error)
                  : error.message
                : rest.helperText
            }
            inputRef={ref}
          >
            {isNativeSelect && <option />}
            {options.map(item =>
              createElement(
                ChildComponent,
                {
                  ...item,
                  key: `${name}_${item[valueKey]}`,
                  value: item?.[valueKey] ?? item,
                },
                item[labelKey]
              )
            )}
          </TextField>
        )
      }}
    />
  )
}
