import { Button, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'

import BlockIcon from '@mui/icons-material/Block'
import CheckIcon from '@mui/icons-material/Check'
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import SentIcon from '@mui/icons-material/MarkEmailReadOutlined'
import { AnimatePresence, motion } from 'framer-motion'

import { alpha, lighten } from '@mui/material'
import React from 'react'
import { LoadingBackdrop } from '~/components/Data/LoadingIndicators'
import SubmittingIcon from '~/components/Data/SubmittingIcon'
import ProposalStatusDialog from '~/routes/ViewProposal/ProposalStatusDialog'
import usePublicProposal from '~/routes/ViewProposal/usePublicProposal'

const motionProps = {
  transition: { duration: 0.3 },
  variants: {
    initial: { opacity: 0, y: '100%' },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: '-100%' },
  },
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
}

const typographyProps = {
  ...motionProps,
  component: motion.p,
  sx: { display: 'flex' },
}

export default function ProposalStatus() {
  const [open, setOpen] = React.useState(false)
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const { proposal, updateStatus, isSubmitting } = usePublicProposal()
  const status = proposal?.status
  if (!status) return null
  return (
    <Box
      sx={theme => ({
        background: 'rgba(255,255,255,0.7)',
        backdropFilter: 'blur(3px)',
        transition: 'background .3s',
        background: status.includes('accepted')
          ? alpha(lighten(theme.palette.success.main, 0.7), 0.8)
          : status.includes('rejected') || status === 'expired'
          ? alpha(lighten(theme.palette.error.main, 0.7), 0.8)
          : status === 'draft'
          ? alpha(lighten(theme.palette.warning.main, 0.7), 0.8)
          : alpha('#fff', 0.7),
        display: 'flex',
        justifyContent: 'center',
        py: 1.5,
      })}
    >
      <ProposalStatusDialog open={open} onClose={() => setOpen(false)} />
      <AnimatePresence mode="popLayout" initial={false}>
        {status === 'sent' && proposal?.secretKey ? (
          <Stack spacing={2} direction="row" key="buttons">
            <Button
              variant="contained"
              color="success"
              endIcon={
                isSubmitting === 'homeowner_accepted' ? (
                  <SubmittingIcon isSubmitting />
                ) : (
                  <CheckIcon />
                )
              }
              onClick={() => updateStatus({ status: 'homeowner_accepted' })}
              {...motionProps}
              component={motion.button}
            >
              Accept proposal
            </Button>
            <Button
              color="error"
              // color="warning"
              // variant="contained"
              endIcon={<BlockIcon />}
              // onClick={() => updateStatus({ status: 'rejected' })}
              onClick={() => setOpen(true)}
              {...motionProps}
              component={motion.button}
            >
              Decline proposal
            </Button>
            <LoadingBackdrop active={isSubmitting === 'homeowner_accepted'} />
          </Stack>
        ) : status === 'sent' ? (
          <Typography key={status} {...typographyProps} color="success.dark">
            <SentIcon sx={{ mr: 1 }} /> This proposal has been sent to the
            homeowner
          </Typography>
        ) : status.includes('accepted') ? (
          <Typography key={status} {...typographyProps} color="success.dark">
            <CheckIcon sx={{ mr: 1 }} /> This proposal has been accepted
          </Typography>
        ) : status.includes('rejected') ? (
          <Typography key={status} {...typographyProps} color="error.dark">
            <BlockIcon sx={{ mr: 1 }} /> This proposal has been declined
          </Typography>
        ) : status === 'expired' ? (
          <Typography key={status} {...typographyProps} color="error.dark">
            <HourglassBottomIcon sx={{ mr: 1 }} /> This proposal has expired
          </Typography>
        ) : status === 'draft' ? (
          <Typography key={status} {...typographyProps} color="warning.dark">
            <DraftsOutlinedIcon sx={{ mr: 1 }} /> You're viewing a draft
            proposal
          </Typography>
        ) : null}
      </AnimatePresence>
    </Box>
  )
}
