import FanIcon from './FanIcon'

export default function SubmittingIcon({
  isSubmitting,
  active = isSubmitting,
}) {
  return (
    <FanIcon
      sx={{
        transition: 'all .3s',
        // mr: -1,
        ...(!active && { width: 0, p: 0, ml: 0 }),
      }}
    />
  )
}
