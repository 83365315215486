import { Box } from '@mui/material'
import { useBoardState } from '~/routes/Admin/SalesResources/Provider/BoardProvider'

export default function BoardContainer({ children, sx, ...rest }) {
  const { boardRef } = useBoardState()
  return (
    <Box
      ref={boardRef}
      sx={{
        display: 'flex',
        flexGrow: 1,
        // overflowX: 'scroll',
        pb: 3,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}
