import { Button, Card, Container, Stack, Typography } from '@mui/material'

import NoteAddIcon from '@mui/icons-material/NoteAdd'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Link, Outlet, useLocation } from 'react-router-dom'
import PageHeader from '~/components/Layouts/PageHeader'
export default function StepComplete() {
  // const navigate = useNavigate()
  // const matches = useMatches()
  const location = useLocation()
  const { proposalId } = location.state
  const { customer, contractor } = location.state.contents

  return (
    <>
      <PageHeader />
      <Container container spacing={2} maxWidth="sm" sx={{ mx: 'auto' }}>
        <Card sx={{ p: 2.5, textAlign: 'center' }}>
          <Stack spacing={3}>
            <Typography variant="h3">Thanks</Typography>
            <Typography>
              The proposal has been sent to {customer.name}.
            </Typography>
            <Typography>Proposal ID is {proposalId}</Typography>
            <Stack direction="row" spacing={3} justifyContent="center">
              <Link to={`/view-proposal/${proposalId}`} target={proposalId}>
                <Button endIcon={<OpenInNewIcon />}>View proposal</Button>
              </Link>
              <Link to={`..`}>
                <Button endIcon={<NoteAddIcon />}>Build another</Button>
              </Link>
            </Stack>
          </Stack>
        </Card>
      </Container>
      <Outlet />
    </>
  )
}
