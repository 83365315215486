import { AutocompleteElement, useFormContext } from 'react-hook-form-mui'
import useRepCompanies from '~/components/Companies/useRepCompanies'
import SelectElement from '~/core/forms/SelectElement'
import { makeAutocompleteElementProps } from '~/core/forms/AutocompleteElementOptions'
export default function CompanyAdminFields() {
  const { options, data } = useRepCompanies()

  const { watch } = useFormContext()
  const companyType = watch('companyType')
  return (
    <>
      <SelectElement
        required
        name="companyType"
        label="Company type"
        options={[
          { id: 'contractingFirm', label: 'Contracting firm' },
          { id: 'distributor', label: 'Distributor' },
          { id: 'repCompany', label: 'Rep company' },
        ]}
        sx={{ width: '50%', minWidth: 200 }}
      />
      {companyType === 'contractingFirm' && (
        <AutocompleteElement
          // required
          name="repCompanyId"
          // options={options}
          label="Rep company"
          {...makeAutocompleteElementProps(data, {
            idKey: 'companyId',
            labelKey: 'companyName',
          })}
        />
      )}
    </>
  )
}
