import Card from '@mui/material/Card'
import * as React from 'react'
// import CardMedia from '@mui/material/CardMedia'
import { Attachment, Download } from '@mui/icons-material'
import { Box, Button, alpha } from '@mui/material'
import Typography from '@mui/material/Typography'
import { getFileType } from '~/routes/Admin/SalesResources/Subcard/fileTypesOptions'
const displayNone = { display: 'none' }

const CardMedia = ({ image, src = image, sx, ...props }) => (
  <Box
    as="img"
    {...props}
    src={src}
    sx={{
      ...sx,
      objectFit: 'cover',
      maxWidth: '100%',
      aspectRatio: '16/9',
      display: 'block',
    }}
  />
)

export const CardMediaWithFallback = ({ fallback, sx, ...rest }) => {
  const [loaded, setLoaded] = React.useState(!fallback)
  return (
    <Box sx={{ position: 'relative' }}>
      <CardMedia
        onLoad={() => setLoaded(true)}
        {...rest}
        sx={{
          ...sx,
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          transition: 'opacity .3s',
          opacity: loaded ? 1 : 0,
        }}
      />
      <CardMedia {...rest} image={fallback} sx={{ ...sx }} />
    </Box>
  )
}

export const constrainBounds = (bounds, aspectRatio) => {
  if (!aspectRatio) return bounds

  if (bounds.width / bounds.height > aspectRatio) {
    const width = bounds.height * aspectRatio
    const left = bounds.left + (bounds.width - width) / 2
    return { ...bounds, right: 'auto', width, left }
    // full width
  } else {
    const height = bounds.width / aspectRatio
    const top = bounds.top + (bounds.height - height) / 2
    const bottom = bounds.bottom + (bounds.height - height) / 2
    // const bottom =
    return { ...bounds, bottom, height, top }
  }
}

export default function MediaCard({ sx, grid, asset, ...rest }) {
  const { url, mimeType, title, originalFilename, extension, metadata } = asset

  const [open, setOpen] = React.useState(false)

  const color = getFileType(extension, 'color') || '#000'

  return (
    <>
      {/* <Dialog open={open} onClose={() => setOpen(false)}>
        <Box as="img" src={url} sx={{ maxWidth: '100%', maxHeight: '100%' }} />
        <CardMedia src={url} fallback={metadata?.lqip} />
      </Dialog> */}
      <Card
        sx={{
          borderRadius: 0.5,
          background: alpha(color, 0.1),
          color,
          aspectRatio: '16/9',
          width: '100%',
          // width: 200,
          ...sx,
        }}
        {...rest}
      >
        {mimeType?.startsWith('image/') ? (
          <Box
            sx={{
              backgroundImage: `url(${url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          />
        ) : (
          // ) : mimeType?.startsWith('video/') ? (
          //   <PositionalVideo src={url} open={open} />
          <Box
            {...(mimeType?.startsWith('application/') && {
              as: 'a',
              href: url,
              target: '_blank',
            })}
            sx={{
              alignItems: 'space-between',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              px: 2,
              pt: 2,
              pb: 1,
              height: '100%',
            }}
          >
            <Typography variant="h4" component="div">
              {title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Attachment /> {extension?.toUpperCase()}
              <Button
                as="a"
                sx={{ display: 'flex', p: 1, ml: 'auto' }}
                href={url + '?dl'}
                size="small"
                color="inherit"
                variant="text"
                // variant="contained"
                startIcon={<Download />}
              >
                Download
              </Button>
            </Box>
          </Box>
        )}
      </Card>
    </>
  )
}
