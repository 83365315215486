import { MultiSelectElement, TextFieldElement } from 'react-hook-form-mui'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'

import { multiSelectElementProps } from '~/components/Data/Filter/SearchContainer'

export default function ProposalSearchFields() {
  return (
    <>
      <TextFieldElement label="Customer email" name="customerEmail" />
      <MultiSelectElement
        {...multiSelectElementProps}
        name="status"
        label="Status"
        options={[
          { id: 'contractor_accepted', label: 'Contractor accepted' },
          { id: 'homeowner_accepted', label: 'Homeowner accepted' },
          { id: 'sent', label: 'Sent' },
          { id: 'draft', label: 'Draft' },
          { id: 'contractor_rejected', label: 'Contractor rejected' },
          { id: 'homeowner_rejected', label: 'Homeowner rejected' },
        ]}
      />
      <TextFieldElement
        label="Zip code"
        name="contents.$-customer-address-zipCode"
      />
      <DatePickerElement name="createdAt.gte" label="Created after" />
      <DatePickerElement name="createdAt.lte" label="Created before" />
      <DatePickerElement name="sentDate.gte" label="Sent after" />
      <DatePickerElement name="sentDate.lte" label="Sent before" />
    </>
  )
}
