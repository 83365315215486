import { Container, Typography } from '@mui/material'
import { Box } from '@mui/system'

// import styled from '@emotion/styled'
// import styled from '@mui/material/styled'
import { styled } from '@mui/material'

export const Demarcator = styled('div')({
  height: 8,
  background:
    'linear-gradient(90deg, var(--primary-color, #C9321F), var(--primary-color, #1C5799))',
})

export const Highlight = styled('div')({
  height: 2,
  width: 64,
  marginBottom: 16,
  background:
    'linear-gradient(90deg, var(--primary-color, #C9321F), var(--primary-color, #1C5799))',
})

export const BlueBox = styled('div')(({ theme }) => ({
  display: 'flex',
  // flexDirection: { xs: 'row', sm: 'column' },
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `var(--feature-background, ${theme.palette.primary.main})`,
  color: `var(--feature-text, ${theme.palette.primary.contrastText})`,
  borderRadius: 4,
  // minWidth: { sm: 172 },
  minWidth: 172,
  padding: '24px 16px ',
  fontSize: 18,
  // color: 'primary.contrastText',
  // bgcolor: 'primary.main',
  // py: 3,
  // px: 2,
}))

// export const Section = styled('section')({
//   padding: '64px 0',
// })

export const Section = ({ children, sx, ...rest }) => (
  <Box
    component="section"
    sx={{
      py: 8,
      color: 'var(--section-text, #18203B)',
      background: 'var(--section-background, #F9F9FC)',
      ...sx,
    }}
    {...rest}
  >
    <Container>{children}</Container>
  </Box>
)

export const AlternateSection = styled(Section)({
  background: 'var(--alternate-background, #18203B)',
  color: 'var(--alternate-text, #fff)',
})

// export const ValueBox = styled('div')({})

export const TypographyArray = (array = [], Component = Typography) => (
  <>
    {array
      ?.filter(v => v)
      .map(text => (
        <Component>{text}</Component>
      ))}
  </>
)
