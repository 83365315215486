import { enqueueSnackbar } from 'notistack'
import React from 'react'
import useSWR from 'swr'
import client from '~/client'
import { v4 as uuid } from 'uuid'
import { getFileExtension } from '~/routes/Admin/Company/Assets/AssetPreview'
import fetchProgress from '~/routes/Admin/Company/Assets/fetchProgress'

export default function useCompanyAssets() {
  const { data: assets, mutate, ...rest } = useSWR('assets', client.fetcher)

  const [isUploading, setIsUploading] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)
  const isSubmitting = isDeleting || isUploading || isUpdating

  const uploadAsset = async data => {
    try {
      setIsUploading(1)

      const file = data.files[0]
      const id = uuid()
      const ext = getFileExtension(file.name)
      const filename = `${id}${ext}`

      const presignedUrl = await client.get(`presigned/?filename=${filename}`)

      // TODO: upload progress, XHR instead of fetch
      const res = await fetchProgress(presignedUrl, {
        method: 'PUT',
        body: file,
        'Content-Type': file.type,
        onProgress: e => setIsUploading(e * 0.95),
      })
      const url = new URL(res.url)

      // we don't actually have the asset yet so work out the filename from s3 res
      const asset = {
        url: url.pathname.replace('/', ''),
        meta: { ...data.meta, ContentType: file.type },
      }

      await mutate(client.put('assets', asset), {
        populateCache: false,
      })
    } catch (e) {
      console.error(e)
      // // enqueueSnackbar(e || 'Error', { variant: 'error' })
    } finally {
      setIsUploading(false)
    }
  }

  // const uploadSmallAsset = async data => {
  //   // console.log('form data data ', data)
  //   try {
  //     setIsUploading(true)
  //     const formdata = new FormData()
  //     // formdata.append("file", fileInput.files[0], "MotifLogo.png");

  //     Object.entries(data).forEach(
  //       ([key, value]) =>
  //         typeof value !== 'undefined' &&
  //         formdata.append(
  //           key,
  //           typeof value === 'string'
  //             ? value
  //             : typeof value === 'number'
  //             ? value
  //             : value?.[0].type?.includes('image')
  //             ? value[0]
  //             : JSON.stringify(value || [])
  //         )
  //     )

  //     // formdata.append('companyId', userInfo.companyId)

  //     // console.log('data', data)
  //     // formdata.forEach(f => console.log('FORMDATA', f))

  //     const res = await client.form('assets', formdata)
  //     enqueueSnackbar(`Asset uploaded`)
  //     console.log('UPDATE', data, formdata, res)
  //     formContext.setValue('files', null)
  //     mutate()
  //   } finally {
  //     setIsUploading(false)
  //   }
  // }

  const updateAsset = async ({ url, meta }) => {
    try {
      setIsUpdating(true)
      await mutate(client.put('assets', { url, meta }), {
        populateCache: false,
      })
      enqueueSnackbar('Asset updated')
    } finally {
      setIsUpdating(false)
    }
  }

  const deleteAsset = async ({ url }) => {
    try {
      setIsDeleting(true)
      await mutate(async () => client.delete('assets', { url }), {
        populateCache: false,
        optimisticData: data => data?.filter(asset => asset.url !== url),
      })
      enqueueSnackbar('Asset deleted')
    } finally {
      setIsDeleting(false)
    }
  }

  return {
    assets,
    mutate,
    ...rest,
    isSubmitting,
    deleteAsset,
    // uploadSmallAsset,
    updateAsset,
    uploadAsset,
    isUploading,
    isUpdating,
  }
}
