import isDev from '~/config/isDev'
import StackClient from './StackClient'
import { enqueueSnackbar } from 'notistack'

const client = new StackClient({
  baseUrl: import.meta.env.VITE_API_DOMAIN,
  onError: e =>
    window.navigator.onLine &&
    isDev() &&
    enqueueSnackbar(e?.toString() || 'Unspecified error', { variant: 'error' }),
  onInValidToken: () => {
    enqueueSnackbar('Session expired, please sign in again', {
      variant: 'error',
    })
    window.location = '/sign-in'
  },
})

export default client
