import { Typography } from '@mui/material'
import React from 'react'
import { useMatches } from 'react-router-dom'

export default function Breadcrumbs() {
  const matches = useMatches()
    .slice(2)
    .filter(match => match.handle?.title)

  if (matches.length < 2) return null

  return (
    <Typography
      component="ul"
      sx={{
        color: 'rgba(0, 0, 0, 0.54)',
        listStyleType: 'none',
        pl: 0,
        '& > li': { display: 'inline' },
      }}
    >
      {matches.map((match, i) => (
        <React.Fragment key={match.id}>
          {i > 0 && <span> / </span>}
          <li key={match.id}>{match.handle.title}</li>
        </React.Fragment>
      ))}
    </Typography>
  )
}
