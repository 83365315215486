import useSWR from 'swr'
import client from '~/client'

export default function useSystemRoles(allowedRoles) {
  const {
    data: systemRoles,
    error,
    isLoading,
  } = useSWR('systemRoles', client.fetcher)

  console.log('useSystemRoles', { systemRoles })

  return (
    systemRoles
      // ?.filter(role => !allowedRoles || allowedRoles.some(s => s === role.name))
      ?.map(option => ({
        id: option.name,
        label: option.name
          .replace(/(?<!^)(?=[A-Z])|_+/g, ' ')
          .replace(/^\w/, c => c.toUpperCase()),
      })) || []
  )
}
