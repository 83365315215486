import { Home } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { CardInfo } from '~/routes/Admin/SalesResources/Card'
import { useBoardState } from '~/routes/Admin/SalesResources/Provider/BoardProvider'
import StyledCard, {
  StyledColumn,
} from '~/routes/Admin/SalesResources/StyledCard'
import useBoardCollection from '~/routes/Admin/SalesResources/hooks/useSalesResource'
const gridItemProps = { xs: 12, sm: 4, md: 3 }

function BoardCollection({ _key, title, description, color, boards, sx }) {
  const { boardRef, boardId, toCard, toBoard, toSubcard, boardBounds } =
    useBoardState()

  return (
    <StyledColumn
      sx={theme => ({
        width: '100%',
        borderTopColor: color,
        position: 'relative',
        mb: 2,
        // ...sx,
        [theme.breakpoints.up('sm')]: {
          display: 'flex',
          alignItems: 'stretch',
          flexDirection: 'row',
          '& > div:first-child': { width: 280, pr: 2 },
        },
      })}
    >
      <Box>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography sx={{ pb: 2 }}>{description}</Typography>
      </Box>
      <Box
        sx={theme => ({
          flexGrow: 1,
          display: 'grid',
          gap: 1,
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat( auto-fill, minmax(280px, 1fr) )',
            gap: 2,
          },
        })}
      >
        {boards?.map(({ _ref, _id = _ref, title, description }) => (
          <Box
            key={_id}
            item
            // {...gridItemProps}
            sx={{
              display: 'flex',
              '& > div': { width: '100%' },
            }}
          >
            <StyledCard
              as="a"
              onClick={() => toBoard(_id)}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                pr: 1,
              }}
            >
              <CardInfo card={{ title, description }} />
              <Home />
            </StyledCard>
          </Box>
        ))}
      </Box>
    </StyledColumn>
  )
}

export default function BoardCollections() {
  const { data } = useBoardCollection('0dc90c93-af85-4a4d-8d56-881f88fb6b66')
  console.log('useBoardCollection', data)

  return (
    // <BoardProvider>
    //   <BoardContainer>
    <Box sx={{ width: '100%' }}>
      {data?.map(({ _key, boards, color, description, title }) => (
        // { _key, title, description, boards }
        <BoardCollection
          key={_key}
          {...{ boards, color, description, title }}
        />
      ))}
    </Box>
    //   </BoardContainer>
    // </BoardProvider>
  )
}
