import AttachmentIcon from '@mui/icons-material/Attachment'
import DownloadIcon from '@mui/icons-material/Download'
import { Box, Card, IconButton, Stack, Typography } from '@mui/material'

const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN

// const sx = { aspectRatio: '16/9' }

const getColorFromExt = (ext, defaultColor = 'grey.800') => {
  if (!ext || !typeof ext === 'string') return defaultColor

  return ext?.includes('.PPT')
    ? 'warning.main'
    : ext.includes('.DOC')
    ? 'info.main'
    : ext.includes('.XLS')
    ? 'success.main'
    : ext.includes('.PDF')
    ? 'error.main'
    : 'grey.800'
}

export const getFileExtension = filename =>
  filename?.match(/\.[0-9a-z]{1,5}$/i)?.[0]

const AssetPreview = ({ asset, disableDownload, sx, ...rest }) => {
  if (!asset) return null

  const contentType = asset?.meta?.ContentType || ''

  // TODO: this functionlity entwines FileUploadElement

  const src = asset.url?.startsWith('data:')
    ? asset.url
    : `${CDN_DOMAIN}${asset.url}`

  if (contentType.startsWith('image'))
    return (
      <Box
        as="img"
        src={src}
        sx={{ width: '100%', objectFit: 'cover', ...sx }}
        {...rest}
      />
    )

  if (contentType.startsWith('video'))
    return (
      <Box
        as="video"
        type={contentType}
        src={src}
        sx={{ width: '100%', objectFit: 'cover', ...sx }}
        // controls
        {...rest}
      />
    )

  // if (contentType === 'application/pdf')
  //   return (
  //     <Box
  //       as="object"
  //       type={contentType}
  //       data={src}
  //       sx={{ width: '100%' }}
  //       // controls
  //       {...props}
  //     >
  //       <p>
  //         Unable to display PDF file. <a href={src}>Download</a> instead.
  //       </p>
  //     </Box>
  //   )

  const ext =
    asset.url?.match(/\.[0-9a-z]{1,5}$/i)?.[0]?.toUpperCase() ||
    contentType.split('/')[1]?.toUpperCase() ||
    '' //'Unknown file type'

  const color = getColorFromExt(ext)

  return (
    <Card
      // {...downloadProps}
      // as="a"
      // href={src}
      // target="_blank"
      sx={{
        width: '100%',
        height: '100%',
        // background: '#eee',
        // border: `1px solid ${color}`,
        // borderRadius: 0.5,
        borderWidth: 0.5,
        borderStyle: 'solid',
        borderColor: color,
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        // cursor: 'pointer', //disableDownload ? 'default' : 'pointer',
        // justifyContent: 'center',

        ...sx,
      }}
      {...rest}
    >
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          color,
          alignSelf: 'flex-start',
          justifySelf: 'flex-start',
          display: 'flex',
          // justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AttachmentIcon />
        <Typography as="span">{ext}</Typography>
      </Stack>

      <Typography
        variant="h6"
        // sx={{ flexGrow: 1 }}
      >
        {asset?.meta?.title || 'Untitled asset'}
      </Typography>
      <Box
        sx={{
          alignSelf: 'flex-end',
          // minHeight: '1em',
        }}
      >
        {!disableDownload && (
          <IconButton
            // size="large"
            sx={{ color: '#666' }}
            as="a"
            href={src}
            target="_blank"
          >
            <DownloadIcon />
          </IconButton>
          //   <IconButton
          //   size="small"
          //   sx={{ color: '#666', p: 0.5 }}
          //   variant="text"
          //   endIcon={<DownloadIcon />}
          // >
          //   Download
          // </IconButton>
        )}
      </Box>
    </Card>
  )
}

export default AssetPreview
