import { TextFieldElement } from 'react-hook-form-mui'
import { Typography } from '@mui/material'
import client from '~/client'
import { roles } from '~/client/StackClient'

export default function ContactEmails({ name = 'contactEmails' }) {
  return (
    <>
      {/* <Typography variant="h4">Contact emails</Typography> */}

      <TextFieldElement
        // fullWidth
        required
        name={`${name}.generalEnquiries`}
        label="General enquiries email address"
        type="email"
        sx={{ width: '50%', minWidth: 200 }}
      />

      {client.hasRoles([
        roles.distributor_admin,
        roles.distributor_user,
        roles.unico_admin,
      ]) && (
        <TextFieldElement
          // fullWidth
          required
          name={`${name}.materialsListExport`}
          label="Materials list export email address"
          type="email"
          sx={{ width: '50%', minWidth: 200 }}
        />
      )}
    </>
  )
}
