import { Box, Button, Card, Container, Link, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import {
  FormContainer,
  PasswordElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { useLocation, useNavigate } from 'react-router-dom'
import client from '~/client'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmittingIcon from '~/components/Data/SubmittingIcon'
import sharedProps from '~/components/sharedProps'
import UpdatePassword from './UpdatePassword'
import shouldOnboard from '~/routes/Onboarding/shouldOnboard'

const variants = {
  initial: { y: '-100%' },
  animate: { y: 0 },
  exit: { y: '-100%' },
}

const defaultValues = {
  username: '',
  password: '',
}

export default function SignIn() {
  const navigate = useNavigate()
  const location = useLocation()
  const [updatePassword, setUpdatePassword] = React.useState()
  const [submitting, setSubmitting] = React.useState(false)

  // const [searchParams] = useSearchParams()
  // console.log(searchParams.get())

  const formContext = useForm({
    defaultValues,
  })

  React.useEffect(() => {
    if (client.isSignedIn()) navigate('/admin')
  }, [])

  const submitButtonRef = React.useRef()

  React.useEffect(() => {
    if (!window.location.search) return

    try {
      const [username, password] = window.location.search
        .replace('?username=', '')
        .split('&password=')

      if (username && password) {
        formContext.reset({ username, password })
        submitButtonRef.current.click()
        // window.location.search = ''
      }
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    }
  }, [])

  const handleClick = async data => {
    try {
      setSubmitting(true)
      const res = await client.signIn(data)
      if (res.token) {
        enqueueSnackbar('Welcome!')
        setTimeout(() =>
          navigate(
            location.state?.from?.pathname === '/admin/sign-out'
              ? '/admin'
              : location.state?.from?.pathname || '/admin'
          )
        )
      } else if (res.session) {
        if (shouldOnboard(res.data.user))
          return navigate('/onboarding', {
            state: {
              user: res.data.user,
              session: res.session,
              username: data.username,
            },
          })

        setSubmitting(false)
        setUpdatePassword({ session: res.session, username: data.username })
      } else {
        console.error(res)
        throw res.message || 'Error signing in'
      }
    } catch (e) {
      setSubmitting(false)
      formContext.setError('password', {
        type: 'custom',
        message:
          'Passwords are case sensitive and may contain special characters',
      })
      enqueueSnackbar(e.toString(), {
        variant: 'error',
      })
      console.error('signup error caught ', e)
    }
  }

  if (updatePassword) return <UpdatePassword {...updatePassword} />

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      as={motion.div}
      variants={variants}
    >
      <Box sx={{ textAlign: 'center' }}>
        <img src="/logo-unico-dark.svg" alt="The Unico System" />
        <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
          Sign in
        </Typography>
      </Box>
      <Card sx={{ p: 3, py: 4, position: 'relative' }}>
        <FormContainer formContext={formContext} onSuccess={handleClick}>
          <TextFieldElement
            fullWidth
            name="username"
            id="username"
            label="Email"
            required
            sx={{ mb: 3 }}
            autoComplete="username"
            type="email"
            // InputLabelProps={{
            //   shrink: true,
            // }}
          />
          <PasswordElement
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            required
            sx={{ mb: 3 }}
            autoComplete="current-password"
            // InputLabelProps={{
            //   shrink: true,
            // }}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              sx={{ flexShrink: 0, alignSelf: 'start' }}
              {...sharedProps.button.save}
              endIcon={<SubmittingIcon active={submitting} />}
              ref={submitButtonRef}
            >
              Sign in
            </Button>
            <Link href="/forgot-password">Forgot password?</Link>
          </Box>
        </FormContainer>
        <LoadingIndicators isSubmitting={submitting} />
      </Card>
    </Container>
  )
}
