import SendIcon from '@mui/icons-material/Send'
import { Stack, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import client from '~/client'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import AutocompleteElementOptions from '~/core/forms/AutocompleteElementOptions'
import useCompanyOptions from '~/components/Users/useCompanyOptions'
import useSystemRoles from './useSystemRoles'
import useMutateUsers from '~/components/Users/useMutateUsers'
import { roles } from '~/client/StackClient'
import useCompanyDetails from '~/routes/Admin/Company/Profile/useCompanyDetails'

export default function AddNewUser({ disableCompany, restrictToOwnCompany }) {
  const navigate = useNavigate()

  const location = useLocation()
  const user = location.state?.user

  const userInfo = client.getUserInfo()

  const mutateUsers = useMutateUsers()

  const [isSubmitting, setSubmitting] = React.useState()

  const handleClick = async data => {
    const newUser = {
      companyId: user?.companyId || userInfo.companyId,
      ...data,
    }
    try {
      setSubmitting(true)
      await client.put('protected', newUser)

      mutateUsers()

      enqueueSnackbar(`User ${newUser.email} added`)
      navigate(-1)
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  const formContext = useForm({
    defaultValues: {
      companyId: user?.companyId || userInfo.companyId,
      // ...defaultValues,
      ...user,
    },
    shouldUnregister: true,
  })

  const systemRoles = useSystemRoles()
  const companyOptions = useCompanyOptions()

  const companyId = formContext.watch('companyId')

  const { data: userCompany } = useCompanyDetails()

  const selectedCompanyType =
    companyOptions?.find(option => option?.id === companyId)?.companyType ||
    userCompany?.companyType

  // bit hacky inferring the roles from start of companyTyoe here
  const allowedRoles = selectedCompanyType
    ? client.hasRoles(['unico_admin'])
      ? systemRoles
      : systemRoles.filter(role =>
          role.id.startsWith(
            (selectedCompanyType || userInfo.role)?.slice(0, 3)
          )
        )
    : []

  console.log('AddNewUser', {
    location,
    user,
    userInfo,
    systemRoles,
    allowedRoles,
    selectedCompanyType,
    companyOptions,
    companyId,
    userCompany,
  })

  return (
    <FormContainer onSuccess={handleClick} formContext={formContext}>
      <Stack spacing={3}>
        <Typography variant="h3">Add new user</Typography>
        <TextFieldElement
          fullWidth
          name="firstName"
          label="First name"
          required
        />
        <TextFieldElement
          fullWidth
          name="lastName"
          label="Last name"
          required
        />
        <TextFieldElement
          fullWidth
          name="email"
          label="Email"
          type="email"
          required
        />
        {/* <PasswordElement
            fullWidth
            name="temp_password"
            label="Temporary password"
            autoComplete="off"
            type="password"
            required
            rules={{ pattern: cognitoPassword }}
          /> */}

        {client.hasRoles(['unico_admin', 'rep_admin']) && !disableCompany && (
          <AutocompleteElementOptions
            fullWidth
            name="companyId"
            label="Company"
            required
            // autocompleteProps={{
            //   disabled: !!disableCompany,
            // }}
            options={companyOptions}
          />
        )}

        <SelectElement
          fullWidth
          name="role"
          label="Role"
          required
          options={allowedRoles}
          // disabled={!selectedCompanyType}
        />
        {/* <TextFieldElement
          fullWidth
          name="phone"
          label="Phone"
          required
          
          autoComplete="phone"
        /> */}

        <SubmitButton
          sx={{ flexShrink: 0, alignSelf: 'start' }}
          isSubmitting={isSubmitting}
          endIcon={<SendIcon />}
        >
          Send confirmation email
        </SubmitButton>
      </Stack>
      <LoadingIndicators isSubmitting={isSubmitting} />
    </FormContainer>
  )
}
