import { AutocompleteElement, TextFieldElement } from 'react-hook-form-mui'
import { stateOptions } from '~/routes/Admin/FastQuote/stateOptions'

const addressSx = {}
const props = {}
export default function AddressFields({ fieldProps, name = 'address' }) {
  return (
    <>
      {/* <Typography variant="h4">Company details</Typography> */}

      <TextFieldElement
        {...props}
        required
        fullWidth
        name={`${name}.line1`}
        label="Line 1"
        type="text"
        {...fieldProps}
      />
      <TextFieldElement
        {...props}
        fullWidth
        name={`${name}.line2`}
        label="Line 2"
        type="text"
        {...fieldProps}
      />
      <TextFieldElement
        {...props}
        fullWidth
        name={`${name}.city`}
        label="City"
        type="text"
        required
        {...fieldProps}
      />
      <AutocompleteElement
        required
        name={`${name}.state`}
        options={stateOptions.map(option => option.id)}
        label="State"
        // groupBy={option => option.country}
        autocompleteProps={{
          // fullWidth: true,
          isOptionEqualToValue: (o, v) => o === v,
          getOptionLabel: value =>
            stateOptions.find(option => option.id === value)?.label || '',
          sx: { width: '50%', minWidth: 200 },
          ...fieldProps,
        }}
      />
      {/* <SelectElement
        name="state"
        options={stateOptions}
        label="State"
        groupBy={option => option.country}

        autocompleteProps={{ fullWidth: true }}
      /> */}
      <TextFieldElement
        // fullWidth
        sx={{ width: '50%', minWidth: 200 }}
        required
        type="text"
        label="Zip code"
        name={`${name}.zipCode`}
        {...fieldProps}
      />
    </>
  )
}
