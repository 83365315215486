import { Card, styled } from '@mui/material'

const hoverSx = {
  transform: 'scale(1.03)',
  transition: 'transform .3s',
  cursor: 'pointer',
}

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'block',
  border: '1px solid #E0E0E0',
  background: '#F7F9FC',
  marginBottom: 8,
  padding: 16,
  borderRadius: 4,
  boxShadow: '0px 0px 14px 0px #3540520D',
  'a&:hover': hoverSx,
  // typeof onClick === 'function' && {
  // }
  // position: 'relative',
}))

export const StyledColumn = styled(Card)(({ theme }) => ({
  transition: 'all .3s',
  // borderStyle: 'solid',
  // borderWidth: '2px',
  // borderColor: 'transparent',
  borderTop: '4px solid #f0a',
  borderTopColor: theme.palette.primary.main,
  '&:not(:last-child)': { marginRight: 16 },
  padding: 16,
  borderRadius: 4,
  // width: 280,
  // position: 'static', ?? changed but why static i wonder
  // overflowY: 'scroll',
  position: 'relative',
  // boxShadow: '0px 0px 14px 0px #3540520D',
}))

export default StyledCard
