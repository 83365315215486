import { Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material'
import * as React from 'react'

import sharedProps from '~/components/sharedProps'

export default function ProductImageList({ images, ...rest }) {
  const [selected, setSelected] = React.useState(null)
  if (!Array.isArray(images)) return null

  return (
    <>
      <Typography variant="h4">Images</Typography>
      <ImageList cols={1} gap={4}>
        {images?.map(image => (
          <ImageListItem
            sx={{ position: 'relative' }}
            key={image.asset.url}
            onClick={() => setSelected(image)}
          >
            <Box as="img" src={image.asset.url} sx={{ width: 120 }} />
          </ImageListItem>
        ))}
      </ImageList>
      <Dialog open={!!selected} onClose={() => setSelected(null)} maxWidth="md">
        <IconButton
          color="inherit"
          onClick={() => setSelected(null)}
          sx={{
            ...sharedProps.button.x.sx,
            color: 'white',
            background: 'rgba(0,0,0,0.25)',
            '&:hover': { background: 'rgba(0,0,0,0.25)' },
          }}
        >
          <Close />
        </IconButton>
        <Box as="img" src={selected?.asset.url} sx={{ width: '100%' }} />
      </Dialog>
    </>
  )
}
