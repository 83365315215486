import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import {
  CheckboxButtonGroup,
  FormContainer,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import sharedProps from '~/components/sharedProps'
import flexStackSx from '~/core/sx/flexStackSx'
import AddressFields from '~/routes/Admin/Company/Profile/AddressFields'
import CompanyDetailsFields from '~/routes/Admin/Company/Profile/CompanyDetailsFields'
import ContactEmails from '~/routes/Admin/Company/Profile/ContactEmails'
import useCompanyDetails from '~/routes/Admin/Company/Profile/useCompanyDetails'
import useUserProfile from '~/routes/Admin/Profile/useUserProfile'
import { UpdatePasswordFields } from '~/routes/Auth/UpdatePassword'
import StepButtons from '~/routes/Onboarding/StepButtons'

export default function OnboardingCompanyDetails({
  fieldProps,
  children,
  ...rest
}) {
  const { data, error, isLoading } = useCompanyDetails()

  const formContext = useForm({
    defaultValues: data,
    shouldUnregister: true,
  })

  React.useEffect(() => void formContext.reset(data), [data])

  return (
    <>
      <FormContainer {...rest} formContext={formContext}>
        <Card
          sx={{
            ...sharedProps.card.sx,
            '& > div': { width: '100%' },
            ...flexStackSx,
          }}
        >
          <Typography variant="h4">
            Please confirm your company's details
          </Typography>

          <CompanyDetailsFields />

          <ContactEmails />

          <Typography variant="h4">Address</Typography>
          <AddressFields />
        </Card>

        {/* <StepButtons /> */}
        {children}
      </FormContainer>
      <LoadingIndicators isLoading={isLoading} />
    </>
  )
}
