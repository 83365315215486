import { Download } from '@mui/icons-material'
import {
  Box,
  Card,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import useSWR from 'swr'
import sanityClient from '~/client/sanity'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import sharedProps from '~/components/sharedProps'
import useSearchParams from '~/core/router/useSearchParams'
import formatBytes from '~/core/utils/formatBytes'
import { camelToSentence } from '~/core/utils/formatString'
import ProductImageList from '~/routes/Admin/ProductCatalogue/ProductImageList'
import ProductList from '~/routes/Admin/ProductCatalogue/ProductList'
import RelatedProducts from '~/routes/Admin/ProductCatalogue/RelatedProducts'
import useNewerGenProducts from '~/routes/Admin/ProductCatalogue/useNewerGenProducts'

const query = `*[_type == 'products' && modelNumber == $modelNumber][0] { 
  ...,
  files[] { ..., asset -> }, 
  images[] { ..., asset -> },
  parts[] -> { _id, modelNumber, title },
  replaces[] -> { _id, modelNumber, title },
}`

export default function ProductInformation({ onClose, ...rest }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { modelNumber, category } = searchParams

  const { data, isLoading } = useSWR(
    [query, { modelNumber }],
    ([query, params]) => sanityClient.fetch(query, params)
  )

  // React.useEffect(() => {
  //   if (data?.spec?.category !== category)
  //     setSearchParams(p => ({ ...p, category: data?.spec?.category || '' }))
  // }, [data?.spec?.category])

  return (
    <Box {...rest} sx={{ flexGrow: 1, position: 'relative', ...rest.sx }}>
      <LoadingIndicators isLoading={isLoading} />
      <Card sx={{ p: 2, mb: 2 }}>
        <Stack spacing={2} direction="row">
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" sx={{ mb: 1 }}>
              {data?.title}
            </Typography>
            <Typography variant="h4" sx={{ color: 'grey.600' }}>
              {modelNumber}
            </Typography>
          </Box>
        </Stack>
      </Card>
      <Card sx={{ p: 2, mb: 2, '& > h4': { mb: 1 } }}>
        {data?.description && (
          <>
            <Typography variant="h4">Description</Typography>
            <Typography>{data.description}</Typography>
            <Divider sx={{ my: 2 }} />
          </>
        )}
        <ProductImageList {...data} />
        {data?.images && <Divider sx={{ my: 3 }} />}
        <ProductSpecTable {...data} />
        <Divider sx={{ my: 2, visibility: 'hidden' }} />
        <ProductInfoTable {...data} />
        <Divider sx={{ my: 2, visibility: 'hidden' }} />
        <ProductBoxInfo {...data} />
        {data?.files && <Divider sx={{ my: 2 }} />}
        <ProductFiles {...data} />
      </Card>
      <RelatedProducts product={data} />
      <ProductList data={data?.parts} title="Replacement parts" />
      <ProductList data={data?.replaces} title="Legacy versions" />
      <ProductList
        {...useNewerGenProducts(data)}
        title="Newer versions"
        // columns={['modelNumber', 'title', 'generation']}
      />
    </Box>
  )
}

const ProductBoxInfo = ({ boxDimensions, boxQuantity, boxWeight }) => {
  console.log(boxDimensions, boxQuantity, boxWeight)
  if ([boxDimensions, boxQuantity, boxWeight].every(v => !v)) return null
  return (
    <>
      <Typography variant="h4">Box information</Typography>
      <Table
        size="small"
        sx={{ width: '100%', ...sharedProps.table.sx, tableLayout: 'auto' }}
      >
        <TableBody>
          {boxDimensions && (
            <TableRow>
              <TableCell variant="head">Dimensions</TableCell>
              <TableCell>
                <b>H</b>
                {boxDimensions.height} <b>W</b>
                {boxDimensions.width} <b>D</b>
                {boxDimensions.depth}
              </TableCell>
            </TableRow>
          )}
          {typeof boxWeight === 'number' && (
            <TableRow>
              <TableCell variant="head">Weight</TableCell>
              <TableCell>{boxWeight}</TableCell>
            </TableRow>
          )}
          {typeof boxQuantity === 'number' && (
            <TableRow>
              <TableCell variant="head">Box quantity</TableCell>
              <TableCell>{boxQuantity}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  )
}

const ProductFiles = ({ files }) => {
  if (!files?.length) return null

  return (
    <>
      <Typography variant="h4">Downloads</Typography>
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
          gridAutoFlow: 'row',
          width: '100%',
        }}
      >
        {files?.map(({ asset, _key }) => (
          <ListItem
            key={_key}
            as="a"
            href={asset.url}
            target="_blank"
            download={asset.originalFilename}
            disableGutters
            sx={{
              py: 0,
              px: 1,
              borderRadius: 0.5,
              '&:hover': { background: theme => theme.palette.action.hover },
            }}
          >
            <ListItemIcon sx={{ minWidth: 36 }}>
              <Download sx={{ color: 'primary.main' }} />
            </ListItemIcon>
            <ListItemText
              sx={{
                '& > span': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
              primary={asset.originalFilename}
              secondary={`${formatBytes(
                asset.size
              )} ${asset.extension?.toUpperCase()}`}
            />
          </ListItem>
        ))}
      </Box>
    </>
  )
}

const ProductSpecTable = ({ spec: { category, ...spec } = {} }) => {
  const specs = Object.entries(spec)
  if (!specs.length) return null

  return (
    <>
      <Typography variant="h4">Specification</Typography>
      <Table
        size="small"
        sx={{ width: '100%', ...sharedProps.table.sx, tableLayout: 'auto' }}
      >
        <TableBody>
          {Object.entries(spec)
            .filter(([key, values]) => Array.isArray(values))
            .map(([key, values]) => (
              <TableRow key={key}>
                <TableCell variant="head">{camelToSentence(key)}</TableCell>
                <TableCell>
                  {values
                    .map?.(v =>
                      typeof v === 'string' ? camelToSentence(v) : v
                    )
                    .join(', ')}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  )
}

const ProductInfoTable = ({ info }) => {
  if (!info?.length) return null

  console.log(info)

  return (
    <>
      <Typography variant="h4">Additional info</Typography>
      <Table
        size="small"
        sx={{ width: '100%', ...sharedProps.table.sx, tableLayout: 'auto' }}
      >
        <TableBody>
          {info
            .filter(({ value }) => Array.isArray(value))
            .map(({ _key, value, name }) => (
              <TableRow key={_key}>
                <TableCell variant="head">{camelToSentence(name)}</TableCell>
                <TableCell>{value.join(', ')}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  )
}
