import { Outlet, useMatches, useParams } from 'react-router-dom'
import PageHeader from '~/components/Layouts/PageHeader'

import BoardContainer from '~/routes/Admin/SalesResources/Provider/BoardContainer'
import { BoardProvider } from '~/routes/Admin/SalesResources/Provider/BoardProvider'
import useCard from '~/routes/Admin/SalesResources/hooks/useCard'

export default function SalesResources() {
  const matches = useMatches('/')

  const params = useParams()

  const { data: card } = useCard(params.cardId)

  console.log({ matches, params, card })
  return (
    <>
      <PageHeader />
      {/* <AppStyles /> */}
      <BoardProvider>
        <BoardContainer>
          <Outlet />
        </BoardContainer>
      </BoardProvider>
    </>
  )
}
