import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'
import { useBoardState } from '~/routes/Admin/SalesResources/Provider/BoardProvider'

import LoadingIndicators from '~/components/Data/LoadingIndicators'
import { CloseButton } from '~/routes/Admin/SalesResources/CloseButton'
import { StyledColumn } from '~/routes/Admin/SalesResources/StyledCard'
import Subcard from '~/routes/Admin/SalesResources/Subcard/Subcard'
import formatSubcards from '~/routes/Admin/SalesResources/Subcard/formatSubcards'
import useBoard from '~/routes/Admin/SalesResources/hooks/useBoard'
import useCard from '~/routes/Admin/SalesResources/hooks/useCard'

export const CardInfo = ({
  card: { title, description } = {},
  variant = 'h5',
  Icon = null,
}) => (
  <div>
    <Typography variant={variant} gutterBottom>
      {Icon && <Icon sx={{ mr: 1 }} />}
      {title}
    </Typography>
    <Box
      sx={{
        overflow: 'hidden',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '2',
      }}
    >
      {description}
    </Box>
  </div>
)

const BoardCardAnchorMenu = ({ subcards, ...rest }) => (
  <Stack spacing={2} {...rest}>
    {subcards?.map(({ name, title, disabled, Icon = null }) => (
      <Card
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          opacity: disabled ? 0.5 : 1,
        }}
        component={disabled ? 'span' : 'a'}
        href={`#${name}`}
      >
        <Icon sx={{ mr: 1 }} />
        {title.replace(/[^A-Za-z0-9]/g, '')}
      </Card>
    ))}
  </Stack>
)

const findSiblingCards = (board, cardId) => {
  const column = board?.columns?.find(column =>
    column.cards?.some(card => card._id === cardId)
  )
  return column?.cards?.map(card => ({
    ...card,
    active: card._id === cardId,
  }))
}

// const BoardCardSiblingMenu = ({ board, card }) => (
//   <Stack direction="row">{board}</Stack>
// )

export default function ViewCard({}) {
  const { boardBounds, boardRef, boardId, toCard, toBoard, cardId } =
    useBoardState()
  const { data: card, isLoading } = useCard(cardId)
  const { data: board } = useBoard(boardId)
  const subcards = formatSubcards(card?.subcards)
  const siblingCards = findSiblingCards(board, cardId)

  console.log('ViewCard', { subcards, card })

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <LoadingIndicators
        isLoading={isLoading}
        sx={{ borderRadius: 0.5, overflow: 'hidden' }}
      />
      <StyledColumn sx={{ width: '100%', mb: 2, pb: 0 }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            mb: 2,
          }}
        >
          <CardInfo card={card} />
          <CloseButton onClick={toBoard}>Back to stage</CloseButton>
        </Box>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'flex-end' }}>
          {siblingCards?.map(card => (
            <Button
              color="inherit"
              onClick={() => toCard(card._id)}
              sx={{
                borderRadius: '4px 4px 0px 0px',
                boxShadow: '0px 0px 14px 0px rgba(53, 64, 82, 0.05)',
                px: 2,
                borderWidth: '1px',
                border: '1px solid #E0E0E0',
                background: '#F7F9FC',
                pb: 0.5,
                // transition: 'padding-bottom .3s, margin-top 0.3s',
                '&:hover': {
                  borderWidth: '1px',
                  // background: '#18203B',
                  color: '#18203B',
                },
                ...(card.active && {
                  // background: '#18203B',
                  // color: '#fff',
                  // mt: 0.5,
                  pb: 1,
                }),
              }}
            >
              {card.title}
            </Button>
          ))}
        </Stack>
      </StyledColumn>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <BoardCardAnchorMenu
          subcards={subcards}
          sx={{ mr: 2, display: { xs: 'none', md: 'block' } }}
        />
        <Card
          sx={{
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            p: 2,
          }}
          // as={motion.div}
          // variants={cardVariants}
        >
          {/* <Divider sx={{ my: 3 }} /> */}

          <Grid
            container
            direction="row"
            spacing={2}
            // sx={{width: '100%'}}
            // component={motion.div}
            // variants={{
            //   initial: { height: 0 },
            //   animate: { height: 'auto' },
            //   exit: { height: 0 },
            // }}
            // transition={{ staggerChildren: 0.2, duration: 0.2 }}
          >
            {subcards
              ?.filter(subcard => !subcard.disabled)
              .map(subcard => (
                <Grid
                  item
                  xs={12}
                  md={subcard.assets || subcard.messages ? 12 : 6}
                  key={subcard._id}
                >
                  <Subcard
                    id={subcard.name}
                    key={subcard._id}
                    {...{ subcard }}
                    // component={motion.div}
                  />
                </Grid>
              ))}
          </Grid>
        </Card>
      </Box>
    </Box>
  )
}
