var options = {
  //   weekday: 'long',
  year: 'numeric',
  month: 'long',
  //   day: 'numeric',
}

// export const formatDate = date => date.toLocaleString('en-GB', options)

const maybeUnixString = value =>
  typeof value === 'string' && value.length === 13 ? parseInt(value) : value

export const formatIsoToDateTime = value => {
  const date = new Date(maybeUnixString(value))
  return date.toLocaleString()
}

export const formatDate = value => {
  if (!value) return null
  const dateTime = new Date(maybeUnixString(value))
  const date = dateTime.toLocaleString()?.split?.(',')?.[0]
  if (date === 'Invalid Date') return null
  return date
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const formatDateMonthYear = value => {
  const date = new Date(maybeUnixString(value))
  return `${months[date.getMonth()]} ${date.getFullYear()}`
}

const n = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelth',
  'thirteenth',
]
// const numberEnding = (i, s = '' + i) =>
//   s.endsWith('1')
//     ? 'st'
//     : s.endsWith('2')
//     ? 'nd'
//     : s.endsWith('3')
//     ? 'rd'
//     : 'th'
export const formatNumber = (i, s = '' + i) =>
  [
    'zeroth',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelth',
    'thirteenth',
  ][i] ||
  `${i}${
    s.endsWith('1') && !s.endsWith('11')
      ? 'st'
      : s.endsWith('2') && !s.endsWith('12')
      ? 'nd'
      : s.endsWith('3') && !s.endsWith('13')
      ? 'rd'
      : 'th'
  }`

export const formatDateNumeric = str => {
  const date = new Date(str)
  return date
    .toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    })
    .replace(/\//g, '.')
}
