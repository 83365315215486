import React from 'react'
import useSWR from 'swr'
import sanityClient from '~/client/sanity'

// const meta = {
//   nextPageId: 0,
//   totalItems: 0,
//   totalPages: 0,
// }

// const paginatedQuery = query.replace(']', `]${o}${p}`)
// const paginatedQuery = query.replace(/\]([^\]]*)$/, `]${o}${p}` + '$1')

export const paginateSanityQuery = (
  query,
  { page, pageSize, field, order }
) => {
  if (!query) return null

  const p =
    typeof page === 'number' && pageSize > 0
      ? `[($page * $pageSize)...($page + 1) * $pageSize] `
      : ''

  const o = field && order ? `| order(${field} ${order}) ` : ''

  const i = query.lastIndexOf(']') + 1
  const queryFilter = query.slice(0, i)
  const queryProjection = query.slice(i)
  const paginatedQuery = queryFilter + o + p + queryProjection

  return `{
    "result": ${paginatedQuery},
    "totalItems": count(${queryFilter}),
  }`
}

export const defaultOptions = {
  // revalidateIfStale: false,
  revalidateOnFocus: false,
  // revalidateOnReconnect: false,
  keepPreviousData: true,
  errorRetryCount: 2,
  dedupingInterval: 15000,
}

// const defaultSorting =

export default function useSanityPageQuery(
  propsQuery,
  { pageSize = 10, initPage = 0, sorting, ...options } = {}
) {
  const [page, setPage] = React.useState(initPage)
  const [sort = {}, setSort] = React.useState(sorting)

  const onSortClick = field =>
    setSort(sort => ({
      field,
      order: sort?.field === field && sort?.order === 'asc' ? 'desc' : 'asc',
    }))

  const params = {
    page,
    pageSize,
    // sortField: sort?.field || '_createdAt',
    // sortValue: sort?.order || 'desc',
    ...options,
  }

  const query = paginateSanityQuery(propsQuery, {
    page,
    pageSize,
    ...sort,
  })

  const { data, mutate, ...rest } = useSWR(
    query ? [query, params] : null,
    ([query, params]) => sanityClient.fetch(query, params),
    { ...defaultOptions, ...options }
  )

  const { data: nextPageData } = useSWR(
    query ? [query, { ...params, page: params.page + 1 }] : null,
    ([query, params]) => sanityClient.fetch(query, params),
    { ...defaultOptions, ...options }
  )

  // const nextPageData = [0]
  React.useEffect(
    () => void setPage(initPage),
    [...Object.values(options), setPage]
  )

  const totalItems = data?.totalItems
  const totalPages = Math.ceil(data?.totalItems / pageSize)

  const isNextPage = page < totalPages - 1
  const isPrevPage = page !== 0

  const onNextClick = () => isNextPage && setPage(p => p + 1)
  const onPrevClick = () => setPage(p => Math.max(0, p))

  return {
    pagination: {
      page,
      setPage,
      onNextClick,
      onPrevClick,
      isNextPage,
      isPrevPage,
      totalItems,
      totalPages,
    },
    sort: {
      ...sort,
      onSortClick,
      setSort,
    },
    data: data?.result,
    ...rest,
  }
}
