import { Box, Typography } from '@mui/material'

import StyledCard from '~/routes/Admin/SalesResources/StyledCard'

export const CardInfo = ({ card: { title, description } }) => (
  <div>
    <Typography variant="h4" gutterBottom>
      {title}
    </Typography>
    <Box
      sx={{
        overflow: 'hidden',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '2',
      }}
    >
      {description}
    </Box>
  </div>
)

function BoardCard({ provided = {}, snapshot = {}, card, ...rest }) {
  return (
    <StyledCard onClick={() => toCard(card._id)}>
      <CardInfo card={card} />
    </StyledCard>
  )
}

export const cardVariants = {
  initial: {
    border: '1px solid #E0E0E0',
    background: '#F7F9FC',
    boxShadow: '0px 0px 14px 0px #3540520D',
  },
  animate: {
    background: '#fff',
    borderColor: 'transparent',
    borderTop: '4px solid #1565C0',
  },
  exit: {
    boxShadow: '0px 0px 14px 0px rgba(53, 64, 82, 0.0)',
    border: '1px solid #E0E0E0',
    background: '#F7F9FC',
  },
}

export default BoardCard
