import { Grid } from '@mui/material'
import GlobalPercentagePrice from '~/routes/Admin/Company/Pricing/GlobalPercentagePrice'

import PricingTable from '~/routes/Admin/Company/Pricing/PricingTable'
import ProductCounter from '~/routes/Admin/Company/Pricing/ProductCounter'
import PageHeader from '~/components/Layouts/PageHeader'

export default function DistributorPricing() {
  // const { data: companyDetails } = useCompanyDetails()
  return (
    <>
      <PageHeader />
      <Grid container spacing={3}>
        <Grid item md={8}>
          {/* <PricingModel /> */}
          <GlobalPercentagePrice />
        </Grid>
        <Grid item md={4}>
          <ProductCounter />
        </Grid>
      </Grid>

      <PricingTable />
    </>
  )
}
