import { Button, Card, Grid, Stack, Typography } from '@mui/material'
import PageHeader from '~/components/Layouts/PageHeader'

import { RemoveRedEye as ViewIcon } from '@mui/icons-material'
import React from 'react'
import usePageQuery from '~/client/usePageQuery'
import useCompanies from '~/components/Companies/useCompanies'
import AutoTable, {
  ChipCell,
  DateCell,
  TableCell,
} from '~/components/Data/AutoTable'
import ProposalSearchFields from '~/components/Data/Filter/ProposalSearchFields'
import SearchContainer from '~/components/Data/Filter/SearchContainer'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import AutocompleteElementOptions from '~/core/forms/AutocompleteElementOptions'

export default function RepGroupProposals() {
  const [search, setSearch] = React.useState()
  const response = usePageQuery(`repCompanyRelations/proposals`, { search })

  const { data: companyRelations } = useCompanies({
    query: 'repCompanyRelations/companies',
  })
  // maybe memoize
  const companiesOptions = companyRelations?.map(({ contractingFirm }) => ({
    label: contractingFirm.companyName,
    value: contractingFirm.companyId,
    id: contractingFirm.companyId,
  }))

  return (
    <>
      <PageHeader />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card {...sharedProps.card}>
            <Stack
              direction="row"
              sx={{ justifyContent: 'space-between', mb: 2 }}
            >
              <Typography variant="h3">My contractor proposals</Typography>
            </Stack>
            <SearchContainer setSearch={setSearch}>
              <ProposalSearchFields />
              <AutocompleteElementOptions
                label="Contracting firm"
                name="contractingFirmId"
                options={companiesOptions}
              />
            </SearchContainer>
            <AutoTable
              {...response}
              uniqueField="proposalId"
              columns={[
                // 'proposalId',
                {
                  field: 'createdAt',
                  headerName: 'Created',
                  Component: DateCell,
                  breakpoints: ['sm'],
                },
                {
                  field: 'company.companyName',
                  sortable: true,
                  headerName: 'Contracting company',
                  breakpoints: ['sm'],
                  Component: ({ data, ...rest }) => (
                    <TableCell {...rest}>
                      {data.contents?.company?.companyName}
                    </TableCell>
                  ),
                },
                {
                  // field: 'contractor.firstName',
                  // sortable: true,
                  headerName: 'Contractor',
                  field: 'user.firstName',
                  sortable: true,
                  breakpoints: ['sm'],
                  Component: ({ data, ...rest }) => (
                    <TableCell {...rest}>
                      {data.contents?.contractor?.firstName}{' '}
                      {data.contents?.contractor?.lastName}
                    </TableCell>
                  ),
                },
                {
                  headerName: 'Address',
                  Component: ({ data, ...rest }) => (
                    <TableCell {...rest}>
                      {data.contents?.customer?.address?.zip},
                      {data.contents?.customer?.address?.state}
                    </TableCell>
                  ),
                },
                {
                  headerName: 'Status',
                  field: 'status',
                  Component: ({ data, ...rest }) => (
                    <ChipCell {...rest}>{data.status}</ChipCell>
                  ),
                },
                {
                  field: 'view',
                  headerName: '',
                  Component: ({ data, ...rest }) => (
                    <TableCell sx={{ px: '0 !important' }}>
                      <Link
                        to={`/view-proposal/${data.proposalId}`}
                        state={data}
                        target="view-proposal"
                      >
                        <Button
                          {...sharedProps.button.table}
                          startIcon={<ViewIcon />}
                          color="success"
                        >
                          View
                        </Button>
                      </Link>
                    </TableCell>
                  ),
                },
              ]}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
