import usePageQuery from '~/client/usePageQuery'

// company?pageSize=10&search=%7B%22status%22%3A%22active%22%7D

export default function useRepCompanies(
  params = { companyType: 'repCompany', status: 'active' }
) {
  const { data, mutate, ...rest } = usePageQuery(
    `company/?search=${encodeURIComponent(JSON.stringify(params))}`,
    { pageSize: -1 }
  )
  const options =
    data?.map(data => ({
      // ...data,
      id: data.companyId,
      label: data.companyName,
    })) || []

  return { data, mutate, ...rest, options }
}
