const fileTypeOptions = [
  {
    id: 'doc',
    title: 'Word Document',
    color: '#2B579A', // Word (Blue)
    extensions: ['doc', 'docx'],
    mimeTypes: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
  },
  {
    id: 'xls',
    title: 'Excel Spreadsheet',
    color: '#217346', // Excel (Green)
    extensions: ['xls', 'xlsx'],
    mimeTypes: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
  },
  {
    id: 'pdf',
    title: 'PDF Document',
    color: '#FF5733', // PDF (Red)
    extensions: ['pdf'],
    mimeTypes: ['application/pdf'],
  },
  {
    id: 'txt',
    title: 'Text File',
    color: '#000000', // Text (Black)
    extensions: ['txt'],
    mimeTypes: ['text/plain'],
  },
  {
    id: 'zip',
    title: 'ZIP Archive',
    color: '#8C8C8C', // Zip (Gray)
    extensions: ['zip'],
    mimeTypes: ['application/zip'],
  },
  {
    id: 'ppt',
    title: 'PowerPoint Presentation',
    color: '#D24726', // PowerPoint (Orange)
    extensions: ['ppt', 'pptx'],
    mimeTypes: [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
  },
  {
    id: 'jpg',
    title: 'Image File',
    color: '#9542F4', // JPEG Image (Purple)
    extensions: ['jpg', 'png'],
    mimeTypes: ['image/jpeg', 'image/png'],
  },
  {
    id: 'video',
    title: 'Video File',
    color: '#FF5733', // Video (Red)
    extensions: ['mp4', 'mkv', 'avi'],
    mimeTypes: ['video/mp4', 'video/x-matroska', 'video/x-msvideo'],
  },
]
export default fileTypeOptions

export const getFileType = (s, key) => {
  const fileType =
    fileTypeOptions.find(fileType => fileType.id === s) ||
    fileTypeOptions.find(fileType => fileType.extensions.includes(s)) ||
    fileTypeOptions.find(fileType =>
      fileType.mimeTypes.some(mimeType => mimeType.startsWith(s))
    )
  return key ? fileType?.[key] : fileType
}
