import { Delete, Send, Block } from '@mui/icons-material'

export const reasonOptionsContractor = [
  { id: 'lost_to_competitor', label: 'Lost to competitor' },
  { id: 'no_budget', label: 'No budget / lost funding' },
  { id: 'unresponsive', label: 'No decision / non-responsive' },
  { id: 'price', label: 'Price' },
  { id: 'other', label: 'Other' },
]

export const reasonOptionsHomeowner = [
  { id: 'too_expensive', label: 'Too expensive' },
  { id: 'competitor_products', label: 'Competitor product' },
  { id: 'delaying', label: 'Delaying project' },
  { id: 'not_required', label: 'Not required' },
  { id: 'other', label: 'Other' },
]

export const statusElements = {
  contractor_accepted: {
    label: 'Accepted',
    verb: 'Accept',
    icon: <Send />,
    color: 'success',
  },
  contractor_rejected: {
    label: 'Rejected',
    verb: 'Reject',
    icon: <Block />,
    color: 'warning',
  },
  cancelled: {
    label: 'Cancelled',
    verb: 'Cancel',
    icon: <Delete />,
    color: 'error',
  },
}

export const statusOptions = Object.entries(statusElements).map(
  ([id, { label }]) => ({ id, label })
)

export const statusElementsArray = Object.entries(statusElements).map(
  ([status, properties]) => ({
    status,
    ...properties,
  })
)

export const getValidStatuses = status => {
  if (status === 'draft') return [statusElements.cancelled]
  if (status === 'sent')
    return Object.entries(statusElements).map(([status, properties]) => ({
      status,
      ...properties,
    }))
  return []
}

export const canUpdate = ({ status, statusReasons, message }) => {
  if (status === 'contractor_accepted') return true
  if (status === 'contractor_rejected')
    if (statusReasons.includes('other') && !message?.length) return false
    else if (statusReasons.length) return true
  if (status === 'cancelled' && message?.length) return true
  return false
}
