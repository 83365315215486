// import { MuiColorInput, matchIsValidColor } from 'mui-color-input'
import { Clear } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const message =
  'Please enter a valid hexadecimal CSS color in the format #abc123.'
const ColorPickerElement = ({ name, helperText, ...rest }) => {
  const { setValue } = useFormContext()
  return (
    <Controller
      name={name}
      rules={{
        validate: value => {
          if (!value) return

          if (!value.startsWith('#') || value.length !== 7) return message

          const s = new Option().style
          s.color = value
          if (s.color === '') return message
        },
        // regex: {
        //   pattern: /^#[0-1a-f]{6}$/i,
        //   message
        // },
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          value={field.value ?? ''}
          {...rest}
          helperText={
            fieldState.invalid ? fieldState.error.message : helperText
          }
          error={fieldState.invalid}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <Clear
                    onClick={() => {
                      setValue(name, '#ffffff')
                      setTimeout(() => setValue(name, null))
                    }}
                  />
                </IconButton>
                <input
                  style={{
                    minHeight: 38,
                    padding: 0,
                    margin: 0,
                    border: 'none',
                    background: 'none',
                  }}
                  type="color"
                  name={name}
                  {...rest}
                  {...field}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}

export default ColorPickerElement
