import MuiChip from '@mui/material/Chip'

export const getColor = s => {
  if (
    ['cancelled', 'expired'].some(substring =>
      s.toLowerCase().includes(substring)
    )
  )
    return 'neutral'
  if (
    [
      'success',
      'admin',
      'active',
      'read',
      'confirmed',
      'sent',
      'accepted',
    ].some(substring => s.toLowerCase().includes(substring))
  )
    return 'success'
  if (
    ['unread', 'invited', 'force_change_password'].some(substring =>
      s.toLowerCase().includes(substring)
    )
  )
    return 'warning'
  if (
    ['danger', 'disabled', 'rejected'].some(substring =>
      s.toLowerCase().includes(substring)
    )
  )
    return 'error'
  if (['super_admin', 'distributor'].includes(s.toLowerCase())) return 'super'
  return 'primary'
}

const dictionary = [
  { key: 'FORCE_CHANGE_PASSWORD', value: 'Force change password' },
  { key: 'CONFIRMED', value: 'Confirmed' },
]

export const snakeToSentence = s => s.replace(/_/g, ' ')

export const systemToHuman = s =>
  typeof s !== 'string'
    ? s
    : dictionary.find(({ key }) => key === s)?.value || //s.includes('_')
      snakeToSentence(s).replace(/(?:^|\s|[-"'([{])+\S/g, c => c.toUpperCase())
// : s

export const getPlural = (label, count) =>
  typeof label === 'string' && typeof count === 'number'
    ? `${count} ${label}${count !== 1 ? 's' : ''}`
    : label

export default function Chip({ label, sx, ...rest }) {
  if (!label) return null
  return (
    <MuiChip
      size="small"
      label={systemToHuman(label)}
      color={getColor(label)}
      sx={{ textTransform: 'capitalize', ...sx }}
      {...rest}
    />
  )
}
